import { Button, Card, CardActions, CardBody, CardHeader, CardTitle, IconButton, Modal } from "capitalroadkit";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import React, { cloneElement, useEffect, useState } from "react";
import { deleteCorporateActionActivityAxios } from "../../../../api/CorporateActionActivitiyResource";
import useAPI from "../../../../../../hooks/useAPI";

const DeleteActivityModal = ({ button, activity, refreshAPI, getCorporateActionActivitiesAPI }) => {
  const [open, setOpen] = useState(false);

  const deleteActivityAPI = useAPI(deleteCorporateActionActivityAxios(activity.uuid), {
    immediate: false,
  });

  useEffect(() => {
    const { response, loading } = deleteActivityAPI;

    if (response && !loading) {
      refreshAPI.fetch();
      getCorporateActionActivitiesAPI.fetch();
    }
  }, [deleteActivityAPI.response, deleteActivityAPI.loading]);

  return (
    <>
      {button && cloneElement(button, { onClick: () => setOpen(true) })}
      <Modal open={open}>
        <Card>
          <CardHeader>
            <CardTitle>Delete activity</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setOpen(false)} />
            </CardActions>
          </CardHeader>
          <CardBody>
            Are you sure?
            <div style={{ display: "flex", justifyContent: "end", gap: "8px" }}>
              <Button color="warning" disable={deleteActivityAPI.loading} onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button color="primary" loading={deleteActivityAPI.loading} onClick={() => deleteActivityAPI.fetch()}>
                Delete
              </Button>
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default DeleteActivityModal;
