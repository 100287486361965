import axios from "axios";
import { host } from "./utilities";

export const getPayeeAxios = (uuid) => (config) => axios.get(`${host()}/payee/${uuid}`, config);
export const approvePayeeAxios =
  (uuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/payee/${uuid}/approve`, data, config);
export const rejectPayeeAxios =
  (uuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/payee/${uuid}/reject`, data, config);
export const deletePayeeAxios = (uuid) => (config) => axios.delete(`${host()}/payee/${uuid}`, config);
