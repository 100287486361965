import { Col, TextField } from "capitalroadkit";
import { Field } from "formik";
import React from "react";
import withNameSpace from "../../../../../functions/withNameSpace";
import MenuItem from "@material-ui/core/MenuItem";
import clone from "lodash/cloneDeep";
import { TinExemptionType } from "../../../../../types/CashService/Accounts/TinExemptionType";

const fields = {
  COUNTRY: "country",
  TIN: "tin",
  EXEMPTION: "noTinReason",
};

const initialValues = {
  [fields.COUNTRY]: "",
  [fields.TIN]: "",
  [fields.EXEMPTION]: "",
};

const clean = (values) => {
  let cleanValues = clone(values);
  return cleanValues;
};

const TaxCountryForm = ({ nameSpace, countries }) => (
  <>
    <Col sm={12}>
      <Field component={TextField} label="Country" name={withNameSpace(nameSpace, fields.COUNTRY)} required select>
        {countries
          .filter((country1) => country1.value !== "AU")
          .map((country2) => (
            <MenuItem value={country2.value}>{country2.key}</MenuItem>
          ))}
      </Field>
    </Col>
    <Col
      sm={12}
      style={{
        display: "flex",
        gap: "8px",
      }}
    >
      <Field component={TextField} label="Tax identifier" name={withNameSpace(nameSpace, fields.TIN)} />
      <Field component={TextField} label="Exemption" name={withNameSpace(nameSpace, fields.EXEMPTION)} select>
        <MenuItem value={""}>N/A</MenuItem>
        {Object.entries(TinExemptionType).map((e) => (
          <MenuItem value={e[0]}>{e[1]}</MenuItem>
        ))}
      </Field>
    </Col>
  </>
);

TaxCountryForm.fields = fields;
TaxCountryForm.initialValues = initialValues;
TaxCountryForm.clean = clean;

export default TaxCountryForm;
