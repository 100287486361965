import { Col, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import withNameSpace from "../../../../../functions/withNameSpace";
import clone from "lodash/cloneDeep";
import { parseEnum } from "../../../../../resources/formatters/Formatter";
import { MenuItem } from "@material-ui/core";
import ReInvestmentPlanStatus from "../../../../../types/corporateActions/ReInvestmentPlanStatus";

const fields = {
  PLAN_STATUS: "reinvestmentPlanStatus",
  MINIMUM_PARTICIPATING_UNITS: "minimumParticipatingUnits",
  MAXIMUM_PARTICIPATING_UNITS: "maximumParticipatingUnits",
  RESIDENCY_RESTRICTIONS: "residencyRestrictions",
};

const initialValues = (ca) => ({
  [fields.PLAN_STATUS]: ca?.reinvestmentPlanInfo?.status || "",
  [fields.MINIMUM_PARTICIPATING_UNITS]: ca?.reinvestmentPlanInfo?.minimumParticipatingUnits || "",
  [fields.MAXIMUM_PARTICIPATING_UNITS]: ca?.reinvestmentPlanInfo?.maximumParticipatingUnits || "",
  [fields.RESIDENCY_RESTRICTIONS]:
    ca?.reinvestmentPlanInfo?.residencyRestrictions?.length > 0
      ? ca?.reinvestmentPlanInfo?.residencyRestrictions[0]
      : "",
});

const clean = (values) => {
  const clean = clone(values);

  if (clean.reInvestmentPlan[fields.PLAN_STATUS] === ReInvestmentPlanStatus.ACTIVE) {
    clean.reinvestmentPlanInfo = {
      status: clean.reInvestmentPlan[fields.PLAN_STATUS],
      minimumParticipatingUnits: clean.reInvestmentPlan[fields.MINIMUM_PARTICIPATING_UNITS],
      maximumParticipatingUnits: clean.reInvestmentPlan[fields.MAXIMUM_PARTICIPATING_UNITS],
      residencyRestrictions:
        clean.reInvestmentPlan[fields.RESIDENCY_RESTRICTIONS] === ""
          ? null
          : {
              type: "BLACKLIST",
              list: [clean.reInvestmentPlan[fields.RESIDENCY_RESTRICTIONS]],
            },
    };
  } else if (clean.reInvestmentPlan[fields.PLAN_STATUS] === ReInvestmentPlanStatus.SUSPENDED) {
    clean.reinvestmentPlanInfo = { status: ReInvestmentPlanStatus.SUSPENDED };
  }

  delete clean.reInvestmentPlan;

  return clean;
};

const ReinvestmentPlanForm = ({ form, nameSpace }) => {
  return (
    <>
      <Col sm={12} />
      <Col sm={12}>Re-investment plan</Col>
      <Col sm={12}>
        <Field
          key={form.values[withNameSpace(nameSpace, fields.PLAN_STATUS)]}
          component={TextField}
          label="Re-investment status"
          fullWidth
          name={withNameSpace(nameSpace, fields.PLAN_STATUS)}
          required
          select
        >
          {Object.values(ReInvestmentPlanStatus).map((status) => (
            <MenuItem value={status}>{parseEnum(status)}</MenuItem>
          ))}
        </Field>
      </Col>
      <Col sm={12}>
        <Field
          component={TextField}
          label="Min participating units"
          fullWidth
          name={withNameSpace(nameSpace, fields.MINIMUM_PARTICIPATING_UNITS)}
          required={form.values[fields.PLAN_STATUS] === ReInvestmentPlanStatus.ACTIVE}
        />
      </Col>
      <Col sm={12}>
        <Field
          component={TextField}
          label="Max participating units"
          fullWidth
          name={withNameSpace(nameSpace, fields.MAXIMUM_PARTICIPATING_UNITS)}
          required={form.values[fields.PLAN_STATUS] === ReInvestmentPlanStatus.ACTIVE}
        />
      </Col>
      <Col sm={12}>
        <Field
          component={TextField}
          label="Residency restrictions"
          fullWidth
          name={withNameSpace(nameSpace, fields.RESIDENCY_RESTRICTIONS)}
        />
      </Col>
    </>
  );
};

ReinvestmentPlanForm.fields = fields;
ReinvestmentPlanForm.initialValues = initialValues;
ReinvestmentPlanForm.clean = clean;

export default ReinvestmentPlanForm;
