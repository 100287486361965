export const host = () => {
  if (window.location.href.includes("localhost")) return "http://localhost:9001/mse-corp-action";
  if (window.location.href.includes("nonprod")) {
    if (window.location.href.includes("dev")) {
      return "https://services-dev.nonprod.capitalroadonline.com.au/mse-corp-action";
    }

    if (window.location.href.includes("sit2")) {
      return "https://services-sit2.nonprod.capitalroadonline.com.au/mse-corp-action";
    }

    if (window.location.href.includes("sit")) {
      return "https://services-sit.nonprod.capitalroadonline.com.au/mse-corp-action";
    }

    if (window.location.href.includes("demo2")) {
      return "https://services-demo2.nonprod.capitalroadonline.com.au/mse-corp-action";
    }
  }
  return "https://services-prod.prod.capitalroadonline.com.au/mse-corp-action";
};
