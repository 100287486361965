import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto-mono/100.css";
import "@fontsource/roboto-mono/300.css";
import "@fontsource/roboto-mono/400.css";
import "@fontsource/roboto-mono/500.css";
import "@fontsource/roboto-mono/700.css";
import "sanitize.css";

import * as am4core from "@amcharts/amcharts4/core";
import AmchartsThemeAnimated from "@amcharts/amcharts4/themes/animated";
import AmchartsThemeDark from "@amcharts/amcharts4/themes/dark";
import { globalStyles, useLocalStorage, useTheme } from "capitalroadkit";
import { useEffect } from "react";
import injectSheet from "react-jss";

const CSS = ({ children }) => {
  const [theme] = useTheme();

  useEffect(() => {
    am4core.addLicense("CH200583143");
  }, []);

  useEffect(() => {
    if (theme.mode === "dark") am4core.useTheme(AmchartsThemeDark);
    else am4core.unuseTheme(AmchartsThemeDark);
  }, [theme]);

  const [isAnimationsEnabled] = useLocalStorage("animatedCharts", true);

  useEffect(() => {
    if (isAnimationsEnabled) am4core.useTheme(AmchartsThemeAnimated);
    else am4core.unuseTheme(AmchartsThemeAnimated);
  }, [isAnimationsEnabled]);

  return (
    <div
      id="app"
      style={{
        backgroundColor: theme.palette.background.main,
        minHeight: "100vh",
      }}
    >
      {children}
    </div>
  );
};

export default injectSheet(globalStyles)(CSS);
