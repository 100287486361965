import { Table } from "capitalroadkit";
import React, { useMemo } from "react";
import { parseBoolean, parseEnumSentenceCase } from "../../../../../../../../resources/formatters/Formatter";

const CashActiveBankAccountsTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        id: "bankAccounts",
        Header: "",
        Footer: "",
        columns: [
          {
            Header: "uuid",
            accessor: "uuid",
          },
          {
            Header: "Type",
            accessor: "type",
            Cell: ({ cell }) => parseEnumSentenceCase(cell.value),
          },
          {
            Header: "Account number",
            accessor: "accountNumber",
          },
          {
            Header: "BSB number",
            accessor: "bsbNumber",
          },
          {
            Header: "Discriminator",
            accessor: "discriminator",
          },
          {
            Header: "Supports debit",
            accessor: "supportsDebit",
            Cell: ({ cell: { value } }) => parseBoolean(value),
          },
          {
            Header: "Supports credit",
            accessor: "supportsCredit",
            Cell: ({ cell: { value } }) => parseBoolean(value),
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={["uuid"]}
      sortBy={[
        {
          id: "type",
          desc: true,
        },
      ]}
    />
  );
};

export default CashActiveBankAccountsTable;
