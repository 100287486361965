import { useTheme } from "capitalroadkit";
import { useRef } from "react";

import useStyles from "./Loading.styles";

const Loading = ({ message, size }) => {
  const [theme] = useTheme();

  const mountTime = useRef(Date.now());
  const mountDelay = -(mountTime.current % 1000);

  const classes = useStyles({ mountDelay });

  const getSize = () => {
    switch (size) {
      case "sm":
        return {
          height: "50px",
          width: "100px",
        };

      case "md":
        return {
          height: "100px",
          width: "200px",
        };

      case "lg":
        return {
          height: "300px",
          width: "200px",
        };

      default:
        return {
          height: "12rem",
          width: "15rem",
        };
    }
  };

  return (
    <>
      {message && (
        <p
          style={{
            textAlign: "center",
            color: "#adadad",
          }}
        >
          {message}
        </p>
      )}
      <svg
        className={classes.loader}
        height={getSize().height}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 187.3 93.7"
        width={getSize().width}
      >
        <path
          className={classes.outline}
          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1
            c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
          fill="none"
          stroke={theme.palette.primary.main}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <path
          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1
            c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
          fill="none"
          id="outline-bg"
          opacity="0.05"
          stroke={theme.palette.primary.main}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
      </svg>
    </>
  );
};

export default Loading;
