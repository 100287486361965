import {
  Card,
  Grid,
  Col,
  CardBody,
  Modal,
  CardHeader,
  CardTitle,
  IconButton,
  InfoBox,
  CardActions,
  Button,
} from "capitalroadkit";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import DOMPurify from "dompurify";
import { faStickyNote } from "@fortawesome/free-solid-svg-icons";
import {
  addInternalNoteAxios,
  addNoteAxios,
  removeInternalNoteAxios,
  removeNoteAxios,
} from "../../../api/CorporateActionsResource";
import useAPI from "../../../../../hooks/useAPI";
import NoteForm from "../../../forms/NoteForm";

const NotesModal = ({ corporateAction, setCorporateAction, useIcon, internal }) => {
  const [open, setOpen] = useState(false);
  const [noteToAdd, setNoteToAdd] = useState(null);
  const [noteToRemove, setNoteToRemove] = useState(null);
  const [updatedCA, setUpdatedCA] = useState(null);

  const addNoteAPI = useAPI(addNoteAxios(corporateAction?.uuid), { immediate: false });
  const addInternalNoteAPI = useAPI(addInternalNoteAxios(corporateAction?.uuid), { immediate: false });

  useEffect(() => {
    if (noteToAdd !== null) {
      if (internal) addInternalNoteAPI.fetch({}, { note: noteToAdd });
      else addNoteAPI.fetch({}, { note: noteToAdd });
    }
  }, [noteToAdd]);

  useEffect(() => {
    const { response, loading, error } = addInternalNoteAPI;
    setNoteToAdd(null);
    if (response && !loading && !error) {
      if (useIcon) setUpdatedCA(response.data);
      setCorporateAction(response.data);
    }
  }, [addInternalNoteAPI.response, addInternalNoteAPI.loading, addInternalNoteAPI.error]);

  useEffect(() => {
    const { response, loading, error } = addNoteAPI;
    setNoteToAdd(null);
    if (response && !loading && !error) {
      if (useIcon) setUpdatedCA(response.data);
      setCorporateAction(response.data);
    }
  }, [addNoteAPI.response, addNoteAPI.loading, addNoteAPI.error]);

  const removeNoteAPI = useAPI(removeNoteAxios(corporateAction?.uuid, noteToRemove), { immediate: false });
  const removeInternalNoteAPI = useAPI(removeInternalNoteAxios(corporateAction?.uuid, noteToRemove), {
    immediate: false,
  });

  useEffect(() => {
    if (noteToRemove !== null) {
      if (internal) removeInternalNoteAPI.fetch();
      else removeNoteAPI.fetch();
    }
  }, [noteToRemove]);

  useEffect(() => {
    const { response, loading, error } = removeInternalNoteAPI;
    setNoteToRemove(null);
    if (response && !loading && !error) {
      if (useIcon) setUpdatedCA(response.data);
      setCorporateAction(response.data);
    }
  }, [removeInternalNoteAPI.response, removeInternalNoteAPI.loading, removeInternalNoteAPI.error]);

  useEffect(() => {
    const { response, loading, error } = removeNoteAPI;
    setNoteToRemove(null);
    if (response && !loading && !error) {
      if (useIcon) setUpdatedCA(response.data);
      setCorporateAction(response.data);
    }
  }, [removeNoteAPI.response, removeNoteAPI.loading, removeNoteAPI.error]);

  // todo: dot notation here
  const noteForm = useFormik({
    initialValues: NoteForm.initialValues,
    onSubmit: (values) => setNoteToAdd(values.note),
    validationSchema: NoteForm.schema,
  });

  const renderNote = (note, index) => (
    <Col sm={12}>
      <CardBody nested>
        <Grid nested>
          <Col sm={11} style={{ whiteSpace: "pre-wrap" }}>
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(note),
              }}
            ></p>
          </Col>
          <Col sm={1} style={{ textAlign: "right" }}>
            <Tooltip title="Notes">
              <div style={{ display: "inline-block", marginLeft: "1rem" }}>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => setNoteToRemove(index.toString())}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Tooltip>
          </Col>
        </Grid>
      </CardBody>
    </Col>
  );

  const error = addNoteAPI.error || removeNoteAPI.error || addNoteAPI.error || removeNoteAPI.error;
  const loading = addNoteAPI.loading || removeNoteAPI.loading || addNoteAPI.loading || removeNoteAPI.loading;

  return (
    <>
      <Tooltip title={`Add ${internal ? "internal " : ""}notes`}>
        <div style={{ display: "inline-block", marginLeft: useIcon ? "1rem" : "" }}>
          {useIcon ? (
            <FontAwesomeIcon
              icon={faStickyNote}
              onClick={() => {
                setOpen(true);
                setNoteToRemove(null);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          ) : (
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                setOpen(true);
                setNoteToRemove(null);
              }}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            >
              + {internal ? "Internal note" : "Note"}
            </Button>
          )}
        </div>
      </Tooltip>
      <Modal open={open} size={"xl"}>
        {open && (
          <Card>
            <CardHeader>
              <CardTitle>{internal ? "Internal notes" : "Notes"}</CardTitle>
              <CardActions>
                <IconButton
                  icon={faTimes}
                  onClick={() => {
                    setOpen(false);
                    setNoteToRemove(null);
                  }}
                />
              </CardActions>
            </CardHeader>
            <CardBody>
              <FormikProvider value={noteForm}>
                <Form>
                  <Grid nested>
                    {!internal && (
                      <Col sm={12}>
                        <InfoBox variant={InfoBox?.Variant.WARNING} value="This will be published in reports" />
                      </Col>
                    )}
                    <Col sm={10}>
                      <NoteForm />
                    </Col>
                    <Col sm={2} style={{ marginTop: "1.5rem" }}>
                      <Button color="primary" loading={loading} type="submit">
                        Add
                      </Button>
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
              <br />
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                {error && <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />}
              </div>
            </CardBody>
            <CardBody>
              <Grid nested>
                {updatedCA ? (
                  <>
                    {internal &&
                      updatedCA?.internalNotes &&
                      updatedCA.internalNotes.map((note, index) => renderNote(note, index)).reverse()}
                    {!internal &&
                      updatedCA?.notes &&
                      updatedCA.notes.map((note, index) => renderNote(note, index)).reverse()}
                  </>
                ) : (
                  <>
                    {internal &&
                      corporateAction?.internalNotes &&
                      corporateAction.internalNotes.map((note, index) => renderNote(note, index)).reverse()}
                    {!internal &&
                      corporateAction?.notes &&
                      corporateAction.notes.map((note, index) => renderNote(note, index)).reverse()}
                  </>
                )}
              </Grid>
            </CardBody>
          </Card>
        )}
      </Modal>
    </>
  );
};

export default NotesModal;
