import { Col, CardBody, Grid, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";

const fields = {
  OLD_LOCAL_CODE: "oldLocalCode",
  NEW_LOCAL_CODE: "newLocalCode",
  EVENT_TYPE: "eventType",
  ACTION_FLAG: "actionFlag",
  PAR_VALUE_CURRENCY: "parValueCurrency",
  SECURITY_PAR_VALUE: "securityParValue",
};

const initialValues = (ca) => ({
  [fields.OLD_LOCAL_CODE]: ca?.oldLocalCode || "",
  [fields.NEW_LOCAL_CODE]: ca?.newLocalCode || "",
  [fields.EVENT_TYPE]: ca?.eventType || "",
  [fields.ACTION_FLAG]: ca?.actionFlag || "",
  [fields.PAR_VALUE_CURRENCY]: ca?.parValueCurrency || "",
  [fields.SECURITY_PAR_VALUE]: ca?.securityParValue || "",
});

const clean = (values) => {
  return values;
};

const LocalCodeChangeForm = ({ form, nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Local code change fields</Col>
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Old local code"
                fullWidth
                name={withNameSpace(nameSpace, fields.OLD_LOCAL_CODE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.oldLocalCode}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="New local code"
                fullWidth
                name={withNameSpace(nameSpace, fields.NEW_LOCAL_CODE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.newLocalCode}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Event type"
                fullWidth
                name={withNameSpace(nameSpace, fields.EVENT_TYPE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.eventType}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Action flag"
                fullWidth
                name={withNameSpace(nameSpace, fields.ACTION_FLAG)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.actionFlag}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Par value currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAR_VALUE_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.parValueCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Security par value"
                fullWidth
                name={withNameSpace(nameSpace, fields.SECURITY_PAR_VALUE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.securityParValue}
              </Col>
            )}
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

LocalCodeChangeForm.fields = fields;
LocalCodeChangeForm.initialValues = initialValues;
LocalCodeChangeForm.clean = clean;

export default LocalCodeChangeForm;
