import { Col, TextField } from "capitalroadkit";
import { Field } from "formik";
import React from "react";
import * as Yup from "yup";

const fields = {
  TAG: "tag",
};

const initialValues = {
  [fields.TAG]: "",
};

const schema = Yup.object({
  [fields.TAG]: Yup.string().required("Tag is required"),
});

const TagForm = () => (
  <>
    <Col sm={8}>
      <Field component={TextField} fullWidth label="New tag" name={fields.TAG} />
    </Col>
  </>
);

export default TagForm;
export { initialValues as TagFormInitialValues, schema as TagFormSchema };
