import { Col, Grid, CardBody, TextField } from "capitalroadkit";
import React, { useEffect } from "react";
import { Field } from "formik";
import clone from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { MenuItem } from "@material-ui/core";
import moment from "moment";
import * as Yup from "yup";

import DividendForm from "./DividendForm";
import CorporateActionType from "../../../../types/corporateActions/CorporateActionType";
import { Formatter, parseEnum, toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import withNameSpace from "../../../../functions/withNameSpace";
import BonusForm from "./BonusForm";
import BuyBackForm from "./BuyBackForm";
import TakeoverForm from "./TakeoverForm";
import EntitlementIssueForm from "./EntitlementIssueForm";
import ConsolidationForm from "./ConsolidationForm";
import SecurityNameChangeForm from "./SecurityNameChangeForm";
import LocalCodeChangeForm from "./LocalCodeChangeForm";
import DemergerForm from "./DemergerForm";
import CompanyMeetingForm from "./CompanyMeetingForm";
import InterestForm from "./InterestForm";
import ReturnOfCapitalForm from "./ReturnOfCapitalForm";

const fields = {
  TYPE: "type",
  ASSET_CODE: "assetCode",
  DATE_OF_ANNOUNCEMENT: "dateOfAnnouncement",
  RECORD_DATE: "recordDate",
  EX_DATE: "exDate",
  PROCESSING_DATE: "processingDate",
  PAYMENT_DATE: "paymentDate",
};

const initialValues = (ca) => {
  const baseInitialValues = {
    [fields.TYPE]: ca ? ca.type.replace("_CORPORATE_ACTION", "") : CorporateActionType.DIVIDEND,
    [fields.ASSET_CODE]: ca?.assetCode || "",
    [fields.DATE_OF_ANNOUNCEMENT]: ca?.dateOfAnnouncement
      ? toDateString(ca.dateOfAnnouncement)
      : moment().format("DD/MM/YYYY"),
    [fields.RECORD_DATE]: ca?.recordDate ? toDateString(ca.recordDate) : "",
    [fields.PROCESSING_DATE]: ca?.processingDate ? toDateString(ca.processingDate) : "",
    [fields.EX_DATE]: ca?.exDate ? toDateString(ca.exDate) : "",
    [fields.PAYMENT_DATE]: ca?.paymentDate ? toDateString(ca.paymentDate) : "",
  };

  switch (baseInitialValues[fields.TYPE]) {
    case CorporateActionType.DIVIDEND:
      return { ...baseInitialValues, ...DividendForm.initialValues(ca) };
    case CorporateActionType.TAKEOVER:
      return { ...baseInitialValues, ...TakeoverForm.initialValues(ca) };
    case CorporateActionType.ENTITLEMENT_ISSUE:
      return { ...baseInitialValues, ...EntitlementIssueForm.initialValues(ca) };
    case CorporateActionType.BONUS:
      return { ...baseInitialValues, ...BonusForm.initialValues(ca) };
    case CorporateActionType.BUY_BACK:
      return { ...baseInitialValues, ...BuyBackForm.initialValues(ca) };
    case CorporateActionType.CONSOLIDATION:
      return { ...baseInitialValues, ...ConsolidationForm.initialValues(ca) };
    case CorporateActionType.SECURITY_NAME_CHANGE:
      return { ...baseInitialValues, ...SecurityNameChangeForm.initialValues(ca) };
    case CorporateActionType.LOCAL_CODE_CHANGE:
      return { ...baseInitialValues, ...LocalCodeChangeForm.initialValues(ca) };
    case CorporateActionType.DEMERGER:
      return { ...baseInitialValues, ...DemergerForm.initialValues(ca) };
    case CorporateActionType.COMPANY_MEETING:
      return { ...baseInitialValues, ...CompanyMeetingForm.initialValues(ca) };
    case CorporateActionType.INTEREST:
      return { ...baseInitialValues, ...InterestForm.initialValues(ca) };
    case CorporateActionType.RETURN_OF_CAPITAL:
      return { ...baseInitialValues, ...ReturnOfCapitalForm.initialValues(ca) };
    default:
      return baseInitialValues;
  }
};

const schema = (ca) => {
  const type = ca?.type.replace("_CORPORATE_ACTION", "");

  const optionalSchema = () => {
    switch (type) {
      case CorporateActionType.BONUS:
        return BonusForm.schema.fields;

      case CorporateActionType.CONSOLIDATION:
        return ConsolidationForm.schema.fields;

      case CorporateActionType.DEMERGER:
        return DemergerForm.schema.fields;

      case CorporateActionType.DIVIDEND:
        return DividendForm.schema.fields;

      case CorporateActionType.ENTITLEMENT_ISSUE:
        return EntitlementIssueForm.schema.fields;

      case CorporateActionType.TAKEOVER:
        return TakeoverForm.schema.fields;

      case CorporateActionType.RETURN_OF_CAPITAL:
        return ReturnOfCapitalForm.schema.fields;

      default:
        return null;
    }
  };

  return Yup.object({
    [fields.ASSET_CODE]: Yup.string().required("Asset code is required"),
    [fields.DATE_OF_ANNOUNCEMENT]: Yup.date()
      .typeError("Date must be a valid date")
      .transform((value, originalValue) => {
        value = moment(originalValue, "DD/MM/YYYY");
        return value.isValid() ? value.toDate() : null;
      })
      .required("Announcement date is required"),
    ...optionalSchema(),
  });
};

const clean = (values, overrideUuid) => {
  let cleanValues = clone(values);
  cleanValues[fields.TYPE] = `${cleanValues[fields.TYPE]}_CORPORATE_ACTION`;

  cleanValues[fields.DATE_OF_ANNOUNCEMENT] = toAPIDateString(cleanValues[fields.DATE_OF_ANNOUNCEMENT]);
  if (cleanValues[fields.RECORD_DATE])
    cleanValues[fields.RECORD_DATE] = toAPIDateString(cleanValues[fields.RECORD_DATE]);
  if (cleanValues[fields.EX_DATE]) cleanValues[fields.EX_DATE] = toAPIDateString(cleanValues[fields.EX_DATE]);
  if (cleanValues[fields.PAYMENT_DATE])
    cleanValues[fields.PAYMENT_DATE] = toAPIDateString(cleanValues[fields.PAYMENT_DATE]);
  if (cleanValues[fields.PROCESSING_DATE])
    cleanValues[fields.PROCESSING_DATE] = toAPIDateString(cleanValues[fields.PROCESSING_DATE]);

  if (overrideUuid) {
    cleanValues.override = { uuid: overrideUuid };
  }

  switch (values[fields.TYPE]) {
    case CorporateActionType.DIVIDEND:
      cleanValues = DividendForm.clean(cleanValues);
      break;
    case CorporateActionType.TAKEOVER:
      cleanValues = TakeoverForm.clean(cleanValues);
      break;
    case CorporateActionType.ENTITLEMENT_ISSUE:
      cleanValues = EntitlementIssueForm.clean(cleanValues);
      break;
    case CorporateActionType.BONUS:
      cleanValues = BonusForm.clean(cleanValues);
      break;
    case CorporateActionType.BUY_BACK:
      cleanValues = BuyBackForm.clean(cleanValues);
      break;
    case CorporateActionType.CONSOLIDATION:
      cleanValues = ConsolidationForm.clean(cleanValues);
      break;
    case CorporateActionType.SECURITY_NAME_CHANGE:
      cleanValues = SecurityNameChangeForm.clean(cleanValues);
      break;
    case CorporateActionType.LOCAL_CODE_CHANGE:
      cleanValues = LocalCodeChangeForm.clean(cleanValues);
      break;
    case CorporateActionType.DEMERGER:
      cleanValues = DemergerForm.clean(cleanValues);
      break;
    case CorporateActionType.COMPANY_MEETING:
      cleanValues = CompanyMeetingForm.clean(cleanValues);
      break;
    case CorporateActionType.INTEREST:
      cleanValues = InterestForm.clean(cleanValues);
      break;
    case CorporateActionType.RETURN_OF_CAPITAL:
      cleanValues = ReturnOfCapitalForm.clean(cleanValues);
      break;
    default:
      break;
  }

  for (const propName in cleanValues) {
    if (cleanValues[propName] === "") {
      delete cleanValues[propName];
    }
  }
  return cleanValues;
};

const CorporateActionForm = ({ form, nameSpace, ca, updateAnnouncement, overriddenCa, setSchema }) => {
  useEffect(() => {
    if (setSchema && form.values.type) setSchema(schema({ type: form.values.type }));
  }, [form.values.type]);

  useEffect(() => {
    if (form.values.type) {
      const newValues = initialValues({
        ...ca,
        [fields.TYPE]: form.values[fields.TYPE],
        [fields.ASSET_CODE]: form.values[fields.ASSET_CODE],
        [fields.DATE_OF_ANNOUNCEMENT]: form.values[fields.DATE_OF_ANNOUNCEMENT]
          ? toAPIDateString(form.values[fields.DATE_OF_ANNOUNCEMENT])
          : "",
        [fields.PROCESSING_DATE]: form.values[fields.PROCESSING_DATE]
          ? toAPIDateString(form.values[fields.PROCESSING_DATE])
          : "",
        [fields.EX_DATE]: form.values[fields.EX_DATE] ? toAPIDateString(form.values[fields.EX_DATE]) : "",
        [fields.RECORD_DATE]: form.values[fields.RECORD_DATE] ? toAPIDateString(form.values[fields.RECORD_DATE]) : "",
        [fields.PAYMENT_DATE]: form.values[fields.PAYMENT_DATE]
          ? toAPIDateString(form.values[fields.PAYMENT_DATE])
          : "",
      });

      if (!isEqual(form.values, newValues)) {
        console.log("here");
        form.setValues(newValues);
      }
    }
  }, [form.values.type]);

  const renderCorporateActionForm = () => {
    switch (form.values.type) {
      case CorporateActionType.DIVIDEND:
        return (
          <DividendForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.TAKEOVER:
        return (
          <TakeoverForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.ENTITLEMENT_ISSUE:
        return (
          <EntitlementIssueForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.BONUS:
        return (
          <BonusForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.BUY_BACK:
        return (
          <BuyBackForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.CONSOLIDATION:
        return (
          <ConsolidationForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.SECURITY_NAME_CHANGE:
        return (
          <SecurityNameChangeForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.LOCAL_CODE_CHANGE:
        return (
          <LocalCodeChangeForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.DEMERGER:
        return (
          <DemergerForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.COMPANY_MEETING:
        return (
          <CompanyMeetingForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );
      case CorporateActionType.INTEREST:
        return (
          <InterestForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );

      case CorporateActionType.RETURN_OF_CAPITAL:
        return (
          <ReturnOfCapitalForm
            form={form}
            nameSpace={nameSpace}
            updateAnnouncement={updateAnnouncement}
            overriddenCa={overriddenCa}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Grid nested>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={overriddenCa ? 8 : 12}>Base corporate action fields</Col>
            {overriddenCa && <Col sm={4}>Morningstar values</Col>}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.TYPE)]}
                component={TextField}
                label="Type"
                fullWidth
                name={withNameSpace(nameSpace, fields.TYPE)}
                readOnly={ca?.type}
                required
                select
              >
                {Object.values(CorporateActionType).map((type) => (
                  <MenuItem value={type}>{parseEnum(type)}</MenuItem>
                ))}
              </Field>
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {parseEnum(overriddenCa.type)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Asset code"
                fullWidth
                name={withNameSpace(nameSpace, fields.ASSET_CODE)}
                readOnly={updateAnnouncement}
                required
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.assetCode}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Date of announcement"
                mask="99/99/9999"
                fullWidth
                name={withNameSpace(nameSpace, fields.DATE_OF_ANNOUNCEMENT)}
                readOnly={updateAnnouncement}
                required
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.dateOfAnnouncement)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Processing date"
                mask="99/99/9999"
                fullWidth
                name={withNameSpace(nameSpace, fields.PROCESSING_DATE)}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.processingDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Ex date"
                mask="99/99/9999"
                fullWidth
                name={withNameSpace(nameSpace, fields.EX_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.exDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Record date"
                mask="99/99/9999"
                fullWidth
                name={withNameSpace(nameSpace, fields.RECORD_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.recordDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Payment date"
                mask="99/99/9999"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAYMENT_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.paymentDate)}
              </Col>
            )}
          </Grid>
        </CardBody>
      </Col>
      {renderCorporateActionForm()}
    </Grid>
  );
};

CorporateActionForm.fields = fields;
CorporateActionForm.initialValues = initialValues;
CorporateActionForm.schema = schema;
CorporateActionForm.clean = clean;

export default CorporateActionForm;
