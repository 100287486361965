const DistributionFrequencyType = Object.freeze({
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  FORTNIGHTLY: "FORTNIGHTLY",
  MONTHLY: "MONTHLY",
  BI_MONTHLY: "BI_MONTHLY",
  QUARTERLY: "QUARTERLY",
  SEMI_ANNUALLY: "SEMI_ANNUALLY",
  ANNUALLY: "ANNUALLY",
  ON_MATURITY: "ON_MATURITY",
});

export default DistributionFrequencyType;
