import { Checkbox, TextField } from "capitalroadkit";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import clone from "lodash/cloneDeep";
import { Divider } from "@material-ui/core";
import React from "react";

const fields = {
  COST_BASE_MULTIPLIER: "costBaseMultiplier",
  TRANSFER_COST_BASE: "transferCostBase",
};

const initialValues = (parcelTreatmentComponent) => ({
  [fields.COST_BASE_MULTIPLIER]:
    parcelTreatmentComponent?.costBaseMultiplier === null || parcelTreatmentComponent?.costBaseMultiplier === undefined
      ? "0"
      : parcelTreatmentComponent?.costBaseMultiplier,
  [fields.TRANSFER_COST_BASE]: parcelTreatmentComponent?.transferCostBase || false,
});

const clean = (values) => {
  let cleanValues = clone(values);

  return cleanValues;
};

const ParcelTreatmentComponentForm = ({ nameSpace }) => {
  return (
    <>
      <Divider />
      Parcel treatment component
      <Field
        component={TextField}
        label="Cost base multiplier"
        name={withNameSpace(nameSpace, fields.COST_BASE_MULTIPLIER)}
        required
      />
      <Field
        component={Checkbox}
        label="Transfer cost base"
        name={withNameSpace(nameSpace, fields.TRANSFER_COST_BASE)}
      />
    </>
  );
};

ParcelTreatmentComponentForm.fields = fields;
ParcelTreatmentComponentForm.initialValues = initialValues;
ParcelTreatmentComponentForm.clean = clean;

export default ParcelTreatmentComponentForm;
