import { Col, TextField } from "capitalroadkit";
import { Field } from "formik";
import React from "react";
import withNameSpace from "../../../../../functions/withNameSpace";
import clone from "lodash/cloneDeep";

const fields = {
  LINE_1: "line1",
  LINE_2: "line2",
  LINE_3: "line3",
  CITY: "city",
  STATE: "state",
  POSTCODE: "postCode",
};

const initialValues = {
  [fields.LINE_1]: "",
  [fields.LINE_2]: "",
  [fields.LINE_3]: "",
  [fields.CITY]: "",
  [fields.STATE]: "",
  [fields.POSTCODE]: "",
};

const clean = (values) => {
  let cleanValues = clone(values);
  return cleanValues;
};

const getAddressLine = (address) => {
  let addressLine = "";
  if (address[fields.LINE_1]) addressLine = address[fields.LINE_1];
  if (address[fields.LINE_2]) addressLine = addressLine.concat(" ", address[fields.LINE_2]);
  if (address[fields.LINE_3]) addressLine = addressLine.concat(" ", address[fields.LINE_3]);
  return addressLine;
};

const AddressForm = ({ nameSpace, values }) => (
  <>
    <Col
      sm={12}
      style={{
        display: "flex",
        gap: "8px",
      }}
    >
      <Field component={TextField} label="Line 1" name={withNameSpace(nameSpace, fields.LINE_1)} required />
      <Field component={TextField} label="City" name={withNameSpace(nameSpace, fields.CITY)} required />
    </Col>
    <Col
      sm={12}
      style={{
        display: "flex",
        gap: "8px",
      }}
    >
      <Field component={TextField} label="Line 2" name={withNameSpace(nameSpace, fields.LINE_2)} />
      <Field component={TextField} label="State" name={withNameSpace(nameSpace, fields.STATE)} required />
    </Col>
    <Col
      sm={12}
      style={{
        display: "flex",
        gap: "8px",
      }}
    >
      <Field component={TextField} label="Line 3" name={withNameSpace(nameSpace, fields.LINE_3)} />
      <Field component={TextField} label="Postcode" name={withNameSpace(nameSpace, fields.POSTCODE)} required />
    </Col>
  </>
);

AddressForm.fields = fields;
AddressForm.initialValues = initialValues;
AddressForm.clean = clean;
AddressForm.getAddressLine = getAddressLine;

export default AddressForm;
