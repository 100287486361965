import { Checkbox, TextField } from "capitalroadkit";
import { Field, useFormikContext } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import { toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import clone from "lodash/cloneDeep";
import React, { useEffect } from "react";
import ActivityForm from "./index";
import ActivityType from "../../../../types/corporateActions/Activities/ActivityType";

const fields = {
  TRANSACTION_DATE: "transactionDate",
  SETTLEMENT_DATE: "settlementDate",
  CUSTODIAN_BENEFICIARY_SETTLEMENT_DATE: "custodianBeneficiarySettlementDate",
  ANNOTATION: "annotation",
  MARKET_PRICE: "marketPrice",
  PRICE: "price",
};

const initialValues = (activity) => ({
  [fields.TRANSACTION_DATE]: toDateString(activity?.transactionComponent?.transactionDate) || "",
  [fields.SETTLEMENT_DATE]: toDateString(activity?.transactionComponent?.settlementDate) || "",
  [fields.CUSTODIAN_BENEFICIARY_SETTLEMENT_DATE]:
    toDateString(activity?.transactionComponent?.custodianBeneficiarySettlementDate) || "",
  [fields.ANNOTATION]: activity?.transactionComponent?.annotation || "",
  [fields.MARKET_PRICE]: activity?.transactionComponent?.marketPrice || false,
  [fields.PRICE]: activity?.transactionComponent?.price || "",
});

const clean = (values) => {
  let cleanValues = clone(values);

  const transactionComponent = {
    [fields.TRANSACTION_DATE]: cleanValues[ActivityForm.fields.TRANSACTION_COMPONENT][fields.TRANSACTION_DATE],
    [fields.SETTLEMENT_DATE]: cleanValues[ActivityForm.fields.TRANSACTION_COMPONENT][fields.SETTLEMENT_DATE],
    [fields.CUSTODIAN_BENEFICIARY_SETTLEMENT_DATE]:
      cleanValues[ActivityForm.fields.TRANSACTION_COMPONENT][fields.CUSTODIAN_BENEFICIARY_SETTLEMENT_DATE],
    [fields.ANNOTATION]: cleanValues[ActivityForm.fields.TRANSACTION_COMPONENT][fields.ANNOTATION],
    [fields.MARKET_PRICE]: cleanValues[ActivityForm.fields.TRANSACTION_COMPONENT][fields.MARKET_PRICE],
    [fields.PRICE]: cleanValues[ActivityForm.fields.TRANSACTION_COMPONENT][fields.PRICE],
  };

  for (const propName in transactionComponent) {
    if (transactionComponent[propName] === "") {
      delete transactionComponent[propName];
    }
  }

  transactionComponent[fields.TRANSACTION_DATE] = toAPIDateString(transactionComponent[fields.TRANSACTION_DATE]);
  transactionComponent[fields.SETTLEMENT_DATE] = toAPIDateString(transactionComponent[fields.SETTLEMENT_DATE]);
  transactionComponent[fields.CUSTODIAN_BENEFICIARY_SETTLEMENT_DATE] = toAPIDateString(
    transactionComponent[fields.CUSTODIAN_BENEFICIARY_SETTLEMENT_DATE]
  );

  delete cleanValues[ActivityForm.fields.TRANSACTION_COMPONENT];
  cleanValues[ActivityForm.fields.TRANSACTION_COMPONENT] = transactionComponent;

  return cleanValues;
};
const TransactionComponentForm = ({ nameSpace }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values[ActivityForm.fields.TRANSACTION_COMPONENT][fields.MARKET_PRICE])
      setFieldValue(withNameSpace(nameSpace, fields.PRICE), "");
  }, [values[ActivityForm.fields.TRANSACTION_COMPONENT][fields.MARKET_PRICE]]);

  return (
    <>
      Transaction component
      <Field
        component={TextField}
        label="Transaction date"
        mask="99/99/9999"
        name={withNameSpace(nameSpace, fields.TRANSACTION_DATE)}
        required
      />
      <Field
        component={TextField}
        label="Settlement date"
        mask="99/99/9999"
        name={withNameSpace(nameSpace, fields.SETTLEMENT_DATE)}
        required
      />
      <Field
        component={TextField}
        label="Custodian Beneficiary Settlement date"
        mask="99/99/9999"
        name={withNameSpace(nameSpace, fields.CUSTODIAN_BENEFICIARY_SETTLEMENT_DATE)}
        required
      />
      <Field
        component={TextField}
        label="Transaction annotation"
        name={withNameSpace(nameSpace, fields.ANNOTATION)}
        required
      />
      {(values[ActivityForm.fields.TYPE] === ActivityType.ASSET_BUY_ACTIVITY ||
        values[ActivityForm.fields.TYPE] === ActivityType.ASSET_SELL_ACTIVITY) && (
        <>
          <Field component={Checkbox} label="Use market price" name={withNameSpace(nameSpace, fields.MARKET_PRICE)} />
          <Field
            component={TextField}
            readOnly={values[ActivityForm.fields.TRANSACTION_COMPONENT][fields.MARKET_PRICE]}
            label="Price"
            name={withNameSpace(nameSpace, fields.PRICE)}
            required
          />
        </>
      )}
    </>
  );
};

TransactionComponentForm.fields = fields;
TransactionComponentForm.initialValues = initialValues;
TransactionComponentForm.clean = clean;

export default TransactionComponentForm;
