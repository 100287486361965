import { useTheme } from "capitalroadkit";
import { parseEnum, toDateString } from "../../../../../../resources/formatters/Formatter";

const EntitlementIssueDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Sub type</th>
        <td>{parseEnum(ca.subType)}</td>
        <td>{parseEnum(secondaryCa?.subType)}</td>
      </tr>
      <tr>
        <th>Over subscription</th>
        <td>{ca.overSubscription}</td>
        <td>{secondaryCa?.overSubscription}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Primary instrument close price</th>
        <td>{ca.primaryInstrumentClosePrice}</td>
        <td>{secondaryCa?.primaryInstrumentClosePrice}</td>
      </tr>
      <tr>
        <th>Subscription price from issue price</th>
        <td>{ca.subscriptionPriceFromIssuePrice}</td>
        <td>{secondaryCa?.subscriptionPriceFromIssuePrice}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Start subscription date</th>
        <td>{toDateString(ca.startSubscriptionDate)}</td>
        <td>{toDateString(secondaryCa?.startSubscriptionDate)}</td>
      </tr>
      <tr>
        <th>End subscription date</th>
        <td>{toDateString(ca.endSubscriptionDate)}</td>
        <td>{toDateString(secondaryCa?.endSubscriptionDate)}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Start trading date</th>
        <td>{toDateString(ca.startTradingDate)}</td>
        <td>{toDateString(secondaryCa?.startTradingDate)}</td>
      </tr>
      <tr>
        <th>End trading date</th>
        <td>{toDateString(ca.endTradingDate)}</td>
        <td>{toDateString(secondaryCa?.endTradingDate)}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Fraction rounding</th>
        <td>{ca.fractionRounding}</td>
        <td>{secondaryCa?.fractionRounding}</td>
      </tr>
      <tr>
        <th>Ratio old</th>
        <td>{ca.ratioOld}</td>
        <td>{secondaryCa?.ratioOld}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Ratio new</th>
        <td>{ca.ratioNew}</td>
        <td>{secondaryCa?.ratioNew}</td>
      </tr>
    </>
  );
};

export default EntitlementIssueDetails;
