import { Button, Card, CardActions, CardHeader, CardTitle, Col, Grid, InfoBox, Page, PageHeader } from "capitalroadkit";
import { Link } from "react-router-dom";

import { useClientLedger } from "../../../context/ClientLedgerContext";
import useAPI from "../../../../../../../../../hooks/useAPI";
import Loading from "../../../../../../../../../Loading";
import { listDomesticPaymentsAxios } from "../../../../../../../api/ClientLedgerResource";
import PaymentsTable from "../../../../../../../components/PaymentsTable";

const ClientLedgerPaymentsIndex = () => {
  const clientLedger = useClientLedger();

  const listDomesticPaymentsAPI = useAPI(listDomesticPaymentsAxios(clientLedger.uuid));

  const response = listDomesticPaymentsAPI.response;
  const loading = listDomesticPaymentsAPI.loading;
  const error = listDomesticPaymentsAPI.error;

  if (response) {
    const payments = listDomesticPaymentsAPI.response.data;

    return (
      <Page>
        <Grid>
          <PageHeader heading="Payments" subHeading={clientLedger.name} />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Payments</CardTitle>
                <CardActions>
                  <Link to="new">
                    <Button color="primary" variant="text">
                      New payment
                    </Button>
                  </Link>
                </CardActions>
              </CardHeader>
              <PaymentsTable
                data={payments}
                hiddenColumns={["clientLedger.name"]}
                refreshData={() => listDomesticPaymentsAPI.fetch(listDomesticPaymentsAPI.response.config.params)}
              />
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page>
        <InfoBox variant={InfoBox.Variant.ERROR} value={error.response?.data} />
      </Page>
    );
  return null;
};

export default ClientLedgerPaymentsIndex;
