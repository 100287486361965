import { TextField } from "capitalroadkit";
import clone from "lodash/cloneDeep";
import { Field } from "formik";
import withNameSpace from "../../../../../functions/withNameSpace";
import React from "react";
import VolumeCalculatorForm from "./index";

const fields = {
  VOLUME: "volume",
};

const initialValues = (volumeCalculator) => ({
  [fields.VOLUME]: volumeCalculator?.volume || "",
});

const clean = (values, calculatorField) => {
  let cleanValues = clone(values);

  const volumeCalculator = {
    type: cleanValues[calculatorField][VolumeCalculatorForm.fields.TYPE],
    volume: cleanValues[calculatorField][fields.VOLUME],
  };

  for (const propName in volumeCalculator) {
    if (volumeCalculator[propName] === "") {
      delete volumeCalculator[propName];
    }
  }

  return volumeCalculator;
};

const FixedForm = ({ nameSpace }) => {
  return (
    <>
      <Field component={TextField} label="Volume" name={withNameSpace(nameSpace, fields.VOLUME)} required />
    </>
  );
};

FixedForm.fields = fields;
FixedForm.initialValues = initialValues;
FixedForm.clean = clean;

export default FixedForm;
