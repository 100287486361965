import {
  Button,
  Card,
  CardBody,
  CardActions,
  CardHeader,
  CardTitle,
  Col,
  Grid,
  InfoBox,
  Page,
  PageHeader,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useClientLedger } from "../../../context/ClientLedgerContext";
import useAPI from "../../../../../../../../../hooks/useAPI";
import { createDomesticPaymentAxios } from "../../../../../../../api/ClientLedgerResource";
import PaymentRequestForm from "../forms/PaymentRequestForm";

const ClientLedgerNewPayment = () => {
  const clientLedger = useClientLedger();

  const createDomesticPaymentAPI = useAPI(createDomesticPaymentAxios(clientLedger.uuid), { immediate: false });

  const paymentRequestForm = useFormik({
    initialValues: PaymentRequestForm.initialValues,
    onSubmit: (values) => createDomesticPaymentAPI.fetch({}, PaymentRequestForm.clean(values)),
  });

  useEffect(() => {
    const { response, loading } = createDomesticPaymentAPI;

    if (response && !loading) paymentRequestForm.resetForm();
  }, [createDomesticPaymentAPI.response, createDomesticPaymentAPI.loading]);

  return (
    <Page>
      <Grid>
        <PageHeader heading="New payment" subHeading={clientLedger.name} />
        <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>New payment</CardTitle>
              <CardActions>
                <Link to={`/mse-cash-active/ledger-balance/${clientLedger.uuid}/payees`}>
                  <Button color="primary" variant="text">
                    View payees
                  </Button>
                </Link>
              </CardActions>
            </CardHeader>
            <CardBody>
              <FormikProvider value={paymentRequestForm}>
                <Form>
                  <Grid nested>
                    <PaymentRequestForm form={paymentRequestForm} />
                    <Col sm={12} style={{ textAlign: "right" }}>
                      <div style={{ maxWidth: "512px", width: "100%" }}>
                        <Button color="primary" loading={createDomesticPaymentAPI.loading} type="submit">
                          Submit
                        </Button>
                      </div>
                    </Col>
                    {createDomesticPaymentAPI.response && (
                      <Col sm={12}>
                        <div style={{ maxWidth: "512px", width: "100%" }}>
                          <InfoBox variant={InfoBox.Variant.SUCCESS} value="Payment created successfully" />
                        </div>
                      </Col>
                    )}
                    {createDomesticPaymentAPI.error && (
                      <Col sm={12}>
                        <div style={{ maxWidth: "512px", width: "100%" }}>
                          <InfoBox
                            variant={InfoBox.Variant.ERROR}
                            value={createDomesticPaymentAPI.error.response?.data}
                          />
                        </div>
                      </Col>
                    )}
                  </Grid>
                </Form>
              </FormikProvider>
            </CardBody>
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

export default ClientLedgerNewPayment;
