import { Button, Col, Grid, Page } from "capitalroadkit";
import moment from "moment";
import { useEffect, useState } from "react";

import Loading from "../Loading";
import { version } from "../../package.json";

const Welcome = () => {
  const [versionMatches, setVersionMatches] = useState(false);

  useEffect(() => {
    fetch("/mse-ui/meta.json", { cache: "no-store" })
      .then((response) => response.json())
      .then((meta) => {
        if (version !== meta.version) {
          window.location = `${window.origin}/mse-ui/welcome?timestamp=${moment().valueOf()}`;
        } else {
          setVersionMatches(true);
        }
      });
  }, []);

  useEffect(() => {
    if (versionMatches) {
      window.history.replaceState(null, "", `${window.origin}/mse-ui/welcome`);
    }
  }, [versionMatches]);

  if (!versionMatches) return <Loading />;
  return (
    <Page id="Welcome">
      <Grid>
        <Col sm={12}>
          <h1
            style={{
              textAlign: "center",
            }}
          >
            Welcome
          </h1>
          <p
            style={{
              textAlign: "center",
            }}
          >
            MSE-UI Version: {version}
          </p>
        </Col>
        <Col
          sm={12}
          style={{
            textAlign: "center",
          }}
        >
          <Button type={"button"} onClick={() => (window.location = `${window.origin}/login`)} color="primary">
            Login
          </Button>
        </Col>
      </Grid>
    </Page>
  );
};

export default Welcome;
