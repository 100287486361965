import { useTheme } from "capitalroadkit";
import { toDateString } from "../../../../../../resources/formatters/Formatter";

const InterestDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Coupon date</th>
        <td>{toDateString(ca.couponDate)}</td>
        <td>{toDateString(secondaryCa?.couponDate)}</td>
      </tr>
      <tr>
        <th>Maturity date</th>
        <td>{toDateString(ca.maturityDate)}</td>
        <td>{toDateString(secondaryCa?.maturityDate)}</td>
      </tr>

      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Coupon rate type</th>
        <td>{ca.couponRateType}</td>
        <td>{secondaryCa?.couponRateType}</td>
      </tr>
      <tr>
        <th>Coupon rate</th>
        <td>{ca.couponRate}</td>
        <td>{secondaryCa?.couponRate}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Payment currency</th>
        <td>{ca.paymentCurrency}</td>
        <td>{secondaryCa?.paymentCurrency}</td>
      </tr>
      <tr>
        <th>Coupon frequency</th>
        <td>{ca.couponFrequency}</td>
        <td>{secondaryCa?.couponFrequency}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Coupon period</th>
        <td>{ca.couponPeriod}</td>
        <td>{secondaryCa?.couponPeriod}</td>
      </tr>
      <tr>
        <th>Coupon year</th>
        <td>{ca.couponYear}</td>
        <td>{secondaryCa?.couponYear}</td>
      </tr>
    </>
  );
};

export default InterestDetails;
