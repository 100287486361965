import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { Card, CardBody, Modal, CardHeader, CardTitle, IconButton, CardActions } from "capitalroadkit";
import React from "react";
import DOMPurify from "dompurify";

const TransactionNotesModal = ({ transaction, setTransaction }) => (
  <Modal open={transaction}>
    {transaction && (
      <Card>
        <CardHeader>
          <CardTitle>Transaction notes</CardTitle>
          <CardActions>
            <IconButton icon={faTimes} onClick={() => setTransaction(null)} />
          </CardActions>
        </CardHeader>
        <CardBody>
          <ul style={{ marginLeft: "1rem" }}>
            {transaction.notes.map((note) => (
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(note),
                }}
              ></p>
            ))}
          </ul>
        </CardBody>
      </Card>
    )}
  </Modal>
);
export default TransactionNotesModal;
