import {
  Card,
  CardHeader,
  CardTitle,
  Col,
  Grid,
  Page,
  PageHeader,
  Button,
  CardActions,
  InfoBox,
  CardBody,
} from "capitalroadkit";
import React from "react";
import { useNavigate, useParams } from "react-router";

import CashActiveBankAccountsTable from "./CashActiveAccountTable";
import CashActiveBpayAccountsTable from "./CashActiveBpayAccountsTable";
import useAPI from "../../../../../../../../hooks/useAPI";
import { getClientLedgerAxios } from "../../../../../../api/ClientLedgerResource";
import AccountDetails from "./AccountDetails";
import Loading from "../../../../../../../../Loading";

const LedgerAccounts = () => {
  const { uuid } = useParams();
  const getClientLedgerAPI = useAPI(getClientLedgerAxios(uuid));

  const { response, loading, error } = getClientLedgerAPI;

  if (getClientLedgerAPI.response) {
    return (
      <Page id="client-accounts">
        <Grid>
          <PageHeader heading={"Client account: " + response.data.clientAccountNumber + " - " + response.data.name} />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Account details</CardTitle>
              </CardHeader>
              <CardBody>
                <Grid>
                  <Col sm={6}>
                    <AccountDetails data={response.data} />
                  </Col>
                </Grid>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Bank accounts</CardTitle>
              </CardHeader>
              <CashActiveBankAccountsTable data={response.data.internalBankDetails.bankAccounts} />
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>bPay accounts</CardTitle>
              </CardHeader>
              <CashActiveBpayAccountsTable data={response.data.internalBankDetails.bpayAccounts} />
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page id="Asset">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default LedgerAccounts;
