const LedgerSubType = Object.freeze({
  NAT: "NAT",
  IMA: "IMA",
  CON: "CON",
  GEN: "GEN",
  IMC: "IMC",
  INT: "INT",
  TAX: "TAX",
  CLI: "CLI",
  SUSPENSE: "SUSPENSE",
});

export default LedgerSubType;
