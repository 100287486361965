import { Button, Card, CardActions, CardHeader, CardTitle, Col, Grid, InfoBox, Page, PageHeader } from "capitalroadkit";
import { Link } from "react-router-dom";

import { useClientLedger } from "../../../context/ClientLedgerContext";
import PayeeTable from "../../../../../../../components/PayeeTable";
import useAPI from "../../../../../../../../../hooks/useAPI";
import { listPayeesAxios } from "../../../../../../../api/ClientLedgerResource";
import Loading from "../../../../../../../../../Loading";

const PayeesIndex = () => {
  const clientLedger = useClientLedger();

  const listPayeesAPI = useAPI(listPayeesAxios(clientLedger.uuid));

  const response = listPayeesAPI.response;
  const loading = listPayeesAPI.loading;
  const error = listPayeesAPI.error;

  if (response) {
    const payees = listPayeesAPI.response.data;

    return (
      <Page>
        <Grid>
          <PageHeader heading="Payees" subHeading={clientLedger.name} />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Payees</CardTitle>
                <CardActions>
                  <Link to="new">
                    <Button color="primary" variant="text">
                      New payee
                    </Button>
                  </Link>
                </CardActions>
              </CardHeader>
              <PayeeTable
                data={payees}
                hiddenColumns={["clientLedger.name"]}
                refreshData={() => listPayeesAPI.fetch(listPayeesAPI.response.config.params)}
              />
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page>
        <InfoBox variant={InfoBox.Variant.ERROR} value={error.response?.data} />
      </Page>
    );
  return null;
};

export default PayeesIndex;
