import { Card, CardHeader, CardTitle, Col, Grid, InfoBox, Page, PageHeader } from "capitalroadkit";

import { listDomesticPaymentsAxios } from "../api/CashActiveEntityResource";
import useAPI from "../../../hooks/useAPI";
import Loading from "../../../Loading";
import PaymentsTable from "../components/PaymentsTable";

const Payments = () => {
  const getDomesticPaymentsAPI = useAPI(listDomesticPaymentsAxios());

  const response = getDomesticPaymentsAPI.response;
  const loading = getDomesticPaymentsAPI.loading;
  const error = getDomesticPaymentsAPI.error;

  if (response) {
    const payments = getDomesticPaymentsAPI.response.data;

    return (
      <Page id="Payments">
        <Grid>
          <PageHeader heading="Payments" />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Payments</CardTitle>
              </CardHeader>
              <PaymentsTable
                data={payments}
                refreshData={() => getDomesticPaymentsAPI.fetch(getDomesticPaymentsAPI.response.config.params)}
              />
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page>
        <InfoBox variant={InfoBox.Variant.ERROR} value={error.response?.data} />
      </Page>
    );
  return null;
};

export default Payments;
