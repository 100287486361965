import { Col, CardBody, Grid, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import { toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import clone from "lodash/cloneDeep";

const fields = {
  AGM_DATE: "agmDate",
  CITY_ADDRESS: "cityAddress",
  MEETING_TIME: "meetingTime",
  MEETING_TYPE: "meetingType",
};

const initialValues = (ca) => ({
  [fields.AGM_DATE]: toDateString(ca?.agmDate) || "",
  [fields.CITY_ADDRESS]: ca?.cityAddress || "",
  [fields.MEETING_TIME]: ca?.meetingTime || "",
  [fields.MEETING_TYPE]: ca?.meetingType || "",
});

const clean = (values) => {
  const clean = clone(values);

  if (clean[fields.AGM_DATE]) clean[fields.AGM_DATE] = toAPIDateString(clean[fields.AGM_DATE]);

  return clean;
};

const CompanyMeetingForm = ({ form, nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Company meeting fields</Col>
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="AGM date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.AGM_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.agmDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="City address"
                fullWidth
                name={withNameSpace(nameSpace, fields.CITY_ADDRESS)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.cityAddress}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Meeting time"
                fullWidth
                name={withNameSpace(nameSpace, fields.MEETING_TIME)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.meetingTime}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Meeting type"
                fullWidth
                name={withNameSpace(nameSpace, fields.MEETING_TYPE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.meetingType}
              </Col>
            )}
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

CompanyMeetingForm.fields = fields;
CompanyMeetingForm.initialValues = initialValues;
CompanyMeetingForm.clean = clean;

export default CompanyMeetingForm;
