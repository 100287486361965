import { Card, Col, Grid, Page, PageHeader, Tab, Tabs, InfoBox } from "capitalroadkit";
import React, { useEffect, useState } from "react";

import LedgerTable from "../../../components/LedgerTable";
import {
  getClientLedgerBalanceSummaryAxios,
  getLedgerBalanceForEntityAxios,
} from "../../../api/CashActiveEntityResource";
import GroupType from "../../../../../types/CashService/GroupType";
import useAPI from "../../../../../hooks/useAPI";
import LedgerType from "../../../../../types/CashService/LedgerType";

const LedgerBalanceIndex = () => {
  const getLedgerBalanceForEntityAPI = useAPI(getLedgerBalanceForEntityAxios(), {
    initialParams: { type: LedgerType.SYSTEM },
  });
  const getClientLedgerBalanceSummaryRetailAPI = useAPI(getClientLedgerBalanceSummaryAxios(GroupType.RETAIL));
  const getClientLedgerBalanceSummaryNonRetailAPI = useAPI(getClientLedgerBalanceSummaryAxios(GroupType.NON_RETAIL));

  const [systemLedgerRetail, setSystemLedgerRetail] = useState(null);
  const [systemLedgerNonRetail, setSystemLedgerNonRetail] = useState(null);

  useEffect(() => {
    if (
      getLedgerBalanceForEntityAPI.response &&
      getClientLedgerBalanceSummaryRetailAPI.response &&
      getClientLedgerBalanceSummaryNonRetailAPI.response
    ) {
      const systemRetail = [];
      const systemNonRetail = [];

      for (const ledger of getLedgerBalanceForEntityAPI.response.data) {
        if (ledger.type === LedgerType.SYSTEM) {
          if (ledger.group !== GroupType.NON_RETAIL) systemRetail.push(ledger);
          if (ledger.group === GroupType.NON_RETAIL) systemNonRetail.push(ledger);
        }
      }
      systemRetail.push(getClientLedgerBalanceSummaryRetailAPI.response.data);
      systemNonRetail.push(getClientLedgerBalanceSummaryNonRetailAPI.response.data);

      setSystemLedgerRetail(systemRetail);
      setSystemLedgerNonRetail(systemNonRetail);
    }
  }, [
    getLedgerBalanceForEntityAPI.response,
    getClientLedgerBalanceSummaryRetailAPI.response,
    getClientLedgerBalanceSummaryNonRetailAPI.response,
  ]);

  const error =
    getLedgerBalanceForEntityAPI.error ||
    getClientLedgerBalanceSummaryNonRetailAPI.error ||
    getClientLedgerBalanceSummaryRetailAPI.error;

  if (systemLedgerRetail && systemLedgerNonRetail) {
    return (
      <Page id="cash-management-ledger">
        <Grid>
          <PageHeader heading="Cash management ledger" />
          <Col sm={12}>
            <Card>
              <Tabs>
                <Tab key="retail" name="Retail">
                  <LedgerTable data={systemLedgerRetail} hiddenColumns={["uuid", "type"]} isSystem />
                </Tab>
                <Tab key="non-retail" name="Non-Retail">
                  <LedgerTable data={systemLedgerNonRetail} hiddenColumns={["uuid", "type"]} isSystem />
                </Tab>
              </Tabs>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (error)
    return (
      <Page id="UpdateAsset">
        <Grid>
          <Col sm={12}>{error && <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />}</Col>
        </Grid>
      </Page>
    );
  return null;
};

export default LedgerBalanceIndex;
