import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "capitalroadkit";
import { Field } from "formik";

import AnnouncementType from "../../../types/Announcements/AnnouncementType";
import { parseEnum } from "../../../resources/formatters/Formatter";

const fields = {
  SECURITY_CODE: "security-code",
  TYPE: "type",
};

const initialValues = {
  [fields.SECURITY_CODE]: "",
  [fields.TYPE]: "",
};

const AnnouncementsSearchForm = () => (
  <>
    <Field component={TextField} label="Security code" name={fields.SECURITY_CODE} />
    <Field component={TextField} label="Type" name={fields.TYPE} select>
      <MenuItem value="">All</MenuItem>
      {Object.values(AnnouncementType).map((type) => (
        <MenuItem value={type}>{parseEnum(type)}</MenuItem>
      ))}
    </Field>
  </>
);

AnnouncementsSearchForm.initialValues = initialValues;
export default AnnouncementsSearchForm;
