import { InfoBox, Card, CardBody, CardTitle, CardHeader, Col, Grid, Page, PageHeader } from "capitalroadkit";
import { useNavigate, useParams } from "react-router";

import Loading from "../../../../Loading";
import useAPI from "../../../../hooks/useAPI";
import { getAssetAxios } from "../../api/AssetResource";

const AssetProfile = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const getAssetAPI = useAPI(getAssetAxios(uuid));

  const response = getAssetAPI.response;
  const loading = getAssetAPI.loading;
  const error = getAssetAPI.error;

  if (response) {
    return (
      <Page id="Asset">
        <Grid>
          <PageHeader heading="Asset" />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Asset</CardTitle>
              </CardHeader>
              <CardBody>
                <div style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(response.data, null, 2)}</div>
              </CardBody>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page id="Asset">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default AssetProfile;
