import axios from "axios";
import { host } from "./utilities";

export const getAssetAxios = (uuid) => (config) => axios.get(`${host()}/asset/${uuid}`, config);
export const createAssetAxios =
  () =>
  (config, data = {}) =>
    axios.post(`${host()}/asset`, data, config);
export const updateAssetAxios =
  (uuid) =>
  (config, data = {}) =>
    axios.put(`${host()}/asset/${uuid}`, data, config);
export const searchAssetsAxios = () => (config) => axios.get(`${host()}/asset/search`, config);
export const getAssetTypesListAxios = () => (config) => axios.get(`${host()}/asset/types/list`, config);
