import { Col, Grid, Page } from "capitalroadkit";

import ServiceCheck from "../components/ServiceCheck";
import { useHealth } from "../context/HealthContext";
import { useRoles } from "../context/RoleContext";

const Home = () => {
  const { assetServiceHealthCheckAPI, cashServiceHealthCheckAPI, corporateActionsServiceHealthCheckAPI } = useHealth();
  const { assetServiceUserRolesAPI, cashServiceUserRolesAPI, corporateActionsServiceUserRolesAPI } = useRoles();

  return (
    <Page id="Home">
      <Grid>
        <Col sm={12} md={6} lg={3}>
          <ServiceCheck
            rolesAPI={assetServiceUserRolesAPI}
            healthCheckAPI={assetServiceHealthCheckAPI}
            link="/mse-asset"
            title="Asset service"
          />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <ServiceCheck
            rolesAPI={cashServiceUserRolesAPI}
            healthCheckAPI={cashServiceHealthCheckAPI}
            link="/mse-cash-active"
            title="Cash service"
          />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <ServiceCheck
            healthCheckAPI={corporateActionsServiceHealthCheckAPI}
            rolesAPI={corporateActionsServiceUserRolesAPI}
            link="/mse-corp-action"
            title="Corporate actions"
          />
        </Col>
      </Grid>
    </Page>
  );
};

export default Home;
