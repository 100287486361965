import { CardBody, InfoBox } from "capitalroadkit";

import useAPI from "../../../../hooks/useAPI";
import { listPaymentSchedulesAxios } from "../../api/CashActiveEntityResource";
import Loading from "../../../../Loading";
import PaymentScheduleStatusEnum from "../../../../types/CashService/PaymentSchedule/PaymentScheduleStatusEnum";
import PaymentSchedulesTable from "../../components/PaymentSchedulesTable";

const PaymentSchedulesTab = () => {
  const listPaymentSchedulesAPI = useAPI(listPaymentSchedulesAxios(), {
    initialParams: { status: PaymentScheduleStatusEnum.PENDING_APPROVAL },
  });

  const response = listPaymentSchedulesAPI.response;
  const loading = listPaymentSchedulesAPI.loading;
  const error = listPaymentSchedulesAPI.error;

  if (response) {
    const paymentSchedules = listPaymentSchedulesAPI.response.data;

    return (
      <PaymentSchedulesTable
        data={paymentSchedules}
        refreshData={() => listPaymentSchedulesAPI.fetch(listPaymentSchedulesAPI.response.config.params)}
      />
    );
  }
  if (loading) {
    return (
      <CardBody>
        <Loading />
      </CardBody>
    );
  }
  if (error) {
    return (
      <CardBody>
        <InfoBox variant={InfoBox.Variant.ERROR} value={error.response?.data} />
      </CardBody>
    );
  }
  return null;
};

export default PaymentSchedulesTab;
