import { Table } from "capitalroadkit";
import { useMemo } from "react";

import { toDateString } from "../../../resources/formatters/Formatter";

const AnnouncementsTable = ({ api, data }) => {
  const columns = useMemo(
    () => [
      {
        id: "CorporateActionTable",
        columns: [
          {
            accessor: "securityCode",
            Header: "Security",
            Cell: ({ cell, row }) => `${cell.value}.${row.original.exchange}`,
          },
          {
            accessor: "description",
            Header: "Description",
          },
          {
            accessor: "dateOfAnnouncement",
            Header: "Date of announcement",
            Cell: ({ cell }) => toDateString(cell.value),
          },
          {
            accessor: "exDate",
            Header: "Ex date",
            Cell: ({ cell }) => toDateString(cell.value),
          },
          {
            accessor: "recordDate",
            Header: "Record date",
            Cell: ({ cell }) => toDateString(cell.value),
          },
          {
            accessor: "paymentDate",
            Header: "Payment date",
            Cell: ({ cell }) => toDateString(cell.value),
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      api={{
        limit: api.response.data.limit,
        offset: api.response.data.offset,
        totalNumberOfRecords: api.response.data.totalNumberOfRecords,
        fetch: api.fetch,
        params: api.lastParams,
      }}
      columns={columns}
      data={data}
    />
  );
};

export default AnnouncementsTable;
