import { MenuItem } from "@material-ui/core";
import { Col, InfoBox, TextField, useTheme } from "capitalroadkit";
import { Field } from "formik";
import clone from "lodash/cloneDeep";
import moment from "moment";
import { useEffect } from "react";

import useAPI from "../../../../../../../../../hooks/useAPI";
import { listPayeesAxios } from "../../../../../../../api/ClientLedgerResource";
import { useClientLedger } from "../../../context/ClientLedgerContext";
import Loading from "../../../../../../../../../Loading";
import withNameSpace from "../../../../../../../../../functions/withNameSpace";
import PayeeTypeEnum from "../../../../../../../../../types/CashService/Payees/PayeeTypeEnum";
import PayeeStatusEnum from "../../../../../../../../../types/CashService/Payees/PayeeStatusEnum";
import PaymentRequestForm from "../../Payments/forms/PaymentRequestForm";
import { parseEnum, toAPIDateString } from "../../../../../../../../../resources/formatters/Formatter";
import PaymentScheduleFrequencyEnum from "../../../../../../../../../types/CashService/PaymentSchedule/PaymentScheduleFrequencyEnum";

const fields = {
  FREQUENCY: "frequency",
  START_DATE: "startDate",
  END_DATE: "endDate",
};

const initialValues = {
  ...PaymentRequestForm.initialValues,
  [fields.FREQUENCY]: PaymentScheduleFrequencyEnum.ONCE,
  [fields.START_DATE]: moment().format("DD/MM/YYYY"),
  [fields.END_DATE]: "",
};

const clean = (values) => {
  let cleanValues = clone(values);

  cleanValues = PaymentRequestForm.clean(cleanValues);

  cleanValues[fields.START_DATE] = toAPIDateString(values[fields.START_DATE]);
  if (values[fields.END_DATE]) cleanValues[fields.END_DATE] = toAPIDateString(values[fields.END_DATE]);

  return cleanValues;
};

const PaymentScheduleForm = ({ form, nameSpace }) => (
  <>
    <PaymentRequestForm form={form} nameSpace={nameSpace} />
    <Col sm={12}>
      <Field component={TextField} label="Frequency" name={withNameSpace(nameSpace, fields.FREQUENCY)} select>
        {Object.entries(PaymentScheduleFrequencyEnum).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {parseEnum(value)}
          </MenuItem>
        ))}
      </Field>
    </Col>
    <Col sm={12}>
      <Field component={TextField} label="Start date" name={withNameSpace(nameSpace, fields.START_DATE)} required />
    </Col>
    <Col sm={12}>
      <Field component={TextField} label="End date" name={withNameSpace(nameSpace, fields.END_DATE)} />
    </Col>
  </>
);

PaymentScheduleForm.fields = fields;
PaymentScheduleForm.initialValues = initialValues;
PaymentScheduleForm.clean = clean;

export default PaymentScheduleForm;
