import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import React from "react";

const CashService = () => {
  const navigate = useNavigate();

  return (
    <>
      <AppBar color={"transparent"} position="static">
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <NavLink to="/mse-cash-active">
              <Typography variant="h6">CASH SERVICE</Typography>
            </NavLink>
          </div>
          <Button color="inherit" onClick={() => navigate("/mse-cash-active/ledger-balance")}>
            Ledger
          </Button>
          <Button color="inherit" onClick={() => navigate("/mse-cash-active/client-ledgers")}>
            Client search
          </Button>
          <Button color="inherit" onClick={() => navigate("/mse-cash-active/client/new")}>
            New account
          </Button>
          <Button color="inherit" onClick={() => navigate("/mse-cash-active/payments")}>
            Payments
          </Button>
          <Button color="inherit" onClick={() => navigate("/mse-cash-active/workflows")}>
            Workflow
          </Button>
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  );
};

export default CashService;
