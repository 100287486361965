import { useTheme } from "capitalroadkit";
import { parseBoolean, toDateString, toPercentString } from "../../../../../../resources/formatters/Formatter";

const DividendDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Dividend type</th>
        <td>{ca.dividendType}</td>
        <td>{secondaryCa?.dividendType}</td>
      </tr>
      <tr>
        <th>Ratio old</th>
        <td>{ca.ratioOld}</td>
        <td>{secondaryCa?.ratioOld}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Ratio new</th>
        <td>{ca.ratioNew}</td>
        <td>{secondaryCa?.ratioNew}</td>
      </tr>
      <tr>
        <th>Declaration date</th>
        <td>{toDateString(ca.declarationDate)}</td>
        <td>{toDateString(secondaryCa?.declarationDate)}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Financial year ending date</th>
        <td>{toDateString(ca.financialYearEndingDate)}</td>
        <td>{toDateString(secondaryCa?.financialYearEndingDate)}</td>
      </tr>
      <tr>
        <th>Period ending date</th>
        <td>{toDateString(ca.periodEndingDate)}</td>
        <td>{toDateString(secondaryCa?.periodEndingDate)}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Base currency</th>
        <td>{ca.baseCurrency}</td>
        <td>{secondaryCa?.baseCurrency}</td>
      </tr>
      <tr>
        <th>Amount per unit in base currency</th>
        <td>{ca.amountPerUnitInBaseCurrency}</td>
        <td>{secondaryCa?.amountPerUnitInBaseCurrency}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Payment currency</th>
        <td>{ca.paymentCurrency}</td>
        <td>{secondaryCa?.paymentCurrency}</td>
      </tr>
      <tr>
        <th>Amount per unit in payment currency</th>
        <td>{ca.amountPerUnitInPaymentCurrency}</td>
        <td>{secondaryCa?.amountPerUnitInPaymentCurrency}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Franked amount per unit in payment currency</th>
        <td>{ca.frankedAmountPerUnitInPaymentCurrency}</td>
        <td>{secondaryCa?.frankedAmountPerUnitInPaymentCurrency}</td>
      </tr>
      <tr>
        <th>Estimate</th>
        <td>{parseBoolean(ca.estimate)}</td>
        <td>{secondaryCa ? parseBoolean(secondaryCa?.estimate) : ""}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>NIL payment</th>
        <td>{parseBoolean(ca.nilPayment)}</td>
        <td>{secondaryCa ? parseBoolean(secondaryCa?.nilPayment) : ""}</td>
      </tr>
      <tr>
        <th>Tax rate</th>
        <td>{ca.taxRate ? toPercentString(ca.taxRate) : ""}</td>
        <td>{secondaryCa?.taxRate ? toPercentString(secondaryCa?.taxRate) : ""}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>DRP reinvestment price</th>
        <td>{ca.drpReinvestmentPrice}</td>
        <td>{secondaryCa?.drpReinvestmentPrice}</td>
      </tr>
      <tr>
        <th>DRP last date</th>
        <td>{toDateString(ca.drpLastDate)}</td>
        <td>{toDateString(secondaryCa?.drpLastDate)}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>DRP payment date</th>
        <td>{toDateString(ca.drpPaymentDate)}</td>
        <td>{toDateString(secondaryCa?.drpPaymentDate)}</td>
      </tr>
      <tr>
        <th>Option election date</th>
        <td>{toDateString(ca.optionElectionDate)}</td>
        <td>{toDateString(secondaryCa?.optionElectionDate)}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Re-investment plan status</th>
        <td>{ca.reinvestmentPlanInfo?.status}</td>
        <td>{secondaryCa?.reinvestmentPlanInfo?.status}</td>
      </tr>
      <tr>
        <th>Minimum participating units</th>
        <td>{ca.reinvestmentPlanInfo?.minimumParticipatingUnits}</td>
        <td>{secondaryCa?.reinvestmentPlanInfo?.minimumParticipatingUnits}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Maximum participating units</th>
        <td>{ca.reinvestmentPlanInfo?.maximumParticipatingUnits}</td>
        <td>{secondaryCa?.reinvestmentPlanInfo?.maximumParticipatingUnits}</td>
      </tr>
      <tr>
        <th>Residency restrictions</th>
        <td>{ca.reinvestmentPlanInfo?.residencyRestrictions ? ca.reinvestmentPlanInfo?.residencyRestrictions : ""}</td>
        <td>
          {secondaryCa?.reinvestmentPlanInfo?.residencyRestrictions
            ? secondaryCa?.reinvestmentPlanInfo?.residencyRestrictions
            : ""}
        </td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Company tax rate</th>
        <td>{ca.companyTaxRate}</td>
        <td>{secondaryCa?.companyTaxRate}</td>
      </tr>
    </>
  );
};

export default DividendDetails;
