import { Col, TextField } from "capitalroadkit";
import { Field } from "formik";
import withNameSpace from "../../../../../../../../../../functions/withNameSpace";

const fields = {
  BILLER_CODE: "billerCode",
  REFERENCE_NUMBER: "referenceNumber",
};

const initialValues = {
  [fields.BILLER_CODE]: "",
  [fields.REFERENCE_NUMBER]: "",
};

const BpayBillerForm = ({ nameSpace }) => {
  return (
    <>
      <Col sm={12}>
        <Field component={TextField} label="Biller code" name={withNameSpace(nameSpace, fields.BILLER_CODE)} required />
      </Col>
      <Col sm={12}>
        <Field
          component={TextField}
          label="Reference number"
          name={withNameSpace(nameSpace, fields.REFERENCE_NUMBER)}
          required
        />
      </Col>
    </>
  );
};

BpayBillerForm.fields = fields;
BpayBillerForm.initialValues = initialValues;

export default BpayBillerForm;
