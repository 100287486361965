import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Grid,
  Page,
  PageHeader,
  CardTitle,
  Button,
  InfoBox,
  CardActions,
} from "capitalroadkit";
import React, { useEffect, useState } from "react";
import useAPI from "../../../hooks/useAPI";
import { Form, FormikProvider, useFormik } from "formik";
import {
  findOverriddenForCorporateActionWithUuidAxios,
  getCorporateActionAxios,
  updateCorporateActionAxios,
} from "../api/CorporateActionsResource";
import CorporateActionForm from "../forms/CorporateActionForm";
import Loading from "../../../Loading";
import { useNavigate, useParams } from "react-router-dom";

const UpdateCorporateAction = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [corporateAction, setCorporateAction] = useState(null);
  const [overriddenCa, setOverriddenCa] = useState(null);

  const getCorporateActionAPI = useAPI(getCorporateActionAxios(uuid));

  useEffect(() => {
    const { response, loading, error } = getCorporateActionAPI;

    if (response && !loading && !error) {
      setCorporateAction(response.data);
      if (response.data.overriddenId) findOverriddenForCorporateActionWithUuidAPI.fetch();
    }
  }, [getCorporateActionAPI.response, getCorporateActionAPI.loading, getCorporateActionAPI.error]);

  const findOverriddenForCorporateActionWithUuidAPI = useAPI(findOverriddenForCorporateActionWithUuidAxios(uuid), {
    immediate: false,
  });

  useEffect(() => {
    const { response, loading, error } = findOverriddenForCorporateActionWithUuidAPI;

    if (response && !loading && !error) setOverriddenCa(response.data);
  }, [
    findOverriddenForCorporateActionWithUuidAPI.response,
    findOverriddenForCorporateActionWithUuidAPI.loading,
    findOverriddenForCorporateActionWithUuidAPI.error,
  ]);

  const updateCorporateActionAPI = useAPI(updateCorporateActionAxios(uuid), { immediate: false });

  const corporateActionForm = useFormik({
    initialValues: CorporateActionForm.initialValues(corporateAction),
    onSubmit: (values) => updateCorporateActionAPI.fetch({}, CorporateActionForm.clean(values)),
    validationSchema: CorporateActionForm.schema(corporateAction),
    enableReinitialize: true,
  });

  const response = updateCorporateActionAPI.response;
  const loading = updateCorporateActionAPI.loading;
  const error = updateCorporateActionAPI.error;

  if (
    (corporateAction && !corporateAction.overriddenId) ||
    (corporateAction && corporateAction.overriddenId && overriddenCa)
  ) {
    return (
      <Page id="UpdateCorporateAction">
        <Grid>
          <PageHeader heading="Update corporate action" />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Update corporate action</CardTitle>
              </CardHeader>
              {getCorporateActionAPI.response.data.overrideId ? (
                <CardBody>
                  <Grid nested>
                    <Col sm={12}>
                      <InfoBox
                        variant={InfoBox?.Variant.ERROR}
                        value="Cannot update a corporate action that is already overridden"
                      />
                    </Col>
                  </Grid>
                </CardBody>
              ) : (
                <FormikProvider value={corporateActionForm}>
                  <Form>
                    <Grid nested>
                      <Col lg={overriddenCa ? 6 : 4} md={overriddenCa ? 8 : 6} sm={12}>
                        <CardBody>
                          <Grid nested>
                            <Col sm={12}>
                              <CorporateActionForm
                                form={corporateActionForm}
                                ca={corporateAction}
                                updateAnnouncement={corporateAction.source === "ANNOUNCEMENT"}
                                overriddenCa={overriddenCa}
                              />
                            </Col>
                            {error && (
                              <Col sm={12}>
                                <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
                              </Col>
                            )}
                            {response && (
                              <Col sm={12}>
                                <InfoBox
                                  variant={InfoBox?.Variant.SUCCESS}
                                  value={
                                    <Grid nested>
                                      <Col sm={6} style={{ marginTop: "20px" }}>
                                        Successfully created!
                                      </Col>
                                      <Col sm={6} style={{ marginTop: "16px", textAlign: "right" }}>
                                        <Button
                                          color="success"
                                          variant="text"
                                          style={{ marginLeft: "4px" }}
                                          onClick={() => navigate(`/mse-corp-action/corporate-actions/${uuid}`)}
                                        >
                                          Back
                                        </Button>
                                        <Button
                                          color="success"
                                          variant="text"
                                          style={{ marginLeft: "4px" }}
                                          onClick={() =>
                                            navigate(
                                              `/mse-corp-action/corporate-actions/${updateCorporateActionAPI.response.data.uuid}`
                                            )
                                          }
                                        >
                                          View
                                        </Button>
                                      </Col>
                                    </Grid>
                                  }
                                />
                              </Col>
                            )}
                            <Col sm={12} style={{ marginTop: "16px", textAlign: "right" }}>
                              <Button
                                color="warning"
                                disable={loading}
                                style={{ marginLeft: "4px" }}
                                onClick={() => navigate(`/mse-corp-action/corporate-actions/${uuid}`)}
                              >
                                Cancel
                              </Button>
                              <Button
                                color="success"
                                disable={loading}
                                loading={loading}
                                style={{ marginLeft: "4px" }}
                                type="submit"
                              >
                                Save
                              </Button>
                            </Col>
                          </Grid>
                        </CardBody>
                      </Col>
                    </Grid>
                  </Form>
                </FormikProvider>
              )}
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (getCorporateActionAPI.loading || findOverriddenForCorporateActionWithUuidAPI.loading) return <Loading />;
  if (getCorporateActionAPI.error || findOverriddenForCorporateActionWithUuidAPI.error)
    return (
      <Page id="UpdateCorporateAction">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default UpdateCorporateAction;
