import { Card, Col, Grid, Page, PageHeader, Tab, Tabs } from "capitalroadkit";

import PayeesTab from "./PayeesTab";
import PaymentsTab from "./PaymentsTab";
import PaymentSchedulesTab from "./PaymentSchedulesTab";

const Workflow = () => (
  <Page id="Workflow">
    <Grid>
      <PageHeader heading="Workflow" />
      <Col sm={12}>
        <Card>
          <Tabs>
            <Tab name="Payments">
              <PaymentsTab />
            </Tab>
            <Tab name="Scheduled payments">
              <PaymentSchedulesTab />
            </Tab>
            <Tab name="Payees">
              <PayeesTab />
            </Tab>
          </Tabs>
        </Card>
      </Col>
    </Grid>
  </Page>
);

export default Workflow;
