import { CardBody, Grid, Col } from "capitalroadkit";
import React from "react";
import { parseEnum } from "../../../../../../../../resources/formatters/Formatter";

const AccountDetails = ({ data }) => {
  return (
    <table
      style={{
        borderCollapse: "collapse",
        tableLayout: "fixed",
        textAlign: "left",
        width: "100%",
      }}
    >
      <tr>
        <th>Client account number:</th>
        <td>{data.clientAccountNumber}</td>
      </tr>
      <tr>
        <th>Holders reference:</th>
        <td>{data.holdersReference}</td>
      </tr>
      <tr>
        <th>Client ID:</th>
        <td>{data.clientId}</td>
      </tr>
      <tr>
        <th>Name:</th>
        <td>{data.name}</td>
      </tr>
      <tr>
        <th>Ledger type:</th>
        <td>{data.ledgerType}</td>
      </tr>
      <tr>
        <th>Group:</th>
        <td>{parseEnum(data.group)}</td>
      </tr>
      <tr>
        <th>External status:</th>
        <td>{parseEnum(data.externalStatus)}</td>
      </tr>
      <tr>
        <th>Internal status:</th>
        <td>{parseEnum(data.internalStatus)}</td>
      </tr>
    </table>
  );
};

export default AccountDetails;
