import { Card, CardHeader, CardBody, Col, Grid, Page, PageHeader, CardTitle, Button, InfoBox } from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAPI from "../../../../hooks/useAPI";
import { createCorporateActionAxios } from "../../api/CorporateActionsResource";
import CorporateActionForm from "../../forms/CorporateActionForm";
import { toAPIDateString } from "../../../../resources/formatters/Formatter";

const NewCorporateAction = () => {
  const navigate = useNavigate();

  const createCorporateActionAPI = useAPI(createCorporateActionAxios(), { immediate: false });

  const [schema, setSchema] = useState(CorporateActionForm.schema());

  const corporateActionForm = useFormik({
    initialValues: CorporateActionForm.initialValues(),
    onSubmit: (values) => createCorporateActionAPI.fetch({}, CorporateActionForm.clean(values)),
    validationSchema: schema,
  });

  useEffect(() => {
    if (corporateActionForm.values.type) {
      setSchema(CorporateActionForm.schema({ type: corporateActionForm.values.type }));
    }
  }, [corporateActionForm.values.type]);

  useEffect(() => {
    if (createCorporateActionAPI.response) {
      corporateActionForm.resetForm();
    }
  }, [createCorporateActionAPI.response]);

  const response = createCorporateActionAPI.response;
  const loading = createCorporateActionAPI.loading;
  const error = createCorporateActionAPI.error;

  return (
    <Page id="NewCorporateActions">
      <Grid>
        <PageHeader heading="New corporate action" />
        <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>New corporate action</CardTitle>
            </CardHeader>
            <FormikProvider value={corporateActionForm}>
              <Form>
                <Grid nested>
                  <Col lg={4} md={6} sm={12}>
                    <CardBody>
                      <Grid nested>
                        <Col sm={12}>
                          <CorporateActionForm form={corporateActionForm} setSchema={setSchema} />
                        </Col>
                        {error && (
                          <Col sm={12}>
                            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
                          </Col>
                        )}
                        {response && !error && (
                          <Col sm={12}>
                            <InfoBox
                              variant={InfoBox?.Variant.SUCCESS}
                              value={
                                <Grid nested>
                                  <Col sm={6} style={{ marginTop: "20px" }}>
                                    Successfully created!
                                  </Col>
                                  <Col sm={6} style={{ marginTop: "16px", textAlign: "right" }}>
                                    <Button
                                      color="success"
                                      variant="text"
                                      style={{ marginLeft: "4px" }}
                                      onClick={() => navigate(`/mse-corp-action/corporate-actions`)}
                                    >
                                      Back
                                    </Button>
                                    <Button
                                      color="success"
                                      variant="text"
                                      style={{ marginLeft: "4px" }}
                                      onClick={() =>
                                        navigate(`/mse-corp-action/corporate-actions/${response.data.uuid}`)
                                      }
                                    >
                                      View corporate action
                                    </Button>
                                  </Col>
                                </Grid>
                              }
                            />
                          </Col>
                        )}
                        <Col sm={12} style={{ marginTop: "16px", textAlign: "right" }}>
                          <Button
                            color="warning"
                            disable={loading}
                            style={{ marginLeft: "4px" }}
                            onClick={() => navigate("/mse-corp-action/corporate-actions")}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="success"
                            disable={loading}
                            loading={loading}
                            style={{ marginLeft: "4px" }}
                            type="submit"
                          >
                            Save
                          </Button>
                        </Col>
                      </Grid>
                    </CardBody>
                  </Col>
                </Grid>
              </Form>
            </FormikProvider>
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

export default NewCorporateAction;
