import { Col, Grid, InfoBox, Page, TextField } from "capitalroadkit";
import * as Yup from "yup";
import clone from "lodash/cloneDeep";
import { Field, useFormikContext } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import useAPI from "../../../../hooks/useAPI";
import Loading from "../../../../Loading";
import React from "react";
import { MenuItem } from "@material-ui/core";
import VolumeCalculatorForm from "../ActivityForm/VolumeCalculatorForm";
import { getAvailableIncomeComponentTypesAxios } from "../../api/IncomeComponentResource";

const fields = {
  COMPONENT_TYPE: "componentType",
  VOLUME_CALCULATOR: "volumeCalculator",
  TAX_RATE: "taxRate",
};

const initialValues = (component) => ({
  [fields.COMPONENT_TYPE]: "",
  [fields.VOLUME_CALCULATOR]: VolumeCalculatorForm.initialValues(component?.volumeCalculator),
  [fields.TAX_RATE]: "",
});

const clean = (values) => {
  let cleanValues = clone(values);

  cleanValues[fields.COMPONENT_TYPE] = { uuid: cleanValues[fields.COMPONENT_TYPE] };
  cleanValues = VolumeCalculatorForm.clean(cleanValues, [fields.VOLUME_CALCULATOR]);

  return cleanValues;
};

const schema = () => {
  return Yup.object({
    [fields.COMPONENT_TYPE]: Yup.string().required("Component type required"),
    [fields.VOLUME_CALCULATOR]: VolumeCalculatorForm.schema(false),
    [fields.TAX_RATE]: Yup.number().required("Tax rate is required").min(0).max(100),
  });
};

const IncomeComponentForm = ({ nameSpace, setSchema }) => {
  const { values } = useFormikContext();

  const getComponentTypesAPI = useAPI(getAvailableIncomeComponentTypesAxios(), { immediate: true });

  if (getComponentTypesAPI.response) {
    return (
      <>
        <Field
          component={TextField}
          label="Component type"
          name={withNameSpace(nameSpace, fields.COMPONENT_TYPE)}
          required
          select
        >
          {getComponentTypesAPI.response.data
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((componentType) => (
              <MenuItem key={componentType.uuid} value={componentType.uuid}>
                {componentType.description}
              </MenuItem>
            ))}
        </Field>
        <VolumeCalculatorForm
          title={"Gross volume calculator"}
          value={values[fields.VOLUME_CALCULATOR]}
          nameSpace={withNameSpace(nameSpace, fields.VOLUME_CALCULATOR)}
          setSchema={setSchema}
        />
        <Field
          component={TextField}
          label="Tax rate"
          placeholder="0-100%"
          endAdornment="%"
          name={withNameSpace(nameSpace, fields.TAX_RATE)}
          required
        />
      </>
    );
  }
  if (getComponentTypesAPI.loading) return <Loading />;
  if (getComponentTypesAPI.error)
    return (
      <Page id="Asset">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={getComponentTypesAPI.error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

IncomeComponentForm.fields = fields;
IncomeComponentForm.initialValues = initialValues;
IncomeComponentForm.schema = schema;
IncomeComponentForm.clean = clean;

export default IncomeComponentForm;
