import { Card, CardBody, Col, Grid, Page, PageHeader, InfoBox, Button } from "capitalroadkit";
import React from "react";
import { useLocation } from "react-router";

import LedgerTable from "../../components/LedgerTable";
import useAPI from "../../../../hooks/useAPI";
import Loading from "../../../../Loading";
import { getLedgerBalanceForEntityAxios } from "../../api/CashActiveEntityResource";
import { Form, FormikProvider, useFormik } from "formik";
import ClientLedgerSearchForm from "../../forms/ClientLedgerSearchForm";

const ClientLedgersIndex = () => {
  const location = useLocation();

  const getLedgerBalanceForEntityAPI = useAPI(getLedgerBalanceForEntityAxios(), {
    initialParams: {
      group: location.state?.group,
      type: "CLIENT",
    },
  });

  const clientLedgerSearchForm = useFormik({
    initialValues: ClientLedgerSearchForm.initialValues(location.state?.group),
    onSubmit: (values) => getLedgerBalanceForEntityAPI.fetch(ClientLedgerSearchForm.clean(values)),
  });

  const { response, loading, error } = getLedgerBalanceForEntityAPI;

  if (response) {
    return (
      <Page id="ClientLedger">
        <Grid>
          <PageHeader heading="Client ledgers" />
          <Col sm={12}>
            <Card>
              <CardBody>
                <FormikProvider value={clientLedgerSearchForm}>
                  <Form>
                    <Grid nested>
                      <Col
                        sm={12}
                        style={{
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <ClientLedgerSearchForm />
                        <Button color="primary" style={{ marginTop: "16px" }} loading={loading} type="submit">
                          Search
                        </Button>
                      </Col>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardBody>
              <LedgerTable
                data={getLedgerBalanceForEntityAPI.response.data}
                hiddenColumns={["uuid", "type"]}
                rowClick={null}
                isClient
              />
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page id="ClientLedger">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={getLedgerBalanceForEntityAPI.error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default ClientLedgersIndex;
