import { useTheme } from "capitalroadkit";
import React from "react";
import DOMPurify from "dompurify";

const SecurityNameChangeDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Security new name</th>
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ca.securityNewName) }} />
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(secondaryCa?.securityNewName) }} />
      </tr>
      <tr>
        <th>Security old name</th>
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ca.securityOldName) }} />
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(secondaryCa?.securityOldName) }} />
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>New security code</th>
        <td>{ca.newSecurityCode}</td>
        <td>{secondaryCa?.newSecurityCode}</td>
      </tr>
      <tr>
        <th>Old security code</th>
        <td>{ca.oldSecurityCode}</td>
        <td>{secondaryCa?.oldSecurityCode}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Action flag</th>
        <td>{ca.actionFlag}</td>
        <td>{secondaryCa?.actionFlag}</td>
      </tr>
      <tr>
        <th>Par value currency</th>
        <td>{ca.parValueCurrency}</td>
        <td>{secondaryCa?.parValueCurrency}</td>
      </tr>
    </>
  );
};

export default SecurityNameChangeDetails;
