import EnvironmentEnums from "../types/EnvironmentEnum";

const useEnvironment = () => {
  if (window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1")) {
    return EnvironmentEnums.LOCALHOST;
  }

  if (window.location.href.includes("nonprod")) {
    if (window.location.href.includes("dev")) {
      return EnvironmentEnums.DEV;
    }

    if (window.location.href.includes("dev2")) {
      return EnvironmentEnums.DEV2;
    }

    if (window.location.href.includes("sit")) {
      return EnvironmentEnums.SIT;
    }

    if (window.location.href.includes("demo2")) {
      return EnvironmentEnums.DEMO2;
    }
  }

  if (window.location.href.includes("prod")) {
    return EnvironmentEnums.PROD;
  }

  throw new Error(`Environment for ${window.local.href} no defined`);
};

export default useEnvironment;
