import { CardBody, Col, Grid, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import clone from "lodash/cloneDeep";
import { parseEnum, toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import { MenuItem } from "@material-ui/core";
import TakeoverSubType from "../../../../types/corporateActions/TakeoverSubType";
import * as Yup from "yup";
import Tooltip from "@material-ui/core/Tooltip";
import { payTypeDescription, progressTypeDescription } from "../../../../functions/corpActionFieldDescription";
import PayType from "../../../../types/corporateActions/PayType";
import ProgressType from "../../../../types/corporateActions/ProgressType";
import { isinRegex, isinWarningString } from "../../../../resources/CorpActionFormValues";

const fields = {
  FRACTION_ROUNDING: "fractionRounding",
  RATIO_OLD: "ratioOld",
  RATIO_NEW: "ratioNew",
  ACTION_FLAG: "actionFlag",
  HOSTILE_FLAG: "hostileFlag",
  WITHDRAWAL_RIGHTS: "withdrawalRights",
  RESULTANT_SECURITY: "resultantSecurity",
  TARGET_ISIN: "targetISIN",
  ACQUIRER_ISIN: "acquirerISIN",
  TARGET_NAME: "targetName",
  ACQUIRER_NAME: "acquirerName",
  PROGRESS_OF_TAKEOVER: "progressOfTakeover",
  PAYMENT_CURRENCY: "paymentCurrency",
  UNCONDITIONAL_DATE: "unconditionalDate",
  CM_ACQ_DATE: "cmAcqDate",
  OPTION_ELECTION_DATE: "optionElectionDate",
  MAXIMUM_PRICE: "maximumPrice",
  MINIMUM_PRICE: "minimumPrice",
  PRIORITY: "priority",
  PAY_TYPE: "payType",
  PAR_VALUE_CURRENCY: "parValueCurrency",
  CLOSE_DATE: "closeDate",
  OPEN_DATE: "openDate",

  //additional information
  SUB_TYPE: "subType",
};

const initialValues = (ca) => ({
  [fields.FRACTION_ROUNDING]: ca?.fractionRounding || "",
  [fields.RATIO_OLD]: ca?.ratioOld || "",
  [fields.RATIO_NEW]: ca?.ratioNew || "",
  [fields.ACTION_FLAG]: ca?.actionFlag || "",
  [fields.HOSTILE_FLAG]: ca?.hostileFlag || "",
  [fields.WITHDRAWAL_RIGHTS]: ca?.withdrawalRights || "",
  [fields.RESULTANT_SECURITY]: ca?.resultantSecurity || "",
  [fields.TARGET_ISIN]: ca?.targetISIN || "",
  [fields.ACQUIRER_ISIN]: ca?.acquirerISIN || "",
  [fields.TARGET_NAME]: ca?.targetName || "",
  [fields.ACQUIRER_NAME]: ca?.acquirerName || "",
  [fields.PROGRESS_OF_TAKEOVER]: ca?.progressOfTakeover || "",
  [fields.PAYMENT_CURRENCY]: ca?.paymentCurrency || "",
  [fields.UNCONDITIONAL_DATE]: toDateString(ca?.unconditionalDate) || "",
  [fields.CM_ACQ_DATE]: toDateString(ca?.cmAcqDate) || "",
  [fields.OPTION_ELECTION_DATE]: toDateString(ca?.openElectionDate) || "",
  [fields.MAXIMUM_PRICE]: ca?.maximumPrice || "",
  [fields.MINIMUM_PRICE]: ca?.minimumPrice || "",
  [fields.PRIORITY]: ca?.priority || "",
  [fields.PAY_TYPE]: ca?.payType || "",
  [fields.PAR_VALUE_CURRENCY]: ca?.parValueCurrency || "",
  [fields.CLOSE_DATE]: toDateString(ca?.closeDate) || "",
  [fields.OPEN_DATE]: toDateString(ca?.openDate) || "",
  [fields.SUB_TYPE]: ca?.subType || "",
});

const clean = (values) => {
  const clean = clone(values);

  if (clean[fields.CM_ACQ_DATE]) clean[fields.CM_ACQ_DATE] = toAPIDateString(clean[fields.CM_ACQ_DATE]);
  if (clean[fields.CLOSE_DATE]) clean[fields.CLOSE_DATE] = toAPIDateString(clean[fields.CLOSE_DATE]);
  if (clean[fields.OPEN_DATE]) clean[fields.OPEN_DATE] = toAPIDateString(clean[fields.OPEN_DATE]);
  if (clean[fields.UNCONDITIONAL_DATE])
    clean[fields.UNCONDITIONAL_DATE] = toAPIDateString(clean[fields.UNCONDITIONAL_DATE]);
  if (clean[fields.OPTION_ELECTION_DATE])
    clean[fields.OPTION_ELECTION_DATE] = toAPIDateString(clean[fields.OPTION_ELECTION_DATE]);

  return clean;
};

const schema = Yup.object({
  [fields.ACQUIRER_ISIN]: Yup.string().nullable().matches(isinRegex, isinWarningString),
  [fields.TARGET_ISIN]: Yup.string().nullable().matches(isinRegex, isinWarningString),
});

const TakeoverForm = ({ form, nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Takeover fields</Col>
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Fraction rounding"
                fullWidth
                name={withNameSpace(nameSpace, fields.FRACTION_ROUNDING)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.fractionRounding}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Ratio old"
                fullWidth
                name={withNameSpace(nameSpace, fields.RATIO_OLD)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.ratioOld}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Ratio new"
                fullWidth
                name={withNameSpace(nameSpace, fields.RATIO_NEW)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.ratioNew}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Action flag"
                fullWidth
                name={withNameSpace(nameSpace, fields.ACTION_FLAG)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.actionFalg}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Hostile flag"
                fullWidth
                name={withNameSpace(nameSpace, fields.HOSTILE_FLAG)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.hostileFlag}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Withdrawal rights"
                fullWidth
                name={withNameSpace(nameSpace, fields.WITHDRAWAL_RIGHTS)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.withdrawalRights}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Resultant security"
                fullWidth
                name={withNameSpace(nameSpace, fields.RESULTANT_SECURITY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.resultantSecurity}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Target name"
                fullWidth
                name={withNameSpace(nameSpace, fields.TARGET_NAME)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.targetName}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Target ISIN"
                fullWidth
                name={withNameSpace(nameSpace, fields.TARGET_ISIN)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.targetISIN}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Acquirer name"
                fullWidth
                name={withNameSpace(nameSpace, fields.ACQUIRER_NAME)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.acquirerName}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Acquirer ISIN"
                fullWidth
                name={withNameSpace(nameSpace, fields.ACQUIRER_ISIN)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.acquirerISIN}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.PROGRESS_OF_TAKEOVER)]}
                component={TextField}
                label="Progress of takeover"
                fullWidth
                name={withNameSpace(nameSpace, fields.PROGRESS_OF_TAKEOVER)}
                readOnly={updateAnnouncement}
                select
              >
                <MenuItem value="">
                  <small>-</small>
                </MenuItem>
                {Object.entries(ProgressType).map((type) => (
                  <MenuItem value={type[0]}>{type[0] + " (" + type[1] + ")"}</MenuItem>
                ))}
              </Field>
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                <Tooltip title={progressTypeDescription(overriddenCa.progressOfTakeover)} placement="right">
                  <div style={{ width: "25px" }}>{overriddenCa.progressOfTakeover}</div>
                </Tooltip>
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Payment currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAYMENT_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.paymentCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Unconditional date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.UNCONDITIONAL_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.unconditionalDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="CM AQC date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.CM_ACQ_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.cmAqcDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Option election date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.OPTION_ELECTION_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.optionElectionDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Minimum price"
                fullWidth
                name={withNameSpace(nameSpace, fields.MINIMUM_PRICE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.minimumPrice}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Maximum price"
                fullWidth
                name={withNameSpace(nameSpace, fields.MAXIMUM_PRICE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.maximumPrice}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Priority"
                fullWidth
                name={withNameSpace(nameSpace, fields.PRIORITY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.priority}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.PAY_TYPE)]}
                component={TextField}
                label="Pay type"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAY_TYPE)}
                readOnly={updateAnnouncement}
                select
              >
                <MenuItem value="">Pay type is currently unknown</MenuItem>
                {Object.entries(PayType).map((type) => (
                  <MenuItem value={type[0]}>{type[0] + " (" + type[1] + ")"}</MenuItem>
                ))}
              </Field>
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                <Tooltip title={payTypeDescription(overriddenCa.payType)} placement="right">
                  <div style={{ width: "25px" }}>{overriddenCa.payType}</div>
                </Tooltip>
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Par value currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAR_VALUE_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.parValueCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Open date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.OPEN_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.openDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Close date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.CLOSE_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.closeDate)}
              </Col>
            )}
          </Grid>
        </CardBody>
      </Col>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Additional information fields</Col>
            <Col sm={12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.SUB_TYPE)]}
                component={TextField}
                label="Sub type"
                fullWidth
                name={withNameSpace(nameSpace, fields.SUB_TYPE)}
                required
                select
              >
                {Object.values(TakeoverSubType).map((type) => (
                  <MenuItem value={type}>{parseEnum(type)}</MenuItem>
                ))}
              </Field>
            </Col>
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

TakeoverForm.fields = fields;
TakeoverForm.initialValues = initialValues;
TakeoverForm.schema = schema;
TakeoverForm.clean = clean;

export default TakeoverForm;
