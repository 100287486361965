import { CardBody, Col, Grid, TextField } from "capitalroadkit";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import * as Yup from "yup";

const fields = {
  PAYMENT_CURRENCY: "paymentCurrency",
  CASH_BACK: "cashBack",
  ACTION_FLAG: "actionFlag",
  PRIORITY: "priority",
};

const initialValues = (ca) => ({
  [fields.PAYMENT_CURRENCY]: ca?.paymentCurrency || "",
  [fields.CASH_BACK]: ca?.cashBack || "",
  [fields.ACTION_FLAG]: ca?.actionFlag || "",
  [fields.PRIORITY]: ca?.priority || "",
});

const clean = (values) => {
  return values;
};

const schema = Yup.object({});

const ReturnOfCapitalForm = ({ nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <Col sm={12}>
      <CardBody nested>
        <Grid nested>
          <Col sm={12}>Return of capital fields</Col>
          <Col sm={overriddenCa ? 8 : 12}>
            <Field
              component={TextField}
              label="Payment currency"
              fullWidth
              name={withNameSpace(nameSpace, fields.PAYMENT_CURRENCY)}
              readOnly={updateAnnouncement}
            />
          </Col>
          {overriddenCa && (
            <Col sm={4} style={{ marginTop: "35px" }}>
              {overriddenCa.paymentCurrency}
            </Col>
          )}
          <Col sm={overriddenCa ? 8 : 12}>
            <Field
              component={TextField}
              label="Cash back"
              fullWidth
              name={withNameSpace(nameSpace, fields.CASH_BACK)}
              readOnly={updateAnnouncement}
            />
          </Col>
          {overriddenCa && (
            <Col sm={4} style={{ marginTop: "35px" }}>
              {overriddenCa.cashBack}
            </Col>
          )}
          <Col sm={overriddenCa ? 8 : 12}>
            <Field
              component={TextField}
              label="Action flag"
              fullWidth
              name={withNameSpace(nameSpace, fields.ACTION_FLAG)}
              readOnly={updateAnnouncement}
            />
          </Col>
          {overriddenCa && (
            <Col sm={4} style={{ marginTop: "35px" }}>
              {overriddenCa.actionFlag}
            </Col>
          )}
          <Col sm={overriddenCa ? 8 : 12}>
            <Field
              component={TextField}
              label="Priority"
              fullWidth
              name={withNameSpace(nameSpace, fields.PRIORITY)}
              readOnly={updateAnnouncement}
            />
          </Col>
          {overriddenCa && (
            <Col sm={4} style={{ marginTop: "35px" }}>
              {overriddenCa.priority}
            </Col>
          )}
        </Grid>
      </CardBody>
    </Col>
  );
};

ReturnOfCapitalForm.fields = fields;
ReturnOfCapitalForm.initialValues = initialValues;
ReturnOfCapitalForm.schema = schema;
ReturnOfCapitalForm.clean = clean;

export default ReturnOfCapitalForm;
