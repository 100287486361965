import axios from "axios";
import { host } from "./utilities";

export const getClientLedgerAxios = (ledgerUuid) => (config) =>
  axios.get(`${host()}/client-ledger/${ledgerUuid}`, config);
export const getClientLedgerInternalBankDetailsAxios = (ledgerUuid) => (config) =>
  axios.get(`${host()}/client-ledger/${ledgerUuid}/internal-bank-details`, config);
export const createPayeeAxios =
  (ledgerUuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/client-ledger/${ledgerUuid}/payee`, data, config);
export const listPayeesAxios = (ledgerUuid) => (config) =>
  axios.get(`${host()}/client-ledger/${ledgerUuid}/payees`, config);
export const createDomesticPaymentAxios =
  (ledgerUuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/client-ledger/${ledgerUuid}/domestic-payment`, data, config);
export const listDomesticPaymentsAxios = (ledgerUuid) => (config) =>
  axios.get(`${host()}/client-ledger/${ledgerUuid}/domestic-payments`, config);
export const submitPaymentScheduleAxios =
  (ledgerUuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/client-ledger/${ledgerUuid}/payment-schedule`, data, config);
export const listPaymentSchedulesAxios = (ledgerUuid) => (config) =>
  axios.get(`${host()}/client-ledger/${ledgerUuid}/payment-schedules`, config);
