import axios from "axios";
import { host } from "./utilities";

export const getAvailableIncomeComponentTypesAxios = () => (config) =>
  axios.get(`${host()}/income-component/component-types`, config);

export const deleteIncomeComponentAxios =
  (componentUuid) =>
  (config, data = {}) =>
    axios.delete(`${host()}/income-component/${componentUuid}`, config);
