import { Table } from "capitalroadkit";
import React, { useMemo } from "react";
import { parseBoolean, parseCamelCase, parseEnum, toDateString } from "../../../resources/formatters/Formatter";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import ActivityType from "../../../types/corporateActions/Activities/ActivityType";

const ActivityTable = ({ actions, data }) => {
  const columns = useMemo(
    () => [
      {
        id: "ActivityTable",
        Header: "",
        Footer: "",
        columns: [
          {
            accessor: "type",
            Header: "Type",
            Cell: ({ cell }) => parseEnum(cell.value),
          },
          {
            accessor: "description",
            Header: "Description",
          },
          {
            accessor: "assetCode",
            Header: "Asset code",
          },
          {
            accessor: "participationCondition",
            Header: "Participation condition",
            Cell: ({ cell }) => parseEnum(cell.value),
          },
          {
            accessor: "cashDistribution",
            Header: "Transaction",
            Cell: ({ cell, row: { original } }) =>
              parseBoolean(original.type === ActivityType.INCOME_ACTIVITY ? cell.value : true),
          },
          {
            accessor: "transactionComponent.transactionDate",
            Header: "Transaction date",
            Cell: ({ cell }) => toDateString(cell.value),
          },
          {
            accessor: "transactionComponent.settlementDate",
            Header: "Settlement date",
            Cell: ({ cell }) => toDateString(cell.value),
          },
          {
            accessor: "volumeCalculator.perShare",
            Header: "Volume calculator per share",
            Cell: ({ cell: { value }, row: { original } }) => (
              <Tooltip
                placement="bottom-start"
                title={
                  <table
                    style={{
                      borderCollapse: "collapse",
                      tableLayout: "fixed",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    {Object.entries(original.volumeCalculator).map((obj) => (
                      <tr>
                        <th>{parseCamelCase(obj[0])}:</th>
                        <td>{obj[1]}</td>
                      </tr>
                    ))}
                  </table>
                }
              >
                <div>
                  {value} <FontAwesomeIcon icon={faInfoCircle} />
                </div>
              </Tooltip>
            ),
          },
          {
            id: "actions",
            headerStyle: {
              width: "3rem",
            },
            rightAlign: true,
            sort: false,
            Cell: actions,
          },
        ],
      },
    ],
    []
  );

  return <Table columns={columns} data={data} />;
};

export default ActivityTable;
