import axios from "axios";
import { host } from "./utilities";

export const getTransactionAxios = (transactionUuid) => (config) =>
  axios.get(`${host()}/transaction/${transactionUuid}`, config);
export const postCliTransactionAxios =
  (transactionUuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/transaction/${transactionUuid}/post`, data, config);
export const getLedgerForTransactionAxios = (transactionUuid) => (config) =>
  axios.get(`${host()}/transaction/${transactionUuid}/ledger`, config);
export const updateCliTransactionAxios =
  (transactionUuid) =>
  (config, data = {}) =>
    axios.put(`${host()}/transaction/${transactionUuid}`, data, config);
