import { Outlet } from "react-router-dom";

import ClientLedgerProvider from "./context/ClientLedgerContext";

const ClientLedger = () => (
  <ClientLedgerProvider>
    <Outlet />
  </ClientLedgerProvider>
);

export default ClientLedger;
