import { Button, Card, CardActions, CardHeader, CardTitle, Col, Grid, InfoBox, Page, PageHeader } from "capitalroadkit";
import { Link } from "react-router-dom";

import { useClientLedger } from "../../../context/ClientLedgerContext";
import useAPI from "../../../../../../../../../hooks/useAPI";
import Loading from "../../../../../../../../../Loading";
import { listPaymentSchedulesAxios } from "../../../../../../../api/ClientLedgerResource";
import PaymentsTable from "../../../../../../../components/PaymentsTable";
import PaymentSchedulesTable from "../../../../../../../components/PaymentSchedulesTable";

const ClientLedgerPaymentSchedulesIndex = () => {
  const clientLedger = useClientLedger();

  const listPaymentSchedulesAPI = useAPI(listPaymentSchedulesAxios(clientLedger.uuid));

  const response = listPaymentSchedulesAPI.response;
  const loading = listPaymentSchedulesAPI.loading;
  const error = listPaymentSchedulesAPI.error;

  if (response) {
    const payments = listPaymentSchedulesAPI.response.data;

    return (
      <Page>
        <Grid>
          <PageHeader heading="Scheduled payments" subHeading={clientLedger.name} />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Scheduled payments</CardTitle>
                <CardActions>
                  <Link to="new">
                    <Button color="primary" variant="text">
                      New scheduled payment
                    </Button>
                  </Link>
                </CardActions>
              </CardHeader>
              <PaymentSchedulesTable
                data={payments}
                hiddenColumns={["clientLedger.name"]}
                refreshData={() => listPaymentSchedulesAPI.fetch(listPaymentSchedulesAPI.response.config.params)}
              />
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page>
        <InfoBox variant={InfoBox.Variant.ERROR} value={error.response?.data} />
      </Page>
    );
  return null;
};

export default ClientLedgerPaymentSchedulesIndex;
