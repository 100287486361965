import { CardBody, Grid, Col } from "capitalroadkit";
import React from "react";
import BeneficiaryReview from "./BeneficiaryReview";

const AccountReview = ({ accountRequest, findCountryForCode }) => (
  <>
    <CardBody nested>
      <Grid nested>
        <Col sm={12}>
          <table
            style={{
              borderCollapse: "collapse",
              tableLayout: "fixed",
              textAlign: "left",
              width: "100%",
            }}
          >
            <tr>
              <th>Client ID:</th>
              <td>{accountRequest.clientId}</td>
            </tr>
            <tr>
              <th>Account name:</th>
              <td>{accountRequest.name}</td>
            </tr>
            <tr>
              <th>Country:</th>
              <td>{findCountryForCode(accountRequest.country)}</td>
            </tr>
          </table>
        </Col>
      </Grid>
    </CardBody>
    {accountRequest.beneficiaries.map((beneficiary, index) => (
      <BeneficiaryReview beneficiary={beneficiary} index={index} findCountryForCode={findCountryForCode} />
    ))}
  </>
);

export default AccountReview;
