import { Table } from "capitalroadkit";
import React, { useMemo } from "react";
import { parseEnumSentenceCase } from "../../../../../../../../resources/formatters/Formatter";

const CashActiveBpayAccountsTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        id: "bpayAccounts",
        Header: "",
        Footer: "",
        columns: [
          {
            Header: "uuid",
            accessor: "uuid",
          },
          {
            Header: "Type",
            accessor: "type",
            Cell: ({ cell }) => parseEnumSentenceCase(cell.value),
          },
          {
            Header: "Biller code",
            accessor: "billerCode",
          },
          {
            Header: "Discriminator",
            accessor: "discriminator",
          },
          {
            Header: "Reference number",
            accessor: "referenceNumber",
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={["uuid"]}
      sortBy={[
        {
          id: "type",
          desc: true,
        },
      ]}
    />
  );
};

export default CashActiveBpayAccountsTable;
