import { createContext, useContext, useState } from "react";

import useEnvironment from "../hooks/useEnvironment";
import CognitoConfiguration from "../types/cognito/CognitoConfiguration";

const UserContext = createContext([null, undefined, null]);

const UserProvider = ({ children }) => {
  const environment = useEnvironment();

  const logout = () => {
    window.location.replace(
      `${CognitoConfiguration[environment]["DOMAIN"]}/logout?client_id=${CognitoConfiguration[environment]["CLIENT_ID"]}&logout_uri=${window.location.origin}/mse-ui/logout`
    );
  };

  const [user, setUser] = useState({
    tenant: "AREAWEALTH",
  });

  return <UserContext.Provider value={[user, setUser, logout]}>{children}</UserContext.Provider>;
};

const useUser = () => useContext(UserContext);

export default UserProvider;
export { useUser };
