import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem } from "@material-ui/core";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { Table, Dropdown } from "capitalroadkit";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { parseBoolean, parseEnum } from "../../../resources/formatters/Formatter";

const AssetTable = ({ api, data }) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        id: "AssetTable",
        columns: [
          {
            id: "actions",
            headerStyle: {
              width: "3rem",
            },
            rightAlign: true,
            sort: false,

            Cell: ({ row }) => (
              <Dropdown
                button={
                  <FontAwesomeIcon icon={faEllipsisV} style={{ cursor: "pointer", width: "15px", margin: "5px" }} />
                }
              >
                <MenuItem onClick={() => navigate(`/mse-asset/assets/${row.original.uuid}`)}>View</MenuItem>
                <MenuItem onClick={() => navigate(`/mse-asset/assets/${row.original.uuid}/update`)}>Update</MenuItem>
              </Dropdown>
            ),
          },
          {
            accessor: "assetCode",
            Header: "Asset code",
          },
          {
            accessor: "assetName",
            Header: "Name",
          },
          {
            accessor: "type",
            Header: "Type",
            Cell: ({ cell }) => parseEnum(cell.value),
          },
          {
            accessor: "assetSource",
            Header: "Source",
            Cell: ({ cell }) => parseEnum(cell.value),
          },
          {
            accessor: "provider",
            Header: "Provider",
            Cell: ({ cell }) => parseEnum(cell.value),
          },
          {
            accessor: "currency",
            Header: "Currency",
          },
          {
            accessor: "active",
            Header: "Active",
            Cell: ({ cell }) => parseBoolean(cell.value),
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      api={{
        limit: api.response.data.limit,
        offset: api.response.data.offset,
        totalNumberOfRecords: api.response.data.totalNumberOfRecords,
        fetch: api.fetch,
        params: api.lastParams,
      }}
      columns={columns}
      data={data}
      sortBy={[
        {
          id: "dateOfAnnouncement",
          desc: true,
        },
      ]}
    />
  );
};

export default AssetTable;
