import { Col, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import clone from "lodash/cloneDeep";
import withNameSpace from "../../../../../functions/withNameSpace";
import { MenuItem } from "@material-ui/core";

const fields = {
  DATE_OF_BIRTH: "dateOfBirth", //optional
  TAX_FILE_NUMBER: "taxFileNumber", //optional
  COUNTRY_OF_BIRTH: "placeOfBirth",
};

const initialValues = {
  [fields.DATE_OF_BIRTH]: "",
  [fields.TAX_FILE_NUMBER]: "",
  [fields.COUNTRY_OF_BIRTH]: "",
};

const clean = (values) => {
  let cleanValues = clone(values);
  return cleanValues;
};

const IndividualForm = ({ nameSpace, countries }) => (
  <>
    <Col sm={12}>
      <Field
        component={TextField}
        label="Country of birth"
        fullWidth
        name={withNameSpace(nameSpace, fields.COUNTRY_OF_BIRTH)}
        select
        required
      >
        {countries.map((country) => (
          <MenuItem value={country.value}>{country.key}</MenuItem>
        ))}
      </Field>
    </Col>
    <Col sm={12}>
      <Field
        component={TextField}
        label="Tax file number"
        fullWidth
        mask="999999999"
        name={withNameSpace(nameSpace, fields.TAX_FILE_NUMBER)}
      />
    </Col>
    <Col sm={12}>
      <Field
        component={TextField}
        label="Date of birth"
        fullWidth
        mask="99/99/9999"
        name={withNameSpace(nameSpace, fields.DATE_OF_BIRTH)}
      />
    </Col>
  </>
);

IndividualForm.fields = fields;
IndividualForm.initialValues = initialValues;
IndividualForm.clean = clean;

export default IndividualForm;
