import { Button, Card, CardActions, CardBody, CardHeader, CardTitle, IconButton, InfoBox, Modal } from "capitalroadkit";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import React, { cloneElement, useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import useAPI from "../../../../../../hooks/useAPI";
import ActivityForm from "../../../../forms/ActivityForm";
import ActivityType from "../../../../../../types/corporateActions/Activities/ActivityType";
import { createCorporateActionActivityAxios } from "../../../../api/CorporateActionsResource";

const CreateActivityModal = ({ button, ca, refreshAPI }) => {
  const [open, setOpen] = useState(false);
  const [schema, setSchema] = useState(ActivityForm.schema);

  const createActivityAPI = useAPI(createCorporateActionActivityAxios(ca.uuid), { immediate: false });

  const createActivityForm = useFormik({
    initialValues: ActivityForm.initialValues(null, ActivityType.INCOME_ACTIVITY),
    onSubmit: (values) => createActivityAPI.fetch({}, ActivityForm.clean(values)),
    validationSchema: schema,
    enableReinitialize: true,
  });

  useEffect(() => {
    const { response, loading } = createActivityAPI;

    if (response && !loading) {
      refreshAPI.fetch();
      createActivityForm.resetForm();
      setOpen(false);
      createActivityAPI.reset();
    }
  }, [createActivityAPI.response, createActivityAPI.loading]);

  return (
    <>
      {button && cloneElement(button, { onClick: () => setOpen(true) })}
      <Modal open={open}>
        <Card>
          <CardHeader>
            <CardTitle>Create activity</CardTitle>
            <CardActions>
              <IconButton
                icon={faTimes}
                onClick={() => {
                  setOpen(false);
                  createActivityForm.resetForm();
                }}
              />
            </CardActions>
          </CardHeader>
          <CardBody>
            <FormikProvider value={createActivityForm}>
              <Form>
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <ActivityForm form={createActivityForm} setSchema={setSchema} />
                  {createActivityAPI.error && (
                    <InfoBox variant={InfoBox?.Variant.ERROR} value={createActivityAPI.error.response?.data} />
                  )}
                  <div style={{ display: "flex", justifyContent: "end", gap: "8px" }}>
                    <Button
                      color="warning"
                      disable={createActivityAPI.loading}
                      onClick={() => {
                        createActivityForm.resetForm();
                        setOpen(false);
                        createActivityAPI.reset();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" loading={createActivityAPI.loading} type="submit">
                      Create
                    </Button>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default CreateActivityModal;
