import { useTheme } from "capitalroadkit";
import React from "react";

const ReturnOfCapitalDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Payment currency</th>
        <td>{ca.paymentCurrency}</td>
        <td>{secondaryCa?.paymentCurrency}</td>
      </tr>
      <tr>
        <th>Cash back</th>
        <td>{ca.cashBack}</td>
        <td>{secondaryCa?.cashBack}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Action flag</th>
        <td>{ca.actionFlag}</td>
        <td>{secondaryCa?.actionFlag}</td>
      </tr>
      <tr>
        <th>Priority</th>
        <td>{ca.priority}</td>
        <td>{secondaryCa?.priority}</td>
      </tr>
    </>
  );
};

export default ReturnOfCapitalDetails;
