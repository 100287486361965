import clone from "lodash/cloneDeep";
import * as Yup from "yup";

import ManagedFundForm from "./ManagedFundForm";

import AssetType from "../../../../../../types/Assets/AssetType";

const fields = {};

const initialValues = (asset) => {
  const fundInitialValues = {};

  switch (asset?.type) {
    case AssetType.MANAGED_FUND:
      return { ...fundInitialValues, ...ManagedFundForm.initialValues(asset) };

    default:
      return fundInitialValues;
  }
};

const schema = (asset) => {
  const optionalSchema = () => {
    switch (asset?.type) {
      case AssetType.MANAGED_FUND:
        return ManagedFundForm.schema.fields;

      default:
        return {};
    }
  };

  return Yup.object({ ...optionalSchema() });
};

const clean = (values) => {
  let cleanValues = clone(values);

  switch (values[fields.TYPE]) {
    case AssetType.EXCHANGE_TRADED_FUND:
      break;
    case AssetType.MANAGED_FUND:
      cleanValues = ManagedFundForm.clean(cleanValues);
      break;

    default:
      break;
  }

  return cleanValues;
};

const FundForm = ({ form, nameSpace, asset }) => {
  const renderFundForm = () => {
    switch (asset?.type) {
      case AssetType.MANAGED_FUND:
        return <ManagedFundForm form={form} nameSpace={nameSpace} asset={asset} />;

      default:
        return null;
    }
  };

  return <>{renderFundForm()}</>;
};

FundForm.fields = fields;
FundForm.initialValues = initialValues;
FundForm.schema = schema;
FundForm.clean = clean;

export default FundForm;
