const PaymentStatusEnum = Object.freeze({
  READY_FOR_PROCESSING: "READY_FOR_PROCESSING",
  ON_HOLD: "ON_HOLD",
  PROCESSED: "PROCESSED",
  ERROR: "ERROR",
  REJECTED: "REJECTED",
  COMPLETED: "COMPLETED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
});

export default PaymentStatusEnum;
