import { Table } from "capitalroadkit";
import React, { useMemo } from "react";
import { toDateString } from "../../../resources/formatters/Formatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import { faEdit, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import QualityControlNotesModal from "../routes/CorporateActions/CorporateActionProfile/QualityControlNotesModal";
import EntitlementsModal from "../routes/CorporateActions/CorporateActionProfile/entitlements/EntitlementsModal";
import TagsModal from "../routes/CorporateActions/CorporateActionProfile/TagsModal";
import NotesModal from "../routes/CorporateActions/CorporateActionProfile/NotesModal";

const CorporateActionsTable = ({ api, data, updateCorporateAction }) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        id: "CorporateActionTable",
        columns: [
          {
            accessor: "assetCode",
            Header: "Security",
            Cell: ({ cell, row }) => <span style={{ opacity: row.original.overrideId ? ".5" : "" }}>{cell.value}</span>,
          },
          {
            accessor: "description",
            Header: "Description",
            Cell: ({ cell, row }) => <span style={{ opacity: row.original.overrideId ? ".5" : "" }}>{cell.value}</span>,
          },
          {
            accessor: "dateOfAnnouncement",
            Header: "Date of announcement",
            Cell: ({ cell, row }) => (
              <span style={{ opacity: row.original.overrideId ? ".5" : "" }}>{toDateString(cell.value)}</span>
            ),
          },
          {
            accessor: "dateOfRevision",
            Header: "Date of revision",
            Cell: ({ cell, row }) => (
              <span style={{ opacity: row.original.overrideId ? ".5" : "" }}>{toDateString(cell.value)}</span>
            ),
          },
          {
            accessor: "processingDate",
            Header: "Processing date",
            Cell: ({ cell, row }) => (
              <span style={{ opacity: row.original.overrideId ? ".5" : "" }}>{toDateString(cell.value)}</span>
            ),
          },
          {
            accessor: "exDate",
            Header: "Ex date",
            Cell: ({ cell, row }) => (
              <span style={{ opacity: row.original.overrideId ? ".5" : "" }}>{toDateString(cell.value)}</span>
            ),
          },
          {
            accessor: "recordDate",
            Header: "Record date",
            Cell: ({ cell, row }) => (
              <span style={{ opacity: row.original.overrideId ? ".5" : "" }}>{toDateString(cell.value)}</span>
            ),
          },
          {
            accessor: "paymentDate",
            Header: "Payment date",
            Cell: ({ cell, row }) => (
              <span style={{ opacity: row.original.overrideId ? ".5" : "" }}>{toDateString(cell.value)}</span>
            ),
          },
          {
            accessor: "applicationClosingDate",
            Header: "Application closing date",
            Cell: ({ cell, row }) => (
              <span style={{ opacity: row.original.overrideId ? ".5" : "" }}>{toDateString(cell.value)}</span>
            ),
          },
          {
            id: "actions",
            headerStyle: {
              width: "3rem",
            },
            rightAlign: true,
            sort: false,
            Cell: ({ row }) => (
              <>
                <QualityControlNotesModal corporateAction={row.original} />
                <Tooltip title="View">
                  <div style={{ display: "inline-block", marginLeft: "1rem" }}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      onClick={() => navigate(`/mse-corp-action/corporate-actions/${row.original.uuid}`)}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title={row.original.overrideId ? "Already overridden" : "Update"}>
                  <div style={{ display: "inline-block", marginLeft: "1rem" }}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => {
                        if (!row.original.overrideId)
                          navigate(`/mse-corp-action/corporate-actions/${row.original.uuid}/update`);
                      }}
                      style={{
                        opacity: row.original.overrideId ? "0.25" : "",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Tooltip>
                <EntitlementsModal corporateAction={row.original} useIcon />
                <TagsModal
                  originalCorporateAction={row.original}
                  updateCorporateAction={updateCorporateAction}
                  useIcon
                />
                <NotesModal corporateAction={row.original} setCorporateAction={updateCorporateAction} useIcon />
              </>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      api={{
        limit: api.response.data.limit,
        offset: api.response.data.offset,
        totalNumberOfRecords: api.response.data.totalNumberOfRecords,
        fetch: api.fetch,
        params: api.lastParams,
      }}
      columns={columns}
      data={data}
      sortBy={[
        {
          id: "exDate",
          desc: true,
        },
      ]}
    />
  );
};

export default CorporateActionsTable;
