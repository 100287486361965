import {
  Card,
  Grid,
  Col,
  CardBody,
  Modal,
  CardHeader,
  CardTitle,
  IconButton,
  InfoBox,
  CardActions,
  Button,
} from "capitalroadkit";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";
import { Form, FormikProvider, useFormik } from "formik";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import useAPI from "../../../../../hooks/useAPI";
import { addTagAxios, removeTagAxios } from "../../../api/CorporateActionsResource";
import TagForm, { TagFormInitialValues, TagFormSchema } from "../../../forms/TagForm";

const TagsModal = ({ originalCorporateAction, updateCorporateAction, useIcon }) => {
  const [open, setOpen] = useState(false);
  const [corporateAction, setCorporateAction] = useState(originalCorporateAction);
  const [tagToAdd, setTagToAdd] = useState(null);
  const [tagToRemove, setTagToRemove] = useState(null);

  const addTagAPI = useAPI(addTagAxios(corporateAction?.uuid, tagToAdd), { immediate: false });

  useEffect(() => {
    if (tagToAdd) addTagAPI.fetch();
  }, [tagToAdd]);

  useEffect(() => {
    const { response, loading, error } = addTagAPI;
    if (response && !loading && !error) {
      setCorporateAction(response.data);
      setTagToAdd(null);
    }
  }, [addTagAPI.response, addTagAPI.loading, addTagAPI.error]);

  const removeTagAPI = useAPI(removeTagAxios(corporateAction?.uuid, tagToRemove), { immediate: false });

  useEffect(() => {
    if (tagToRemove) removeTagAPI.fetch();
  }, [tagToRemove]);

  useEffect(() => {
    const { response, loading, error } = removeTagAPI;
    if (response && !loading && !error) {
      setCorporateAction(response.data);
      setTagToRemove(null);
    }
  }, [removeTagAPI.response, removeTagAPI.loading, removeTagAPI.error]);

  // todo: use dot notation here
  const tagForm = useFormik({
    initialValues: TagFormInitialValues,
    onSubmit: (values) => setTagToAdd(values.tag),
    validationSchema: TagFormSchema,
  });

  const error = addTagAPI.error || removeTagAPI.error;
  const loading = addTagAPI.loading || removeTagAPI.loading;

  return (
    <>
      <Tooltip title="Add tags">
        <div style={{ display: "inline-block", marginLeft: useIcon ? "1rem" : "" }}>
          {useIcon ? (
            <FontAwesomeIcon icon={faTags} onClick={() => setOpen(true)} style={{ cursor: "pointer" }} />
          ) : (
            <Button
              color="primary"
              variant="text"
              onClick={() => setOpen(true)}
              style={{ cursor: "pointer", marginLeft: "4px" }}
            >
              + Tag
            </Button>
          )}
        </div>
      </Tooltip>
      <Modal open={open}>
        {open && (
          <Card>
            <CardHeader>
              <CardTitle>Tags</CardTitle>
              <CardActions>
                <IconButton
                  icon={faTimes}
                  onClick={() => {
                    updateCorporateAction(corporateAction);
                    setOpen(false);
                  }}
                />
              </CardActions>
            </CardHeader>
            <CardBody>
              <FormikProvider value={tagForm}>
                <Form>
                  <Grid nested>
                    <TagForm />
                    <Col sm={4} style={{ marginTop: "1.5rem" }}>
                      <Button color="primary" loading={loading} type="submit">
                        Add
                      </Button>
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                {error && <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />}
                {corporateAction?.tags?.length > 0 &&
                  corporateAction.tags.map((tag) => <Chip onDelete={() => setTagToRemove(tag)} label={tag} />)}
              </div>
            </CardBody>
          </Card>
        )}
      </Modal>
    </>
  );
};

export default TagsModal;
