import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import { NavLink, useNavigate } from "react-router-dom";

import { useUser } from "./context/UserContext";
import { useHealth } from "./context/HealthContext";
import { useRoles } from "./context/RoleContext";

const Navigation = ({ children }) => {
  const navigate = useNavigate();

  const [, , logout] = useUser();

  const { assetServiceHealthCheckAPI, cashServiceHealthCheckAPI, corporateActionsServiceHealthCheckAPI } = useHealth();
  const { assetServiceUserRolesAPI, cashServiceUserRolesAPI, corporateActionsServiceUserRolesAPI } = useRoles();

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <NavLink to="/">
              <Typography variant="h6">MICROSERVICE UI</Typography>
            </NavLink>
          </div>
          {assetServiceHealthCheckAPI.response && assetServiceUserRolesAPI.response && (
            <Button color="inherit" onClick={() => navigate("/mse-asset")}>
              Asset service
            </Button>
          )}
          {cashServiceHealthCheckAPI.response && cashServiceUserRolesAPI.response && (
            <Button color="inherit" onClick={() => navigate("/mse-cash-active")}>
              Cash service
            </Button>
          )}
          {corporateActionsServiceHealthCheckAPI.response && corporateActionsServiceUserRolesAPI.response && (
            <Button color="inherit" onClick={() => navigate("/mse-corp-action")}>
              Corporate actions service
            </Button>
          )}
          <Button color="inherit" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {children}
    </>
  );
};

export default Navigation;
