import { Card, CardBody, Col, Grid, Page, PageHeader, Button, InfoBox } from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useLocation } from "react-router";

import LedgerTransactionSearchForm from "../../forms/LedgerTransactionSearchForm";
import TransactionLedgerModal from "./TransactionLedgerModal";

import PostTransactionModal from "./PostTransactionModal";
import TransactionNotesModal from "./TransactionNotesModal";
import TransactionsTable from "./TransactionsTable";
import UpdateTransactionModal from "./UpdateTransactionModal";
import { searchLedgerTransactionsAxios } from "../../api/CashActiveEntityResource";
import useAPI from "../../../../hooks/useAPI";
import Loading from "../../../../Loading";
import SummaryType from "../../../../types/CashService/Transactions/SummaryType";
import PostingStatus from "../../../../types/CashService/Transactions/PostingStatus";
import { getLedgerAxios } from "../../api/LedgerResource";
import LedgerSubType from "../../../../types/CashService/LedgerSubType";

const Transactions = () => {
  const location = useLocation();
  const { uuid, subType, group } = location.state;

  const ledgerUuid = uuid === SummaryType.RETAIL_CLIENT || uuid === SummaryType.NON_RETAIL_CLIENT ? null : uuid;

  const searchLedgerTransactionsAPI = useAPI(searchLedgerTransactionsAxios(), {
    initialParams: {
      postingStatus: ledgerUuid ? null : PostingStatus.PENDING,
      subType: subType || LedgerSubType.CLI,
      group,
      ledgerUuid: ledgerUuid,
    },
  });

  const getLedgerAPI = useAPI(getLedgerAxios(ledgerUuid), { immediate: ledgerUuid });

  const [transaction, setTransaction] = useState(null);
  const [notesTransaction, setNotesTransaction] = useState(null);
  const [updateTransaction, setUpdateTransaction] = useState(null);
  const [postTransaction, setPostTransaction] = useState(null);

  const getHiddenColumns = () => {
    const hiddenColumns = [];
    if (subType === LedgerSubType.NAT) hiddenColumns.push("naturalAccountSource");
    else if (subType && subType !== LedgerSubType.CLI)
      return hiddenColumns.concat(["naturalAccountSource", "transactionChannel", "statusOfPosting"]);
    return hiddenColumns;
  };

  const clientTransactionForm = useFormik({
    initialValues: LedgerTransactionSearchForm.initialValues(ledgerUuid, subType, group),
    onSubmit: (values) => {
      searchLedgerTransactionsAPI.fetch(LedgerTransactionSearchForm.clean(values));
    },
  });

  const { response, error, loading } = searchLedgerTransactionsAPI;

  if (response) {
    return (
      <Page id="client-transactions">
        <Grid>
          <PageHeader
            heading={getLedgerAPI.response ? `Transactions - ${getLedgerAPI.response.data.name}` : "Transaction search"}
          />
          <Col sm={12}>
            <Card>
              <CardBody>
                {error && (
                  <Grid nested>
                    <Col sm={12}>
                      <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
                    </Col>
                  </Grid>
                )}
                <FormikProvider value={clientTransactionForm}>
                  <Form>
                    <Grid nested>
                      <Col
                        sm={12}
                        style={{
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <LedgerTransactionSearchForm ledger={ledgerUuid} />
                        <Button
                          color="primary"
                          style={{ marginTop: "16px" }}
                          loading={searchLedgerTransactionsAPI.loading}
                          type="submit"
                        >
                          Search
                        </Button>
                      </Col>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardBody>
              <TransactionsTable
                data={searchLedgerTransactionsAPI.response.data.list}
                api={searchLedgerTransactionsAPI}
                hiddenColumns={getHiddenColumns()}
                rowClick={null}
                isNaturalLedger={subType === LedgerSubType.NAT}
                isClient
                setNotesTransaction={setNotesTransaction}
                setPostTransaction={setPostTransaction}
                setTransaction={setTransaction}
                setUpdateTransaction={setUpdateTransaction}
                showLedgerInfo={!ledgerUuid}
              />
            </Card>
          </Col>
          <TransactionNotesModal setTransaction={setNotesTransaction} transaction={notesTransaction} />
          <TransactionLedgerModal setTransaction={setTransaction} transaction={transaction} />
          <UpdateTransactionModal
            form={clientTransactionForm}
            setTransaction={setUpdateTransaction}
            transaction={updateTransaction}
          />
          <PostTransactionModal
            form={clientTransactionForm}
            setTransaction={setPostTransaction}
            transaction={postTransaction}
          />
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page id="Asset">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default Transactions;
