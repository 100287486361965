const EnvironmentEnums = Object.freeze({
  LOCALHOST: "LOCALHOST",
  DEV: "DEV",
  DEV2: "DEV2",
  SIT: "SIT",
  UAT: "UAT",
  DEMO2: "DEMO2",
  PROD: "PROD",
});

export default EnvironmentEnums;
