import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import {
  Button,
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Grid,
  IconButton,
  Modal,
  InfoBox,
} from "capitalroadkit";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useAPI from "../../../../hooks/useAPI";
import { getLedgerForTransactionAxios } from "../../api/TransactionResource";
import Loading from "../../../../Loading";

const TransactionLedgerModal = ({ setTransaction, transaction }) => {
  const getLedgerForTransactionAPI = useAPI(getLedgerForTransactionAxios(transaction?.uuid), { immediate: false });

  useEffect(() => {
    if (transaction) {
      getLedgerForTransactionAPI.fetch();
    }
  }, [transaction]);

  const { response, loading, error } = getLedgerForTransactionAPI;

  return (
    <Modal open={response && transaction}>
      {loading && <Loading />}
      {!loading && response && (
        <Card>
          <CardHeader>
            <CardTitle>Ledger information</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setTransaction(null)} />
            </CardActions>
          </CardHeader>
          <CardBody>
            <table
              style={{
                borderCollapse: "collapse",
                tableLayout: "fixed",
                textAlign: "left",
                width: "100%",
              }}
            >
              <tr>
                <th>Name</th>
                <td>
                  <Link
                    to={"/mse-cash-active/transactions"}
                    state={{
                      uuid: response.data.uuid,
                      subType: response.data.ledgerType,
                      group: response.data.group,
                    }}
                  >
                    {response.data.name}
                  </Link>
                </td>
              </tr>
              <br />
              <tr>
                <th>Holders reference</th>
                <td>{response.data.holdersReference}</td>
              </tr>
              <tr>
                <th>Internal status</th>
                <td>{response.data.internalStatus}</td>
              </tr>
              <tr>
                <th>External status</th>
                <td>{response.data.externalStatus}</td>
              </tr>
              <tr>
                <th>Group</th>
                <td>{response.data.group}</td>
              </tr>
              <tr>
                <th>Ledger type</th>
                <td>{response.data.ledgerType}</td>
              </tr>
            </table>
            <br />
            <Grid nested>
              <Col sm={12} style={{ textAlign: "right" }}>
                <Button color="danger" onClick={() => setTransaction(null)}>
                  Close
                </Button>
              </Col>
            </Grid>
          </CardBody>
        </Card>
      )}
      {error && (
        <Card>
          <Grid>
            <Col sm={12}>
              <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
            </Col>
          </Grid>
        </Card>
      )}
    </Modal>
  );
};

export default TransactionLedgerModal;
