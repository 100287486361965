const CorporateActionType = Object.freeze({
  GENERIC: "GENERIC",
  DIVIDEND: "DIVIDEND",
  BUY_BACK: "BUY_BACK",
  ENTITLEMENT_ISSUE: "ENTITLEMENT_ISSUE", //subtypes: SHARE_PURCHASE_PLAN / PRIORITY_ISSUE / RIGHTS_RENOUNCEABLE / RIGHTS_NON_RENOUNCEABLE / OTHER
  CONSOLIDATION: "CONSOLIDATION",
  BONUS: "BONUS",
  TAKEOVER: "TAKEOVER",
  SECURITY_NAME_CHANGE: "SECURITY_NAME_CHANGE",
  LOCAL_CODE_CHANGE: "LOCAL_CODE_CHANGE",
  DEMERGER: "DEMERGER",
  COMPANY_MEETING: "COMPANY_MEETING",
  INTEREST: "INTEREST",
  // PLACEMENT: "PLACEMENT",
  RETURN_OF_CAPITAL: "RETURN_OF_CAPITAL",
  // PROXY_VOTING: "PROXY_VOTING",
  // RECONSTRUCTION: "RECONSTRUCTION",
  // PRIORITY_ISSUE: "PRIORITY_ISSUE",
  // MERGER: "MERGER",
  // EQUITY_OPTIONS_EXPIRING: "EQUITY_OPTIONS_EXPIRING",
  // DELISTING: "DELISTING",
  // OTHER: "OTHER",
});

export default CorporateActionType;
