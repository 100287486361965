import { Col, TextField } from "capitalroadkit";
import { Field } from "formik";
import withNameSpace from "../../../../../../../../../../functions/withNameSpace";

const fields = {
  BSB_NUMBER: "bsbNumber",
  ACCOUNT_NUMBER: "accountNumber",
};

const initialValues = {
  [fields.BSB_NUMBER]: "",
  [fields.ACCOUNT_NUMBER]: "",
};

const DomesticPayeeForm = ({ nameSpace }) => {
  return (
    <>
      <Col sm={12}>
        <Field component={TextField} label="BSB number" name={withNameSpace(nameSpace, fields.BSB_NUMBER)} required />
      </Col>
      <Col sm={12}>
        <Field
          component={TextField}
          label="Account number"
          name={withNameSpace(nameSpace, fields.ACCOUNT_NUMBER)}
          required
        />
      </Col>
    </>
  );
};

DomesticPayeeForm.fields = fields;
DomesticPayeeForm.initialValues = initialValues;

export default DomesticPayeeForm;
