import {
  InfoBox,
  Card,
  CardTitle,
  CardActions,
  CardHeader,
  Col,
  Grid,
  Page,
  PageHeader,
  Button,
  CardBody,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import clone from "lodash/cloneDeep";
import { useNavigate } from "react-router-dom";

import { listAnnouncementsAxios } from "../api/AnnouncementsResource";
import useAPI from "../../../hooks/useAPI";
import AnnouncementTable from "../components/AnnouncementsTable";
import Loading from "../../../Loading";
import AnnouncementsSearchForm from "../forms/AnnouncementsSearchForm";

const Announcements = () => {
  const navigate = useNavigate();

  const listAnnouncementsAPI = useAPI(listAnnouncementsAxios(), { initialParams: { limit: 10 } });

  const announcementsSearchForm = useFormik({
    initialValues: AnnouncementsSearchForm.initialValues,
    onSubmit: (values) => {
      const clean = clone(values);

      if (values.securityCode) clean.securityCode = values.securityCode.toUpperCase();
      for (const propName in clean) {
        if (clean[propName] === "") {
          delete clean[propName];
        }
      }

      listAnnouncementsAPI.fetch({ ...clean, limit: 10 });
    },
  });

  const error = listAnnouncementsAPI.error;
  const loading = listAnnouncementsAPI.loading;

  if (listAnnouncementsAPI.response) {
    return (
      <Page id="Announcements">
        <Grid>
          <PageHeader heading="Announcements" />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Announcement list</CardTitle>
              </CardHeader>
              <CardBody>
                <FormikProvider value={announcementsSearchForm}>
                  <Form>
                    <div style={{ alignItems: "end", display: "flex", flexWrap: "wrap", gap: "16px" }}>
                      <AnnouncementsSearchForm />
                      <Button color="primary" loading={loading} type="submit">
                        Search
                      </Button>
                    </div>
                  </Form>
                </FormikProvider>
              </CardBody>
              <AnnouncementTable api={listAnnouncementsAPI} data={listAnnouncementsAPI.response.data.list} />
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page id="Announcements">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default Announcements;
