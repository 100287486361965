import { TextField } from "capitalroadkit";
import clone from "lodash/cloneDeep";
import { Field } from "formik";
import withNameSpace from "../../../../../functions/withNameSpace";
import React from "react";
import RoundingMode from "../../../../../types/corporateActions/Activities/RoundingMode";
import { MenuItem } from "@material-ui/core";
import { parseEnum } from "../../../../../resources/formatters/Formatter";
import VolumeCalculatorForm from "./index";

const fields = {
  NUMERATOR: "numerator",
  DENOMINATOR: "denominator",
  ROUNDING_MODE: "roundingMode",
  SCALE: "scale",
};

const initialValues = (volumeCalculator) => ({
  [fields.NUMERATOR]: volumeCalculator?.numerator || "",
  [fields.DENOMINATOR]: volumeCalculator?.denominator || "",
  [fields.ROUNDING_MODE]: volumeCalculator?.roundingMode || RoundingMode.HALF_UP,
  [fields.SCALE]: volumeCalculator?.scale || volumeCalculator?.scale === 0 ? volumeCalculator?.scale : 2,
});

const clean = (values, calculatorField) => {
  let cleanValues = clone(values);

  const volumeCalculator = {
    type: cleanValues[calculatorField][VolumeCalculatorForm.fields.TYPE],
    numerator: cleanValues[calculatorField][fields.NUMERATOR],
    denominator: cleanValues[calculatorField][fields.DENOMINATOR],
    roundingMode: cleanValues[calculatorField][fields.ROUNDING_MODE],
    scale: cleanValues[calculatorField][fields.SCALE],
  };

  for (const propName in volumeCalculator) {
    if (volumeCalculator[propName] === "") {
      delete volumeCalculator[propName];
    }
  }

  return volumeCalculator;
};

const RatioForm = ({ nameSpace }) => {
  return (
    <>
      <Field component={TextField} label="Numerator" name={withNameSpace(nameSpace, fields.NUMERATOR)} required />
      <Field component={TextField} label="Denominator" name={withNameSpace(nameSpace, fields.DENOMINATOR)} required />
      <Field
        component={TextField}
        label="Rounding mode"
        name={withNameSpace(nameSpace, fields.ROUNDING_MODE)}
        required
        select
      >
        {Object.values(RoundingMode).map((mode) => (
          <MenuItem key={mode} value={mode}>
            {parseEnum(mode)}
          </MenuItem>
        ))}
      </Field>
      <Field component={TextField} label="Scale" name={withNameSpace(nameSpace, fields.SCALE)} required />
    </>
  );
};

RatioForm.fields = fields;
RatioForm.initialValues = initialValues;
RatioForm.clean = clean;

export default RatioForm;
