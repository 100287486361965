import { Navigate, useRoutes } from "react-router-dom";

import AssetService from "./AssetService";
import Assets from "./AssetService/routes/Assets";
import AssetsIndex from "./AssetService/routes/Assets/AssetsIndex";
import AssetProfile from "./AssetService/routes/Assets/AssetProfile";
import NewAsset from "./AssetService/routes/Assets/NewAsset";
import UpdateAsset from "./AssetService/routes/Assets/UpdateAsset";

import CashService from "./CashService";
import LedgerAccounts from "./CashService/routes/LedgerBalance/children/ClientLedger/children/Accounts";
import ClientLedgerNewPayment from "./CashService/routes/LedgerBalance/children/ClientLedger/children/Payments/children/NewPayment";
import ClientLedgers from "./CashService/routes/Clients/ClientLedgersIndex";
import LedgerBalance from "./CashService/routes/LedgerBalance/index";
import LedgerBalanceIndex from "./CashService/routes/LedgerBalance/children/LedgerBalanceIndex";
import Transactions from "./CashService/routes/Transactions/Transactions";
import NewAccount from "./CashService/routes/Clients/NewAccount";
import NewPayee from "./CashService/routes/LedgerBalance/children/ClientLedger/children/Payees/children/NewPayee";
import Payees from "./CashService/routes/LedgerBalance/children/ClientLedger/children/Payees";
import PayeesIndex from "./CashService/routes/LedgerBalance/children/ClientLedger/children/Payees/children/PayeesIndex";
import ClientLedger from "./CashService/routes/LedgerBalance/children/ClientLedger";
import ClientLedgerPayments from "./CashService/routes/LedgerBalance/children/ClientLedger/children/Payments";
import ClientLedgerPaymentsIndex from "./CashService/routes/LedgerBalance/children/ClientLedger/children/Payments/children/PaymentsIndex";
import Workflow from "./CashService/routes/Workflow";
import Payments from "./CashService/routes/Payments";
import ClientLedgerPaymentSchedules from "./CashService/routes/LedgerBalance/children/ClientLedger/children/PaymentSchedules";
import ClientLedgerPaymentSchedulesIndex from "./CashService/routes/LedgerBalance/children/ClientLedger/children/PaymentSchedules/children/PaymentSchedulesIndex";
import ClientLedgerNewPaymentSchedule from "./CashService/routes/LedgerBalance/children/ClientLedger/children/PaymentSchedules/children/NewPaymentSchedule";

import CorporateActionsService from "./CorporateActionsService";
import Announcements from "./CorporateActionsService/routes/Announcements";
import CorporateActions from "./CorporateActionsService/routes/CorporateActions";
import CorporateActionsIndex from "./CorporateActionsService/routes/CorporateActions/CorporateActionsIndex";
import CorporateActionProfile from "./CorporateActionsService/routes/CorporateActions/CorporateActionProfile";
import NewCorporateAction from "./CorporateActionsService/routes/CorporateActions/NewCorporateAction";
import OverrideCorporateAction from "./CorporateActionsService/routes/CorporateActions/OverrideCorporateAction";
import UpdateCorporateAction from "./CorporateActionsService/routes/UpdateCorporateAction";

import Home from "./Home";
import Welcome from "./Welcome";

const Routes = () => {
  return useRoutes([
    { index: true, element: <Home /> },
    { path: "welcome", element: <Welcome /> },

    {
      path: "mse-asset",
      element: <AssetService />,
      children: [
        {
          index: true,
          element: <Navigate to="assets" />,
        },
        {
          path: "assets",
          element: <Assets />,
          children: [
            { index: true, element: <AssetsIndex /> },
            { path: "new", element: <NewAsset /> },
            { path: ":uuid", element: <AssetProfile /> },
            { path: ":uuid/update", element: <UpdateAsset /> },
          ],
        },
      ],
    },

    {
      path: "mse-cash-active",
      element: <CashService />,
      children: [
        {
          index: true,
          element: <Navigate to="ledger-balance" />,
        },
        {
          path: "client/new",
          element: <NewAccount />,
        },
        {
          path: "client-ledgers",
          element: <ClientLedgers />,
        },
        {
          path: "transactions",
          element: <Transactions />,
        },
        {
          path: "payments",
          element: <Payments />,
        },
        {
          path: "workflows",
          element: <Workflow />,
        },
        {
          path: "ledger-balance",
          element: <LedgerBalance />,
          children: [
            { index: true, element: <LedgerBalanceIndex /> },
            {
              path: ":uuid",
              element: <ClientLedger />,
              children: [
                { path: "accounts", element: <LedgerAccounts /> },
                {
                  path: "payees",
                  element: <Payees />,
                  children: [
                    { index: true, element: <PayeesIndex /> },
                    { path: "new", element: <NewPayee /> },
                  ],
                },
                {
                  path: "payments",
                  element: <ClientLedgerPayments />,
                  children: [
                    { index: true, element: <ClientLedgerPaymentsIndex /> },
                    { path: "new", element: <ClientLedgerNewPayment /> },
                  ],
                },
                {
                  path: "payment-schedules",
                  element: <ClientLedgerPaymentSchedules />,
                  children: [
                    { index: true, element: <ClientLedgerPaymentSchedulesIndex /> },
                    { path: "new", element: <ClientLedgerNewPaymentSchedule /> },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },

    {
      path: "mse-corp-action",
      element: <CorporateActionsService />,
      children: [
        {
          index: true,
          element: <Navigate to="corporate-actions" />,
        },
        {
          path: "announcements",
          element: <Announcements />,
        },
        {
          path: "corporate-actions",
          element: <CorporateActions />,
          children: [
            { index: true, element: <CorporateActionsIndex /> },
            { path: "new", element: <NewCorporateAction /> },
            { path: ":uuid", element: <CorporateActionProfile /> },
            { path: ":uuid/override", element: <OverrideCorporateAction /> },
            { path: ":uuid/update", element: <UpdateCorporateAction /> },
          ],
        },
      ],
    },
  ]);
};

export default Routes;
