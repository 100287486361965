import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { Tooltip } from "@material-ui/core";
import {
  Button,
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Grid,
  IconButton,
  InfoBox,
  Modal,
} from "capitalroadkit";
import { useEffect, useState } from "react";

import useAPI from "../../../../hooks/useAPI";
import PayeeStatusEnum from "../../../../types/CashService/Payees/PayeeStatusEnum";
import { approvePayeeAxios, deletePayeeAxios, rejectPayeeAxios } from "../../api/PayeeResource";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";

const PayeeTableActions = ({ payee, refreshData }) => {
  const [open, setOpen] = useState(false);

  const approvePayeeAPI = useAPI(approvePayeeAxios(payee.uuid), { immediate: false });

  useEffect(() => {
    const { response, loading } = approvePayeeAPI;

    if (response && !loading) refreshData();
  }, [approvePayeeAPI.response, approvePayeeAPI.loading]);

  const rejectPayeeAPI = useAPI(rejectPayeeAxios(payee.uuid), { immediate: false });

  useEffect(() => {
    const { response, loading } = rejectPayeeAPI;

    if (response && !loading) refreshData();
  }, [rejectPayeeAPI.response, rejectPayeeAPI.loading]);

  const deletePayeeAPI = useAPI(deletePayeeAxios(payee.uuid), { immediate: false });

  useEffect(() => {
    const { response, loading } = deletePayeeAPI;

    if (response && !loading) refreshData();
  }, [deletePayeeAPI.response, deletePayeeAPI.loading]);

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      {payee.status === PayeeStatusEnum.CREATED && (
        <Tooltip title="Approve/Reject">
          <div onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faPencil} />
          </div>
        </Tooltip>
      )}

      <Modal open={open}>
        <Card>
          <CardHeader>
            <CardTitle>Update status</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setOpen(false)} />
            </CardActions>
          </CardHeader>
          <CardBody>
            <Grid nested>
              <Col sm={12} style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button
                  color="success"
                  disabled={approvePayeeAPI.response || rejectPayeeAPI.response || rejectPayeeAPI.loading}
                  loading={approvePayeeAPI.loading}
                  onClick={() => approvePayeeAPI.fetch()}
                >
                  Approve
                </Button>
                <Button
                  color="danger"
                  disabled={approvePayeeAPI.response || rejectPayeeAPI.response || rejectPayeeAPI.loading}
                  loading={rejectPayeeAPI.loading}
                  onClick={() => rejectPayeeAPI.fetch()}
                >
                  Reject
                </Button>
              </Col>
              {approvePayeeAPI.response && (
                <Col sm={12}>
                  <div style={{ maxWidth: "512px", width: "100%" }}>
                    <InfoBox variant={InfoBox.Variant.SUCCESS} value="Payment approved successfully" />
                  </div>
                </Col>
              )}
              {approvePayeeAPI.error && (
                <Col sm={12}>
                  <div style={{ maxWidth: "512px", width: "100%" }}>
                    <InfoBox variant={InfoBox.Variant.ERROR} value={approvePayeeAPI.error.response?.data} />
                  </div>
                </Col>
              )}
              {rejectPayeeAPI.response && (
                <Col sm={12}>
                  <div style={{ maxWidth: "512px", width: "100%" }}>
                    <InfoBox variant={InfoBox.Variant.SUCCESS} value="Payment approved successfully" />
                  </div>
                </Col>
              )}
              {rejectPayeeAPI.error && (
                <Col sm={12}>
                  <div style={{ maxWidth: "512px", width: "100%" }}>
                    <InfoBox variant={InfoBox.Variant.ERROR} value={approvePayeeAPI.error.response?.data} />
                  </div>
                </Col>
              )}
            </Grid>
          </CardBody>
        </Card>
      </Modal>

      {payee.status !== PayeeStatusEnum.DELETED && (
        <Tooltip title="Delete">
          <div onClick={() => deletePayeeAPI.fetch()} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default PayeeTableActions;
