import { Table } from "capitalroadkit";
import React, { useCallback, useMemo } from "react";
import { parseEnum, toCurrency, toDateString } from "../../../../../../resources/formatters/Formatter";
import ActivityComponentsTable from "./ActivityComponentsTable";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import ActivityType from "../../../../../../types/corporateActions/Activities/ActivityType";

const ModalActivityTable = ({ data }) => {
  const sortedData = data.sort((a, b) => a.participationCondition > b.participationCondition);

  const columns = useMemo(
    () => [
      {
        id: "EntitlementsTable",
        Header: "",
        Footer: "",
        columns: [
          {
            accessor: "type",
            Header: "Type",
            Cell: ({ cell, row }) => (
              <>
                {cell.value === ActivityType.INCOME_ACTIVITY && (
                  <Tooltip title="Activity components">
                    <span style={{ marginRight: ".75rem" }}>
                      {row.isExpanded ? (
                        <FontAwesomeIcon icon={faChevronDown} size="sm" {...row.getToggleRowExpandedProps()} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronRight} size="sm" {...row.getToggleRowExpandedProps()} />
                      )}
                    </span>
                  </Tooltip>
                )}
                {parseEnum(cell.value)}
              </>
            ),
          },
          {
            accessor: "assetCode",
            Header: "Asset code",
          },
          {
            accessor: "participationCondition",
            Header: "Condition",
            Cell: ({ cell }) => parseEnum(cell.value),
          },
          {
            accessor: "transactionComponent.transactionDate",
            Header: "Transaction date",
            rightAlign: true,
            Cell: ({ cell }) => toDateString(cell.value),
          },
          {
            accessor: "transactionComponent.settlementDate",
            Header: "Settlement date",
            rightAlign: true,
            Cell: ({ cell }) => toDateString(cell.value),
          },
          {
            accessor: "entitlement",
            Header: "Entitlement",
            rightAlign: true,
            Cell: ({ cell, row: { original } }) => {
              if (
                original.type === ActivityType.ASSET_BUY_ACTIVITY ||
                original.type === ActivityType.ASSET_SELL_ACTIVITY
              )
                return cell.value.toLocaleString();
              return toCurrency(cell.value);
            },
          },
        ],
      },
    ],
    []
  );

  const subComponent = useCallback(
    ({ row: { original } }) => <ActivityComponentsTable data={original.activityComponents} />,
    []
  );

  return <Table columns={columns} data={sortedData} subComponent={subComponent} />;
};

export default ModalActivityTable;
