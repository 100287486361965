const AssetType = Object.freeze({
  CASH: "CASH",
  COLLECTABLE: "COLLECTABLE",
  DEBT_SECURITY: "DEBT_SECURITY",
  DEPOSITORY_RECEIPT: "DEPOSITORY_RECEIPT",
  EQUITY: "EQUITY",
  EXCHANGE_TRADED_FUND: "EXCHANGE_TRADED_FUND",
  MANAGED_FUND: "MANAGED_FUND",
  REAL_PROPERTY: "REAL_PROPERTY",
});

export default AssetType;
