import { MenuItem } from "@material-ui/core";
import { Col, InfoBox, TextField, useTheme } from "capitalroadkit";
import { Field } from "formik";
import clone from "lodash/cloneDeep";
import { useEffect } from "react";

import useAPI from "../../../../../../../../../hooks/useAPI";
import { listPayeesAxios } from "../../../../../../../api/ClientLedgerResource";
import { useClientLedger } from "../../../context/ClientLedgerContext";
import Loading from "../../../../../../../../../Loading";
import withNameSpace from "../../../../../../../../../functions/withNameSpace";
import PayeeTypeEnum from "../../../../../../../../../types/CashService/Payees/PayeeTypeEnum";
import PayeeStatusEnum from "../../../../../../../../../types/CashService/Payees/PayeeStatusEnum";

const fields = {
  PAYEE: "payee",
  AMOUNT: "amount",
  NARRATION: "narration",
};

const initialValues = {
  [fields.PAYEE]: "",
  [fields.AMOUNT]: "",
  [fields.NARRATION]: "",
};

const clean = (values) => {
  const cleanValues = clone(values);

  cleanValues[fields.PAYEE] = { uuid: values[fields.PAYEE] };

  return cleanValues;
};

const PaymentRequestForm = ({ form, nameSpace }) => {
  const clientLedger = useClientLedger();
  const theme = useTheme();

  const listPayeesAPI = useAPI(listPayeesAxios(clientLedger.uuid), {
    initialParams: { status: PayeeStatusEnum.APPROVED },
  });

  useEffect(() => {
    const { response, loading } = listPayeesAPI;

    if (response && !loading && response.data.length > 0) form.setFieldValue(fields.PAYEE, response.data[0].uuid);
  }, [listPayeesAPI.response, listPayeesAPI.loading]);

  const response = listPayeesAPI.response;
  const error = listPayeesAPI.error;
  const loading = listPayeesAPI.loading;

  if (response) {
    const payees = listPayeesAPI.response.data.filter((payee) => payee.type === PayeeTypeEnum.DOMESTIC_PAYEE);

    if (payees.length > 0) {
      return (
        <>
          <Col sm={12}>
            <Field component={TextField} label="Payee" name={withNameSpace(nameSpace, fields.PAYEE)} required select>
              {payees.map((payee) => (
                <MenuItem value={payee.uuid}>
                  {payee.name}{" "}
                  {payee.nickName && <span style={{ color: theme.palette.font.muted }}>({payee.nickname})</span>} -{" "}
                  {payee.bsbNumber}:{payee.accountNumber}
                </MenuItem>
              ))}
            </Field>
          </Col>
          <Col sm={12}>
            <Field
              component={TextField}
              format={TextField.Format.CURRENCY}
              label="Amount"
              name={withNameSpace(nameSpace, fields.AMOUNT)}
              required
            />
          </Col>
          <Col sm={12}>
            <Field component={TextField} label="Narration" name={withNameSpace(nameSpace, fields.NARRATION)} required />
          </Col>
        </>
      );
    }

    return (
      <Col sm={12}>
        <InfoBox
          variant={InfoBox.Variant.ERROR}
          value="You must have at least one approved domestic payee to make a payment"
        />
      </Col>
    );
  }
  if (loading) return <Loading />;
  if (error) return <InfoBox variant={InfoBox.Variant.ERROR} value={error.response?.data} />;
  return null;
};

PaymentRequestForm.fields = fields;
PaymentRequestForm.initialValues = initialValues;
PaymentRequestForm.clean = clean;

export default PaymentRequestForm;
