import { Card, Grid, Col, CardBody, Tab, Tabs } from "capitalroadkit";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import useAPI from "../../../../../hooks/useAPI";
import { removeInternalNoteAxios, removeNoteAxios } from "../../../api/CorporateActionsResource";

const CorporateActionNotes = ({ ca, setCorporateAction }) => {
  const [noteToRemove, setNoteToRemove] = useState(null);
  const [internalNoteToRemove, setInternalNoteToRemove] = useState(null);

  const removeNoteAPI = useAPI(removeNoteAxios(ca?.uuid, noteToRemove), { immediate: false });

  useEffect(() => {
    if (noteToRemove !== null) removeNoteAPI.fetch();
  }, [noteToRemove]);

  useEffect(() => {
    const { response, loading, error } = removeNoteAPI;
    setNoteToRemove(null);
    if (response && !loading && !error) setCorporateAction(response.data);
  }, [removeNoteAPI.response, removeNoteAPI.loading, removeNoteAPI.error]);

  const removeInternalNoteAPI = useAPI(removeInternalNoteAxios(ca?.uuid, internalNoteToRemove), { immediate: false });

  useEffect(() => {
    if (internalNoteToRemove !== null) removeInternalNoteAPI.fetch();
  }, [internalNoteToRemove]);

  useEffect(() => {
    const { response, loading, error } = removeInternalNoteAPI;
    if (response && !loading && !error) {
      setCorporateAction(response.data);
      setInternalNoteToRemove(null);
    }
  }, [removeInternalNoteAPI.response, removeInternalNoteAPI.loading, removeInternalNoteAPI.error]);

  const renderNotes = (notes, setRemove) => (
    <CardBody>
      <Grid nested>
        {notes.length < 1 && <Col sm={12}>No results</Col>}
        {notes
          .map((note, index) => (
            <Col sm={12}>
              <CardBody nested>
                <Grid nested>
                  <Col sm={11} style={{ whiteSpace: "pre-wrap" }}>
                    {note}
                  </Col>
                  <Col sm={1} style={{ textAlign: "right" }}>
                    <Tooltip title="Delete">
                      <div style={{ display: "inline-block", marginLeft: "1rem" }}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => setRemove(index)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </Tooltip>
                  </Col>
                </Grid>
              </CardBody>
            </Col>
          ))
          .reverse()}
      </Grid>
    </CardBody>
  );

  if (ca?.notes?.length + ca?.internalNotes?.length > 0) {
    return (
      <Col sm={12}>
        <Card>
          <Tabs>
            <Tab name="Notes" id="Notes">
              {renderNotes(ca?.notes, setNoteToRemove)}
            </Tab>
            <Tab name="Internal notes" id="InternalNotes">
              {renderNotes(ca?.internalNotes, setInternalNoteToRemove)}
            </Tab>
          </Tabs>
        </Card>
      </Col>
    );
  }
  return null;
};

export default CorporateActionNotes;
