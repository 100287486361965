import axios from "axios";
import { host } from "./utilities";

export const getEntityAxios = () => (config) =>
  axios.get(`${host()}/cash-active/entity/OKTO_WEALTH_ADMINISTRATION_SERVICES`, config);
export const getLedgerBalanceForEntityAxios = () => (config) =>
  axios.get(`${host()}/cash-active/entity/OKTO_WEALTH_ADMINISTRATION_SERVICES/ledgers/balance`, config);
export const getClientLedgerTransactionsAxios = () => (config) =>
  axios.get(`${host()}/cash-active/entity/OKTO_WEALTH_ADMINISTRATION_SERVICES/client-ledger/transactions`, config);
export const searchLedgerTransactionsAxios = () => (config) =>
  axios.get(`${host()}/cash-active/entity/OKTO_WEALTH_ADMINISTRATION_SERVICES/ledger/search-transactions`, config);
export const getClientLedgerBalanceSummaryAxios = (group) => (config) =>
  axios.get(`${host()}/cash-active/entity/OKTO_WEALTH_ADMINISTRATION_SERVICES/ledger/${group}/CLIENT/summary`, config);
export const listDomesticPaymentsAxios = () => (config) =>
  axios.get(`${host()}/cash-active/entity/OKTO_WEALTH_ADMINISTRATION_SERVICES/domestic-payments`, config);
export const createClientAccountAxios =
  () =>
  (config, data = {}) =>
    axios.post(`${host()}/cash-active/entity/OKTO_WEALTH_ADMINISTRATION_SERVICES/client-account`, data, config);
export const listPayeesAxios = () => (config) =>
  axios.get(`${host()}/cash-active/entity/OKTO_WEALTH_ADMINISTRATION_SERVICES/payees`, config);
export const listPaymentSchedulesAxios = () => (config) =>
  axios.get(`${host()}/cash-active/entity/OKTO_WEALTH_ADMINISTRATION_SERVICES/payment-schedules`, config);
