import { Col, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import clone from "lodash/cloneDeep";
import withNameSpace from "../../../../../functions/withNameSpace";
import { MenuItem } from "@material-ui/core";

const fields = {
  ABN: "abn",
  COUNTRY_OF_ESTABLISHMENT: "placeOfEstablishmentIncorporation",
  COUNTRY_OF_EFFECTIVE_MANAGEMENT: "placeOfEffectiveManagement",
};

const initialValues = {
  [fields.ABN]: "",
  [fields.COUNTRY_OF_ESTABLISHMENT]: "AU",
  [fields.COUNTRY_OF_EFFECTIVE_MANAGEMENT]: "AU",
};

const clean = (values) => {
  let cleanValues = clone(values);
  return cleanValues;
};

const OrganisationForm = ({ nameSpace, countries }) => (
  <>
    <Col sm={12}>
      <Field
        component={TextField}
        label="ABN"
        fullWidth
        mask="9999999999999999999999999999999"
        name={withNameSpace(nameSpace, fields.ABN)}
        required
      />
    </Col>
    <Col sm={12}>
      <Field
        component={TextField}
        label="Country of establishment"
        fullWidth
        name={withNameSpace(nameSpace, fields.COUNTRY_OF_ESTABLISHMENT)}
        required
        select
      >
        {countries.map((country) => (
          <MenuItem value={country.value}>{country.key}</MenuItem>
        ))}
      </Field>
    </Col>
    <Col sm={12}>
      <Field
        component={TextField}
        label="Country of effective management"
        fullWidth
        name={withNameSpace(nameSpace, fields.COUNTRY_OF_EFFECTIVE_MANAGEMENT)}
        required
        select
      >
        {countries.map((country) => (
          <MenuItem value={country.value}>{country.key}</MenuItem>
        ))}
      </Field>
    </Col>
  </>
);

OrganisationForm.fields = fields;
OrganisationForm.initialValues = initialValues;
OrganisationForm.clean = clean;

export default OrganisationForm;
