import { CardBody, InfoBox } from "capitalroadkit";

import useAPI from "../../../../hooks/useAPI";
import { listPayeesAxios } from "../../api/CashActiveEntityResource";
import PayeeStatusEnum from "../../../../types/CashService/Payees/PayeeStatusEnum";
import PayeeTable from "../../components/PayeeTable";
import Loading from "../../../../Loading";

const PayeesTab = () => {
  const listPayeesAPI = useAPI(listPayeesAxios(), { initialParams: { status: PayeeStatusEnum.CREATED } });

  const response = listPayeesAPI.response;
  const loading = listPayeesAPI.loading;
  const error = listPayeesAPI.error;

  if (response) {
    const payees = listPayeesAPI.response.data;

    return <PayeeTable data={payees} refreshData={() => listPayeesAPI.fetch(listPayeesAPI.response.config.params)} />;
  }
  if (loading) {
    return (
      <CardBody>
        <Loading />
      </CardBody>
    );
  }
  if (error) {
    return (
      <CardBody>
        <InfoBox variant={InfoBox.Variant.ERROR} value={error.response?.data} />
      </CardBody>
    );
  }
  return null;
};

export default PayeesTab;
