import { Table, useTheme } from "capitalroadkit";
import React, { useMemo } from "react";

import { parseEnum } from "../../../../resources/formatters/Formatter";
import PayeeTypeEnum from "../../../../types/CashService/Payees/PayeeTypeEnum";
import PayeeTableActions from "./PayeeTableActions";
import { Link } from "react-router-dom";

const PayeeTable = ({ data, hiddenColumns, refreshData }) => {
  const [theme] = useTheme();

  const columns = useMemo(
    () => [
      {
        id: "PayeeTable",
        columns: [
          {
            accessor: "clientLedger.name",
            Header: "Ledger",
            Cell: ({ cell: { value }, row: { original } }) => (
              <Link
                to={"/mse-cash-active/transactions"}
                state={{
                  uuid: original.clientLedger.uuid,
                  subType: original.clientLedger.ledgerType,
                  group: original.clientLedger.group,
                }}
              >
                {value}
              </Link>
            ),
          },
          {
            id: "name",
            Header: "Name",
            Cell: ({ row: { original } }) => (
              <span>
                {original.name}{" "}
                {original.nickname && <span style={{ color: theme.palette.font.muted }}>({original.nickname})</span>}
              </span>
            ),
          },
          {
            accessor: "type",
            Header: "Type",
            Cell: ({ cell: { value } }) => parseEnum(value),
          },
          {
            id: "organisationCode",
            Header: "BSB/Biller code",
            Cell: ({ row: { original } }) => {
              switch (original.type) {
                case PayeeTypeEnum.BPAY_BILLER:
                  return original.billerCode;

                case PayeeTypeEnum.DOMESTIC_PAYEE:
                  return original.bsbNumber;

                default:
                  return null;
              }
            },
          },
          {
            id: "customerNumber",
            Header: "Account number/Reference number",
            Cell: ({ row: { original } }) => {
              switch (original.type) {
                case PayeeTypeEnum.BPAY_BILLER:
                  return original.referenceNumber;

                case PayeeTypeEnum.DOMESTIC_PAYEE:
                  return original.accountNumber;

                default:
                  return null;
              }
            },
          },
          {
            accessor: "status",
            Header: "Status",
            Cell: ({ cell: { value } }) => parseEnum(value),
          },
          {
            id: "actions",
            Cell: ({ row: { original } }) => <PayeeTableActions payee={original} refreshData={refreshData} />,
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
      sortBy={[
        {
          id: "name",
        },
      ]}
    />
  );
};

export default PayeeTable;
