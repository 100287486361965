import { Col, CardBody, Grid, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";

const fields = {
  SECURITY_OLD_NAME: "securityOldName",
  SECURITY_NEW_NAME: "securityNewName",
  OLD_SECURITY_CODE: "oldSecurityCode",
  NEW_SECURITY_CODE: "newSecurityCode",
  ACTION_FLAG: "actionFlag",
  PAR_VALUE_CURRENCY: "parValueCurrency",
};

const initialValues = (ca) => ({
  [fields.SECURITY_OLD_NAME]: ca?.securityOldName || "",
  [fields.SECURITY_NEW_NAME]: ca?.securityNewName || "",
  [fields.OLD_SECURITY_CODE]: ca?.oldSecurityCode || "",
  [fields.NEW_SECURITY_CODE]: ca?.newSecurityCode || "",
  [fields.ACTION_FLAG]: ca?.actionFlag || "",
  [fields.PAR_VALUE_CURRENCY]: ca?.parValueCurrency || "",
});

const clean = (values) => {
  return values;
};

const SecurityNameChangeForm = ({ form, nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Security name change fields</Col>
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Security old name"
                fullWidth
                name={withNameSpace(nameSpace, fields.SECURITY_OLD_NAME)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.securityOldName}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Security new name"
                fullWidth
                name={withNameSpace(nameSpace, fields.SECURITY_NEW_NAME)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.securityNewName}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Old security code"
                fullWidth
                name={withNameSpace(nameSpace, fields.OLD_SECURITY_CODE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.oldSecurityCode}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="New security code"
                fullWidth
                name={withNameSpace(nameSpace, fields.NEW_SECURITY_CODE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.newSecurityCode}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Action flag"
                fullWidth
                name={withNameSpace(nameSpace, fields.ACTION_FLAG)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.actionFlag}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Par value currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAR_VALUE_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.parValueCurrency}
              </Col>
            )}
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

SecurityNameChangeForm.fields = fields;
SecurityNameChangeForm.initialValues = initialValues;
SecurityNameChangeForm.clean = clean;

export default SecurityNameChangeForm;
