import { Col, CardBody, Grid, TextField } from "capitalroadkit";
import React, { useEffect } from "react";
import { Field } from "formik";
import withNameSpace from "../../../../../functions/withNameSpace";
import clone from "lodash/cloneDeep";
import * as Yup from "yup";
import AssetType from "../../../../../types/Assets/AssetType";
import FundForm from "./FundForm";
import DebtSecurityForm from "./DebtSecurityForm";
import AssetForm from "../index";
import Collapse from "@material-ui/core/Collapse";

const fields = {
  LISTING_CODE: "listingCode",
  LISTING_EXCHANGE: "listingExchange",
  ISIN: "isin",
  APIR: "apir",
};

const initialValues = (asset) => {
  const securityInitialValues = {
    [fields.LISTING_CODE]: asset?.listingCode || "",
    [fields.LISTING_EXCHANGE]: asset?.listingExchange || "",
    [fields.ISIN]: asset?.isin || "",
    [fields.APIR]: asset?.apir || "",
  };

  switch (asset?.type) {
    case AssetType.DEBT_SECURITY:
      return { ...securityInitialValues, ...DebtSecurityForm.initialValues(asset) };
    case AssetType.EXCHANGE_TRADED_FUND:
    case AssetType.MANAGED_FUND:
      return { ...securityInitialValues, ...FundForm.initialValues(asset) };

    default:
      return securityInitialValues;
  }
};

const schema = (asset) => {
  const optionalSchema = () => {
    switch (asset?.type) {
      case AssetType.DEBT_SECURITY:
        return DebtSecurityForm.schema.fields;
      case AssetType.EXCHANGE_TRADED_FUND:
      case AssetType.MANAGED_FUND:
        return FundForm.schema.fields;

      default:
        return {};
    }
  };

  return Yup.object({ ...optionalSchema() });
};

const clean = (values) => {
  let cleanValues = clone(values);

  switch (values[AssetForm.fields.TYPE]) {
    case AssetType.DEBT_SECURITY:
      cleanValues = DebtSecurityForm.clean(cleanValues);
      break;
    case AssetType.EXCHANGE_TRADED_FUND:
    case AssetType.MANAGED_FUND:
      cleanValues = FundForm.clean(cleanValues);
      break;

    default:
      break;
  }

  return cleanValues;
};

const SecurityForm = ({ form, nameSpace, asset, listed }) => {
  let type = null;
  if (asset?.type) type = asset?.type;
  else if (form.values?.type) type = form.values[AssetForm.fields.TYPE];

  useEffect(() => {
    switch (type) {
      case AssetType.DEBT_SECURITY:
        form.setValues({ ...initialValues(asset), type, ...DebtSecurityForm.initialValues(asset) });
        break;
      case AssetType.EXCHANGE_TRADED_FUND:
      case AssetType.MANAGED_FUND:
        form.setValues({ ...initialValues(asset), type, ...FundForm.initialValues(asset) });
        break;

      default:
        break;
    }
  }, [type]);

  const renderSecurityForm = () => {
    switch (type) {
      case AssetType.DEBT_SECURITY:
        return <DebtSecurityForm form={form} nameSpace={nameSpace} asset={asset} />;
      case AssetType.EXCHANGE_TRADED_FUND:
      case AssetType.MANAGED_FUND:
        return <FundForm form={form} nameSpace={nameSpace} asset={asset} />;

      default:
        return null;
    }
  };

  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Security fields</Col>
            <Collapse in={listed} style={{ width: "100%" }}>
              <Col sm={12}>
                <Field
                  component={TextField}
                  label="Listing code"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.LISTING_CODE)}
                  readOnly={asset?.listingCode}
                  required={listed}
                />
              </Col>
              <Col sm={12}>
                <Field
                  component={TextField}
                  label="Listing exchange"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.LISTING_EXCHANGE)}
                  readOnly={asset?.listingExchange}
                  required={listed}
                />
              </Col>
            </Collapse>
            <Col sm={12}>
              <Field component={TextField} label="ISIN" fullWidth name={withNameSpace(nameSpace, fields.ISIN)} />
            </Col>
            <Col sm={12}>
              <Field component={TextField} label="APIR" fullWidth name={withNameSpace(nameSpace, fields.APIR)} />
            </Col>
          </Grid>
        </CardBody>
      </Col>
      {renderSecurityForm()}
    </>
  );
};

SecurityForm.fields = fields;
SecurityForm.initialValues = initialValues;
SecurityForm.schema = schema;
SecurityForm.clean = clean;

export default SecurityForm;
