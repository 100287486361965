import { Col, Grid, CardBody, TextField, Checkbox } from "capitalroadkit";
import React, { useEffect } from "react";
import { Field } from "formik";
import clone from "lodash/cloneDeep";
import withNameSpace from "../../../../../functions/withNameSpace";
import BeneficiaryType from "../../../../../types/CashService/Accounts/BeneficiaryType";
import IndividualForm from "./IndividualForm";
import OrganisationForm from "./OrganisationForm";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import AddressForm from "./AddressForm";
import Collapse from "@material-ui/core/Collapse";
import { MenuItem } from "@material-ui/core";
import TaxCountryForm from "./TaxCountryForm";
import { toAPIDateTimeString } from "../../../../../resources/formatters/Formatter";

const fields = {
  TYPE: "type",
  NAME: "name",
  ADDRESS: "primaryAddress",
  EMAIL: "email", //optional
  AUSTRALIAN_TAX_RESIDENT_ONLY: "australianTaxResidentOnly",
  NUMBER_OF_TAX_COUNTRIES: "numberOfTaxCountries",
  TAX_COUNTRIES: "taxCountries",
};

const initialValues = {
  [fields.TYPE]: "",
  [fields.NAME]: "",
  [fields.ADDRESS]: AddressForm.initialValues,
  [fields.EMAIL]: "",
  [fields.AUSTRALIAN_TAX_RESIDENT_ONLY]: false,
  [fields.NUMBER_OF_TAX_COUNTRIES]: 1,
  [fields.TAX_COUNTRIES]: [{ ...TaxCountryForm.initialValues }],
};

const clean = (values) => {
  let cleanValues = clone(values);

  cleanValues.beneficiaries.forEach((beneficiary) => {
    delete beneficiary[fields.NUMBER_OF_TAX_COUNTRIES];

    for (const propName in beneficiary) if (beneficiary[propName] === "") delete beneficiary[propName];

    for (const propName in beneficiary[fields.ADDRESS]) {
      if (beneficiary[fields.ADDRESS][propName] === "") delete beneficiary[fields.ADDRESS][propName];
    }
    beneficiary[fields.ADDRESS].addressLine = AddressForm.getAddressLine(beneficiary[fields.ADDRESS]);
    delete beneficiary[fields.ADDRESS][AddressForm.fields.LINE_1];
    delete beneficiary[fields.ADDRESS][AddressForm.fields.LINE_2];
    delete beneficiary[fields.ADDRESS][AddressForm.fields.LINE_3];

    beneficiary[fields.TAX_COUNTRIES].forEach((taxCountry) => {
      for (const propName in taxCountry) if (taxCountry[propName] === "") delete taxCountry[propName];
    });

    const additionalData = {};
    additionalData[fields.AUSTRALIAN_TAX_RESIDENT_ONLY] = beneficiary[fields.AUSTRALIAN_TAX_RESIDENT_ONLY];
    delete beneficiary[fields.AUSTRALIAN_TAX_RESIDENT_ONLY];

    if (beneficiary[fields.TYPE] === BeneficiaryType.INDIVIDUAL) {
      additionalData[IndividualForm.fields.COUNTRY_OF_BIRTH] = beneficiary[IndividualForm.fields.COUNTRY_OF_BIRTH];
      delete beneficiary[IndividualForm.fields.COUNTRY_OF_BIRTH];
      if (beneficiary[IndividualForm.fields.DATE_OF_BIRTH])
        beneficiary[IndividualForm.fields.DATE_OF_BIRTH] = toAPIDateTimeString(
          beneficiary[IndividualForm.fields.DATE_OF_BIRTH],
          "00:00"
        );
    } else {
      additionalData[OrganisationForm.fields.COUNTRY_OF_EFFECTIVE_MANAGEMENT] =
        beneficiary[OrganisationForm.fields.COUNTRY_OF_EFFECTIVE_MANAGEMENT];
      additionalData[OrganisationForm.fields.COUNTRY_OF_ESTABLISHMENT] =
        beneficiary[OrganisationForm.fields.COUNTRY_OF_ESTABLISHMENT];
      delete beneficiary[OrganisationForm.fields.COUNTRY_OF_EFFECTIVE_MANAGEMENT];
      delete beneficiary[OrganisationForm.fields.COUNTRY_OF_ESTABLISHMENT];
    }

    beneficiary.additionalData = additionalData;
  });
  return cleanValues;
};

const renderBeneficiaryType = (nameSpace, type, countries) => {
  switch (type) {
    case BeneficiaryType.INDIVIDUAL:
      return <IndividualForm nameSpace={nameSpace} countries={countries} />;
    case BeneficiaryType.COMPANY:
    case BeneficiaryType.TRUST:
      return <OrganisationForm nameSpace={nameSpace} countries={countries} />;

    default:
      return null;
  }
};

const BeneficiaryForm = ({ nameSpace, form, index, countries, findCountryForCode }) => {
  useEffect(() => {
    if (form.values.beneficiaries[index][fields.AUSTRALIAN_TAX_RESIDENT_ONLY]) {
      form.values.beneficiaries[index][fields.TAX_COUNTRIES] = [];
    } else {
      const taxCountries = form.values.beneficiaries[index][fields.TAX_COUNTRIES];
      const requiredAmount = form.values.beneficiaries[index][fields.NUMBER_OF_TAX_COUNTRIES];

      if (taxCountries.length < requiredAmount)
        for (let i = taxCountries.length; i < requiredAmount; i++) taxCountries.push(TaxCountryForm.initialValues);
      else if (taxCountries.length > requiredAmount)
        for (let i = taxCountries.length; i > requiredAmount; i--) taxCountries.pop();

      form.values.beneficiaries[index][fields.TAX_COUNTRIES] = taxCountries;
    }
  }, [
    form.values.beneficiaries[index][fields.NUMBER_OF_TAX_COUNTRIES],
    form.values.beneficiaries[index][fields.AUSTRALIAN_TAX_RESIDENT_ONLY],
  ]);

  return (
    <Col sm={12}>
      <CardBody nested>
        <Grid nested>
          <Col sm={12}>Beneficiary {index + 1}</Col>
          <Col sm={12}>
            <Field component={TextField} label="Name" fullWidth name={withNameSpace(nameSpace, fields.NAME)} required />
          </Col>
          <Col sm={12}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" style={{ cursor: "pointer" }} />}
              >
                Address
              </AccordionSummary>
              <AccordionDetails>
                <Grid nested>
                  <AddressForm
                    nameSpace={withNameSpace(nameSpace, fields.ADDRESS)}
                    values={form.values[fields.ADDRESS]}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Col>
          <Col sm={12}>
            <Field
              component={Checkbox}
              label="Australian tax resident only"
              fullWidth
              name={withNameSpace(nameSpace, fields.AUSTRALIAN_TAX_RESIDENT_ONLY)}
            />
          </Col>
          <Col sm={12}>
            <Collapse in={!form.values.beneficiaries[index][fields.AUSTRALIAN_TAX_RESIDENT_ONLY]}>
              <Field
                key={form.values[fields.NUMBER_OF_COUNTRIES]}
                component={TextField}
                label="No. countries with tax residency"
                fullWidth
                name={withNameSpace(nameSpace, fields.NUMBER_OF_TAX_COUNTRIES)}
                required
                select
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Field>
            </Collapse>
          </Col>

          <Col sm={12}>
            {form.values.beneficiaries[index][fields.TAX_COUNTRIES] &&
              form.values.beneficiaries[index][fields.TAX_COUNTRIES].map((taxCountry, index) => (
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" style={{ cursor: "pointer" }} />}
                  >
                    {findCountryForCode(taxCountry[TaxCountryForm.fields.COUNTRY]) || "Tax country " + (index + 1)}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid nested>
                      <TaxCountryForm
                        countries={countries}
                        findCountryForCode={findCountryForCode}
                        form={form}
                        index={index}
                        nameSpace={withNameSpace(nameSpace, `${fields.TAX_COUNTRIES}[${index}]`)}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Col>
          {renderBeneficiaryType(nameSpace, form.values.beneficiaries[index][fields.TYPE], countries)}
          <Col sm={12}>
            <Field component={TextField} label="Email" fullWidth name={withNameSpace(nameSpace, fields.EMAIL)} />
          </Col>
        </Grid>
      </CardBody>
    </Col>
  );
};

BeneficiaryForm.fields = fields;
BeneficiaryForm.initialValues = initialValues;
BeneficiaryForm.clean = clean;

export default BeneficiaryForm;
