import { TextField, Button, useTheme, Checkbox } from "capitalroadkit";
import { Field, useFormikContext } from "formik";

import withNameSpace from "../../../../functions/withNameSpace";
import { Divider, MenuItem } from "@material-ui/core";
import React, { useEffect } from "react";
import { parseEnum, toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import ActivityType from "../../../../types/corporateActions/Activities/ActivityType";
import clone from "lodash/cloneDeep";
import TransactionComponentForm from "./TransactionComponentForm";
import ParcelTreatmentComponentForm from "./ParcelTreatmentComponentForm";
import VolumeCalculatorForm from "./VolumeCalculatorForm";
import * as Yup from "yup";
import NoteForm from "../NoteForm";

const fields = {
  TYPE: "type",
  DESCRIPTION: "description",
  ASSET_CODE: "assetCode",
  PARTICIPATION_CONDITION: "participationCondition",
  NOTES: "notes",
  CASH_DISTRIBUTION: "cashDistribution",
  CGT_EVENT: "cgtEvent", // only for asset activities
  EXPECTED_PAYMENT_DATE: "expectedPaymentDate",

  //subForms
  TRANSACTION_COMPONENT: "transactionComponent",
  VOLUME_CALCULATOR: "volumeCalculator",
  PARCEL_TREATMENT_COMPONENT: "parcelTreatmentComponent",
};

const initialValues = (activity) => ({
  [fields.TYPE]: activity?.type || ActivityType.INCOME_ACTIVITY,
  [fields.DESCRIPTION]: activity?.description || "",
  [fields.ASSET_CODE]: activity?.assetCode || "AUD",
  [fields.PARTICIPATION_CONDITION]: activity?.participationCondition || "",
  [fields.CASH_DISTRIBUTION]: activity?.cashDistribution !== false,
  [fields.CGT_EVENT]: activity?.cgtEvent === null || activity?.cgtEvent === undefined ? false : activity?.cgtEvent,
  [fields.EXPECTED_PAYMENT_DATE]: toDateString(activity?.expectedPaymentDate) || "",
  [fields.TRANSACTION_COMPONENT]: TransactionComponentForm.initialValues(activity),
  [fields.VOLUME_CALCULATOR]: VolumeCalculatorForm.initialValues(activity?.volumeCalculator),
  [fields.PARCEL_TREATMENT_COMPONENT]: ParcelTreatmentComponentForm.initialValues(activity?.parcelTreatmentComponent),
  [fields.NOTES]: activity?.notes || [],
  [NoteForm.fields.NOTE]: "",
});

const clean = (values) => {
  let cleanValues = clone(values);

  switch (values[fields.TYPE]) {
    case ActivityType.ASSET_BUY_ACTIVITY:
      cleanValues = ParcelTreatmentComponentForm.clean(cleanValues);
      delete cleanValues[fields.CASH_DISTRIBUTION];
      delete cleanValues[fields.CGT_EVENT];
      break;
    case ActivityType.ASSET_SELL_ACTIVITY:
      delete cleanValues[fields.PARCEL_TREATMENT_COMPONENT];
      delete cleanValues[fields.CASH_DISTRIBUTION];
      break;
    case ActivityType.CASH_ACTIVITY:
      delete cleanValues[fields.PARCEL_TREATMENT_COMPONENT];
      delete cleanValues[fields.CASH_DISTRIBUTION];
      delete cleanValues[fields.CGT_EVENT];
      break;
    case ActivityType.INCOME_ACTIVITY:
      delete cleanValues[fields.PARCEL_TREATMENT_COMPONENT];
      delete cleanValues[fields.CGT_EVENT];
      if (!cleanValues[fields.CASH_DISTRIBUTION]) delete cleanValues[fields.TRANSACTION_COMPONENT];
      break;
    default:
      break;
  }

  if (cleanValues[fields.EXPECTED_PAYMENT_DATE])
    cleanValues[fields.EXPECTED_PAYMENT_DATE] = toAPIDateString(cleanValues[fields.EXPECTED_PAYMENT_DATE]);
  if (cleanValues[fields.TRANSACTION_COMPONENT]) cleanValues = TransactionComponentForm.clean(cleanValues);
  cleanValues = VolumeCalculatorForm.clean(cleanValues, [fields.VOLUME_CALCULATOR]);
  delete cleanValues[NoteForm.fields.NOTE];

  for (const propName in cleanValues) {
    if (cleanValues[propName] === "") {
      delete cleanValues[propName];
    }
  }
  return cleanValues;
};

const schema = Yup.object({
  [fields.TYPE]: Yup.string().required("Type required"),
  [fields.DESCRIPTION]: Yup.string(),
  [fields.ASSET_CODE]: Yup.string().required("Asset code required"),
  [fields.EXPECTED_PAYMENT_DATE]: Yup.string().when(fields.CASH_DISTRIBUTION, {
    is: false,
    then: Yup.string().required("Expected payment date is required when 'Write cash transaction' is unchecked"),
  }),
  [fields.TRANSACTION_COMPONENT]: Yup.object().when(fields.CASH_DISTRIBUTION, {
    is: (cashDistribution) => cashDistribution,
    then: Yup.object({
      [TransactionComponentForm.fields.TRANSACTION_DATE]: Yup.string().required("Transaction date required"),
      [TransactionComponentForm.fields.SETTLEMENT_DATE]: Yup.string().required("Settlement date required"),
      [TransactionComponentForm.fields.ANNOTATION]: Yup.string().required("Annotation required"),
      [TransactionComponentForm.fields.PRICE]: Yup.string().when(["marketPrice", "type"], {
        is: (marketPrice, type) =>
          (type === ActivityType.ASSET_BUY_ACTIVITY || type === ActivityType.ASSET_SELL_ACTIVITY) &&
          marketPrice === false,
        then: Yup.string().required("Price required"),
      }),
      [TransactionComponentForm.fields.MARKET_PRICE]: Yup.boolean(),
    }),
  }),
  [fields.VOLUME_CALCULATOR]: VolumeCalculatorForm.schema(false),
  [fields.NOTES]: Yup.array(),
  [fields.PARCEL_TREATMENT_COMPONENT]: Yup.object().when("type", {
    is: ActivityType.ASSET_BUY_ACTIVITY,
    then: Yup.object({
      [ParcelTreatmentComponentForm.fields.COST_BASE_MULTIPLIER]: Yup.string().when("transferCostBase", {
        is: true,
        then: Yup.string().required("Cost base multiplier required"),
      }),
    }),
  }),
  [fields.CGT_EVENT]: Yup.boolean().when("type", {
    is: ActivityType.ASSET_SELL_ACTIVITY,
    then: Yup.boolean().required("CGT event flag required"),
  }),
});

const ActivityForm = ({ form, nameSpace, activity, setSchema }) => {
  const { values, setFieldValue } = useFormikContext();
  const [theme] = useTheme();

  useEffect(() => {
    if (!activity) setFieldValue(withNameSpace(nameSpace, fields.CASH_DISTRIBUTION), true);
    if (
      values[fields.TYPE] !== ActivityType.ASSET_BUY_ACTIVITY &&
      values[fields.TYPE] !== ActivityType.ASSET_SELL_ACTIVITY
    )
      setFieldValue(withNameSpace(nameSpace, fields.ASSET_CODE), "AUD");
    else {
      if (!activity) setFieldValue(withNameSpace(nameSpace, fields.ASSET_CODE), "");
    }
  }, [values[fields.TYPE]]);

  const renderActivityForm = () => {
    switch (values[fields.TYPE]) {
      case ActivityType.ASSET_BUY_ACTIVITY:
        return (
          <ParcelTreatmentComponentForm
            form={form}
            nameSpace={withNameSpace(nameSpace, fields.PARCEL_TREATMENT_COMPONENT)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Field
        component={TextField}
        label="Type"
        name={withNameSpace(nameSpace, fields.TYPE)}
        readOnly={activity}
        required
        select
      >
        {Object.entries(ActivityType).map((type) => (
          <MenuItem key={type[0]} value={type[0]}>
            {parseEnum(type[0])}
          </MenuItem>
        ))}
      </Field>
      <Field component={TextField} label="Activity description" name={withNameSpace(nameSpace, fields.DESCRIPTION)} />
      <Field
        component={TextField}
        label="Asset code"
        name={withNameSpace(nameSpace, fields.ASSET_CODE)}
        readOnly={
          values[fields.TYPE] !== ActivityType.ASSET_BUY_ACTIVITY &&
          values[fields.TYPE] !== ActivityType.ASSET_SELL_ACTIVITY
        }
        required
      />
      <Field
        component={TextField}
        label="Participation condition"
        name={withNameSpace(nameSpace, fields.PARTICIPATION_CONDITION)}
        required
      ></Field>
      {values[fields.TYPE] === ActivityType.INCOME_ACTIVITY && (
        <Field
          component={Checkbox}
          label="Write cash transaction"
          name={withNameSpace(nameSpace, fields.CASH_DISTRIBUTION)}
        />
      )}
      {values[fields.TYPE] === ActivityType.ASSET_SELL_ACTIVITY && (
        <Field component={Checkbox} label="is CGT event" name={withNameSpace(nameSpace, fields.CGT_EVENT)} />
      )}
      {!values[fields.CASH_DISTRIBUTION] && (
        <Field
          component={TextField}
          label="Expected payment date"
          mask="99/99/9999"
          name={withNameSpace(nameSpace, fields.EXPECTED_PAYMENT_DATE)}
        />
      )}
      <Divider />
      {values[fields.CASH_DISTRIBUTION] && (
        <>
          <TransactionComponentForm nameSpace={withNameSpace(nameSpace, fields.TRANSACTION_COMPONENT)} />
          <Divider />
        </>
      )}
      <VolumeCalculatorForm
        value={values[fields.VOLUME_CALCULATOR]}
        title={"Volume calculator"}
        nameSpace={withNameSpace(nameSpace, fields.VOLUME_CALCULATOR)}
        setSchema={setSchema}
        isActivityForm
      />
      {renderActivityForm()}
    </>
  );
};

ActivityForm.fields = fields;
ActivityForm.initialValues = initialValues;
ActivityForm.schema = schema;
ActivityForm.clean = clean;
export default ActivityForm;
