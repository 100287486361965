import { useTheme } from "capitalroadkit";
import Tooltip from "@material-ui/core/Tooltip";
import { toDateString } from "../../../../../../resources/formatters/Formatter";
import { payTypeDescription } from "../../../../../../functions/corpActionFieldDescription";

const BuyBackDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Start date</th>
        <td>{toDateString(ca.startDate)}</td>
        <td>{toDateString(secondaryCa?.startDate)}</td>
      </tr>
      <tr>
        <th>End date</th>
        <td>{toDateString(ca.endDate)}</td>
        <td>{toDateString(secondaryCa?.endDate)}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Action flag</th>
        <td>{ca.actionFlag}</td>
        <td>{secondaryCa?.actionFlag}</td>
      </tr>
      <tr>
        <th>Withdrawal rights</th>
        <td>{ca.withdrawalRights}</td>
        <td>{secondaryCa?.withdrawalRights}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>On off flag</th>
        <td>{ca.onOffFlag}</td>
        <td>{secondaryCa?.onOffFlag}</td>
      </tr>
      <tr>
        <th>Payment currency</th>
        <td>{ca.paymentCurrency}</td>
        <td>{secondaryCa?.paymentCurrency}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Minimum price</th>
        <td>{ca.minimumPrice}</td>
        <td>{secondaryCa?.minimumPrice}</td>
      </tr>
      <tr>
        <th>Maximum price</th>
        <td>{ca.maximumPrice}</td>
        <td>{secondaryCa?.maximumPrice}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Priority</th>
        <td>{ca.priority}</td>
        <td>{secondaryCa?.priority}</td>
      </tr>
      <tr>
        <th>Pay type</th>
        <td>
          <Tooltip title={payTypeDescription(ca.payType)} placement="right">
            <div style={{ width: "25px" }}>{ca.payType}</div>
          </Tooltip>
        </td>
        <td>
          <Tooltip title={payTypeDescription(secondaryCa?.payType)} placement="right">
            <div style={{ width: "25px" }}>{secondaryCa?.payType}</div>
          </Tooltip>
        </td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Par value currency</th>
        <td>{ca.parValueCurrency}</td>
        <td>{secondaryCa?.parValueCurrency}</td>
      </tr>
      <tr>
        <th>Security par value</th>
        <td>{ca.securityParValue}</td>
        <td>{secondaryCa?.securityParValue}</td>
      </tr>
    </>
  );
};

export default BuyBackDetails;
