import EnvironmentEnum from "../EnvironmentEnum";

const CognitoConfiguration = Object.freeze({
  [EnvironmentEnum.LOCALHOST]: {
    DOMAIN: "http://localhost:20001/mse-ui/welcome",
    CLIENT_ID: "7ikilrouvcvcp0g3ji838usj9r",
  },
  [EnvironmentEnum.DEV]: {
    DOMAIN: "https://dev-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    CLIENT_ID: "31pkbbi6g9112smoacijp9fl6e",
  },
  [EnvironmentEnum.DEV2]: {
    DOMAIN: "https://dev2-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    CLIENT_ID: "55v1tqscipu28u0e2j0662o2fa",
  },
  [EnvironmentEnum.SIT]: {
    DOMAIN: "https://sit-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    CLIENT_ID: "i07dcnlr9ed11h48d2h98l6s6",
  },
  [EnvironmentEnum.UAT]: {
    DOMAIN: "https://uat-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    CLIENT_ID: "14dao7fddruon7edaijghsgccv",
  },
  [EnvironmentEnum.DEMO2]: {
    DOMAIN: "https://demo2-nonprod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    CLIENT_ID: "2r565bbpelnnqf51amdr65qpmi",
  },
  [EnvironmentEnum.PROD]: {
    DOMAIN: "https://prod-idp-internal-capitalroad.auth.ap-southeast-2.amazoncognito.com",
    CLIENT_ID: "4b2qr4c0n89vkvfu0tv80jdl34",
  },
});

export default CognitoConfiguration;
