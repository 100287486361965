import Collapse from "@material-ui/core/Collapse";
import { MenuItem } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Col, CardBody, Grid, TextField, Checkbox } from "capitalroadkit";
import { Field } from "formik";
import clone from "lodash/cloneDeep";
import get from "lodash/get";
import * as Yup from "yup";

import AssetForm from "../index";

import withNameSpace from "../../../../../functions/withNameSpace";
import { parseEnum, toAPIDateString, toDateString } from "../../../../../resources/formatters/Formatter";
import DebtSecuritySubType from "../../../../../types/Assets/DebtSecuritySubType";
import DistributionFrequencyType from "../../../../../types/Assets/DistributionFrequencyType";
import IssuerType from "../../../../../types/Assets/IssuerType";
import DayCountConventionType from "../../../../../types/Assets/DayCountConventionType";
import ProviderType from "../../../../../types/Assets/ProviderType";

const fields = {
  ISSUER: "issuer",
  ISSUER_TYPE: "issuerType",
  SUB_TYPE: "subType",
  COUPON: "coupon",
  DAY_COUNT_CONVENTION: "dayCountConvention",
  FACE_VALUE: "faceValue",
  PRICE_DIVISOR: "priceDivisor",
  DISTRIBUTION_FREQUENCY: "distributionFrequency",
  DATE_OF_ISSUE: "dateOfIssue",
  FLOATING: "floating",
  MARGIN: "margin",
  BENCHMARK: "benchmark",
  BENCHMARK_RATE: "benchmarkRate",
  YIELD: "yield",
  DATE_OF_MATURITY: "dateOfMaturity",
  DATE_OF_NEXT_DISTRIBUTION: "dateOfNextDistribution",
  DATE_OF_LAST_DISTRIBUTION: "dateOfLastDistribution",
};

const initialValues = (asset) => ({
  [fields.ISSUER]: asset ? asset.issuer : "",
  [fields.ISSUER_TYPE]: asset ? asset.issuerType : "",
  [fields.SUB_TYPE]: asset ? asset.subType : "",
  [fields.COUPON]: asset ? asset.coupon : "",
  [fields.DAY_COUNT_CONVENTION]: asset ? asset.dayCountConvention : "",
  [fields.FACE_VALUE]: asset ? asset.faceValue : "",
  [fields.PRICE_DIVISOR]: asset ? asset.priceDivisor : "",
  [fields.DISTRIBUTION_FREQUENCY]: asset ? asset.distributionFrequency : "",
  [fields.DATE_OF_ISSUE]: asset ? toDateString(asset.dateOfIssue) : "",
  [fields.FLOATING]: asset ? asset.floating : false,
  [fields.MARGIN]: asset ? asset.margin : "",
  [fields.BENCHMARK]: asset ? asset.benchmark : "",
  [fields.BENCHMARK_RATE]: asset ? asset.benchmarkRate : "",
  [fields.YIELD]: asset ? asset.yield : "",
  [fields.DATE_OF_MATURITY]: asset ? toDateString(asset.dateOfMaturity) : "",
  [fields.DATE_OF_NEXT_DISTRIBUTION]: asset ? toDateString(asset.dateOfNextDistribution) : "",
  [fields.DATE_OF_LAST_DISTRIBUTION]: asset ? toDateString(asset.dateOfLastDistribution) : "",
});

const schema = () => Yup.object({});

const clean = (values) => {
  const cleanValues = clone(values);

  if (cleanValues[fields.DATE_OF_ISSUE])
    cleanValues[fields.DATE_OF_ISSUE] = toAPIDateString(cleanValues[fields.DATE_OF_ISSUE]);
  if (cleanValues[fields.DATE_OF_MATURITY])
    cleanValues[fields.DATE_OF_MATURITY] = toAPIDateString(cleanValues[fields.DATE_OF_MATURITY]);
  if (cleanValues[fields.DATE_OF_NEXT_DISTRIBUTION])
    cleanValues[fields.DATE_OF_NEXT_DISTRIBUTION] = toAPIDateString(cleanValues[fields.DATE_OF_NEXT_DISTRIBUTION]);
  if (cleanValues[fields.DATE_OF_LAST_DISTRIBUTION])
    cleanValues[fields.DATE_OF_LAST_DISTRIBUTION] = toAPIDateString(cleanValues[fields.DATE_OF_LAST_DISTRIBUTION]);

  return cleanValues;
};

const DebtSecurityForm = ({ asset, form, nameSpace }) => {
  const canUpdate = (field) => {
    if (asset) return asset[field] === null || asset[AssetForm.fields.PROVIDER] === ProviderType.ADMIN;
    return true;
  };

  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Debt security fields</Col>
            <Col sm={12}>
              <Field
                component={TextField}
                label="Issuer"
                fullWidth
                name={withNameSpace(nameSpace, fields.ISSUER)}
                readOnly={!canUpdate(fields.ISSUER)}
                required
              />
            </Col>
            <Col sm={12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.ISSUER_TYPE)]}
                component={TextField}
                label="Issuer type"
                fullWidth
                name={withNameSpace(nameSpace, fields.ISSUER_TYPE)}
                readOnly={!canUpdate(fields.ISSUER_TYPE)}
                required
                select
              >
                {Object.values(IssuerType).map((type) => (
                  <MenuItem value={type}>{parseEnum(type)}</MenuItem>
                ))}
              </Field>
            </Col>
            <Col sm={12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.SUB_TYPE)]}
                component={TextField}
                label="Sub type"
                fullWidth
                name={withNameSpace(nameSpace, fields.SUB_TYPE)}
                readOnly={!canUpdate(fields.SUB_TYPE)}
                required
                select
              >
                {Object.values(DebtSecuritySubType).map((type) => (
                  <MenuItem value={type}>{parseEnum(type)}</MenuItem>
                ))}
              </Field>
            </Col>
            <Col sm={12}>
              <Tooltip title={dayCountConventionTooltip()} style={{ maxWidth: "none" }} placement="right-start">
                <Field
                  key={form.values[withNameSpace(nameSpace, fields.DAY_COUNT_CONVENTION)]}
                  component={TextField}
                  label="Day count convention"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.DAY_COUNT_CONVENTION)}
                  readOnly={!canUpdate(fields.DAY_COUNT_CONVENTION)}
                  required
                  select
                >
                  {Object.entries(DayCountConventionType).map((type) => (
                    <MenuItem value={type[0]}>{type[1]}</MenuItem>
                  ))}
                </Field>
              </Tooltip>
            </Col>
            <Col sm={12}>
              <Field
                component={TextField}
                label="Face value"
                fullWidth
                name={withNameSpace(nameSpace, fields.FACE_VALUE)}
                readOnly={asset?.faceValue}
                required
              />
            </Col>
            <Col sm={12}>
              <Col sm={12}>
                <Tooltip
                  title={
                    <>
                      The value of the denominator will be used to divide the price.
                      <br />
                      For example: Equities are priced in dollars. The denominator would be 1.
                      <br />
                      The valuation of the equity holding will be Qty x Price / 1.
                      <br />
                      Bonds are priced in $100.00 lots. The denominator would be 100.
                      <br />
                      The valuation of the bond holding will be Qty x Price / 100.
                    </>
                  }
                  style={{ maxWidth: "none" }}
                  placement="right-start"
                >
                  <Field
                    component={TextField}
                    label="Price divisor"
                    fullWidth
                    name={withNameSpace(nameSpace, fields.PRICE_DIVISOR)}
                    readOnly={asset?.priceDivisor}
                    required
                  />
                </Tooltip>
              </Col>
            </Col>
            <Col sm={12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.DISTRIBUTION_FREQUENCY)]}
                component={TextField}
                label="Distribution frequency"
                fullWidth
                name={withNameSpace(nameSpace, fields.DISTRIBUTION_FREQUENCY)}
                readOnly={!canUpdate(fields.DISTRIBUTION_FREQUENCY)}
                required
                select
              >
                {Object.values(DistributionFrequencyType).map((type) => (
                  <MenuItem value={type}>{parseEnum(type)}</MenuItem>
                ))}
              </Field>
            </Col>
            <Col sm={12}>
              <Field
                component={TextField}
                label="Date of issue"
                mask="99/99/9999"
                fullWidth
                name={withNameSpace(nameSpace, fields.DATE_OF_ISSUE)}
                readOnly={asset?.dateOfIssue}
                required
              />
            </Col>
            <Col sm={12}>
              <Field component={Checkbox} label="Floating" fullWidth name={withNameSpace(nameSpace, fields.FLOATING)} />
            </Col>
            <Collapse style={{ width: "100%" }} in={!get(form.values, withNameSpace(nameSpace, fields.FLOATING))}>
              <Col sm={12}>
                <Field
                  component={TextField}
                  endAdornment={"%"}
                  label="Coupon (%)"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.COUPON)}
                  readOnly={!canUpdate(fields.COUPON)}
                  required
                />
              </Col>
            </Collapse>
            <Collapse style={{ width: "100%" }} in={get(form.values, withNameSpace(nameSpace, fields.FLOATING))}>
              <Col sm={12}>
                <Field
                  component={TextField}
                  endAdornment="%"
                  label="Margin (%)"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.MARGIN)}
                  readOnly={!canUpdate(fields.MARGIN)}
                  required={form.values[withNameSpace(nameSpace, fields.FLOATING)] === true}
                />
              </Col>
              <Col sm={12}>
                <Field
                  component={TextField}
                  label="Benchmark"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.BENCHMARK)}
                  readOnly={!canUpdate(fields.BENCHMARK)}
                  required={form.values[withNameSpace(nameSpace, fields.FLOATING)] === true}
                />
              </Col>
              <Col sm={12}>
                <Field
                  component={TextField}
                  endAdornment="%"
                  label="Benchmark rate (%)"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.BENCHMARK_RATE)}
                  readOnly={!canUpdate(fields.BENCHMARK_RATE)}
                  required={form.values[withNameSpace(nameSpace, fields.BENCHMARK)] === true}
                />
              </Col>
            </Collapse>
            <Col sm={12}>
              <Field
                component={TextField}
                endAdornment="%"
                label="Yield (%)"
                fullWidth
                name={withNameSpace(nameSpace, fields.YIELD)}
                readOnly={asset?.yield}
              />
            </Col>
            <Col sm={12}>
              <Field
                component={TextField}
                label="Date of maturity"
                mask="99/99/9999"
                fullWidth
                name={withNameSpace(nameSpace, fields.DATE_OF_MATURITY)}
                readOnly={!canUpdate(fields.DATE_OF_MATURITY)}
              />
            </Col>
            <Col sm={12}>
              <Field
                component={TextField}
                label="Date of next distribution"
                mask="99/99/9999"
                fullWidth
                name={withNameSpace(nameSpace, fields.DATE_OF_NEXT_DISTRIBUTION)}
                readOnly={!canUpdate(fields.DATE_OF_NEXT_DISTRIBUTION)}
              />
            </Col>
            <Col sm={12}>
              <Field
                component={TextField}
                label="Date of last distribution"
                mask="99/99/9999"
                fullWidth
                name={withNameSpace(nameSpace, fields.DATE_OF_LAST_DISTRIBUTION)}
                readOnly={!canUpdate(fields.DATE_OF_LAST_DISTRIBUTION)}
              />
            </Col>
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

export const dayCountConventionTooltip = () => (
  <ul style={{ marginLeft: "10px" }}>
    <li>
      <strong>30/360</strong> - calculates the daily interest using a 360-day year and then multiplies that by 30
      (standardized month).
    </li>
    <li>
      <strong>30/365</strong> - calculates the daily interest using a 365-day year and then multiplies that by 30
      (standardized month).
    </li>
    <li>
      <strong>Actual/360</strong> - calculates the daily interest using a 360-day year and then multiplies that by the
      actual number of days in each time period.
    </li>
    <li>
      <strong>Actual/365</strong> - calculates the daily interest using a 365-day year and then multiplies that by the
      actual number of days in each time period.
    </li>
    <li>
      <strong>Actual/actual</strong> - calculates the daily interest using the actual number of days in the year and
      then multiplies that by the actual number of days in each time period.
    </li>
  </ul>
);

DebtSecurityForm.fields = fields;
DebtSecurityForm.initialValues = initialValues;
DebtSecurityForm.schema = schema;
DebtSecurityForm.clean = clean;

export default DebtSecurityForm;
