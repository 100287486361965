import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import {
  Button,
  Card,
  CardBody,
  Col,
  Grid,
  Modal,
  CardHeader,
  CardTitle,
  IconButton,
  CardActions,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";

import UpdateTransactionForm, { UpdateTransactionFormInitialValues } from "../../forms/UpdateTransactionForm";
import useAPI from "../../../../hooks/useAPI";
import { updateCliTransactionAxios } from "../../api/TransactionResource";

const UpdateTransactionModal = ({ listLedgerTransactionsAPI, transaction, setTransaction }) => {
  const updateCliTransactionAPI = useAPI(updateCliTransactionAxios(transaction?.uuid), {
    immediate: false,
  });

  useEffect(() => {
    if (updateCliTransactionAPI.response && !updateCliTransactionAPI.loading) {
      setTransaction(null);
      listLedgerTransactionsAPI.fetch();
    }
  }, [updateCliTransactionAPI.response, updateCliTransactionAPI.loading]);

  const updateCliTransactionForm = useFormik({
    initialValues: UpdateTransactionFormInitialValues,
    onSubmit: (values) => updateCliTransactionAPI.fetch({}, { type: "CLI_LEDGER_TRANSACTION", ...values }),
  });

  useEffect(() => {
    if (transaction) {
      updateCliTransactionForm.setFieldValue("transactionChannel", transaction.transactionChannel);
      updateCliTransactionForm.setFieldValue("naturalAccountSource", transaction.naturalAccountSource);
    }
  }, [transaction]);

  return (
    <Modal open={transaction}>
      {transaction && (
        <Card>
          <CardHeader>
            <CardTitle>Edit transaction</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setTransaction(null)} />
            </CardActions>
          </CardHeader>
          <CardBody>
            <FormikProvider value={updateCliTransactionForm}>
              <Form>
                <Grid nested>
                  <UpdateTransactionForm form={updateCliTransactionForm} original={transaction} />
                  <Col sm={12} style={{ textAlign: "right" }}>
                    <Button color="primary" loading={updateCliTransactionAPI.loading} type="submit">
                      Save
                    </Button>
                  </Col>
                </Grid>
              </Form>
            </FormikProvider>
          </CardBody>
        </Card>
      )}
    </Modal>
  );
};
export default UpdateTransactionModal;
