import { CardBody, Checkbox, Col, Grid, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import clone from "lodash/cloneDeep";
import { toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import ReinvestmentPlanForm from "./subComponents/ReInvestmentPlan";
import * as Yup from "yup";

const fields = {
  DIVIDEND_TYPE: "dividendType",
  DECLARATION_DATE: "declarationDate",
  RATIO_OLD: "ratioOld",
  RATIO_NEW: "ratioNew",
  FINANCIAL_YEAR_ENDING_DATE: "financialYearEndingDate",
  PERIOD_ENDING_DATE: "periodEndingDate",
  BASE_CURRENCY: "baseCurrency",
  AMOUNT_PER_UNIT_IN_BASE_CURRENCY: "amountPerUnitInBaseCurrency",
  PAYMENT_CURRENCY: "paymentCurrency",
  AMOUNT_PER_UNIT_IN_PAYMENT_CURRENCY: "amountPerUnitInPaymentCurrency",
  FRANKED_AMOUNT_PER_UNIT_IN_PAYMENT_CURRENCY: "frankedAmountPerUnitInPaymentCurrency",
  ESTIMATE: "estimate",
  NIL_PAYMENT: "nilPayment",
  TAX_RATE: "taxRate",
  DRP_REINVESTMENT_PRICE: "drpReinvestmentPrice",
  DRP_PAYMENT_DATE: "drpPaymentDate",
  DRP_LAST_DATE: "drpLastDate",
  OPTION_ELECTION_DATE: "optionElectionDate",

  //additional information
  COMPANY_TAX_RATE: "companyTaxRate",
  DIVIDEND_TAX_COMPONENT: "dividendTaxComponent",
  RE_INVESTMENT_PLAN: "reInvestmentPlan",
};

const initialValues = (ca) => ({
  [fields.DIVIDEND_TYPE]: ca?.dividendType || "",
  [fields.DECLARATION_DATE]: toDateString(ca?.declarationDate) || "",
  [fields.RATIO_OLD]: ca?.ratioOld || "",
  [fields.RATIO_NEW]: ca?.ratioNew || "",
  [fields.FINANCIAL_YEAR_ENDING_DATE]: toDateString(ca?.financialYearEndingDate) || "",
  [fields.PERIOD_ENDING_DATE]: toDateString(ca?.periodEndingDate) || "",
  [fields.BASE_CURRENCY]: ca?.baseCurrency || "AUD",
  [fields.AMOUNT_PER_UNIT_IN_BASE_CURRENCY]: ca?.amountPerUnitInBaseCurrency || "",
  [fields.PAYMENT_CURRENCY]: ca?.paymentCurrency || "AUD",
  [fields.AMOUNT_PER_UNIT_IN_PAYMENT_CURRENCY]: ca?.amountPerUnitInPaymentCurrency || "",
  [fields.FRANKED_AMOUNT_PER_UNIT_IN_PAYMENT_CURRENCY]: ca?.frankedAmountPerUnitInPaymentCurrency || "",
  [fields.ESTIMATE]: ca?.estimate || false,
  [fields.NIL_PAYMENT]: ca?.nilPayment || false,
  [fields.TAX_RATE]: ca?.taxRate || "",
  [fields.DRP_REINVESTMENT_PRICE]: ca?.drpReinvestmentPrice || "",
  [fields.DRP_PAYMENT_DATE]: toDateString(ca?.drpPaymentDate) || "",
  [fields.DRP_LAST_DATE]: toDateString(ca?.drpLastDate) || "",
  [fields.OPTION_ELECTION_DATE]: toDateString(ca?.optionElectionDate) || "",
  [fields.COMPANY_TAX_RATE]: ca?.companyTaxRate || "",
  [fields.RE_INVESTMENT_PLAN]: ReinvestmentPlanForm.initialValues(ca),
});

const schema = Yup.object({});

const clean = (values) => {
  let cleanValues = clone(values);

  if (cleanValues[fields.DECLARATION_DATE])
    cleanValues[fields.DECLARATION_DATE] = toAPIDateString(cleanValues[fields.DECLARATION_DATE]);
  if (cleanValues[fields.FINANCIAL_YEAR_ENDING_DATE])
    cleanValues[fields.FINANCIAL_YEAR_ENDING_DATE] = toAPIDateString(cleanValues[fields.FINANCIAL_YEAR_ENDING_DATE]);
  if (cleanValues[fields.PERIOD_ENDING_DATE])
    cleanValues[fields.PERIOD_ENDING_DATE] = toAPIDateString(cleanValues[fields.PERIOD_ENDING_DATE]);
  if (cleanValues[fields.DRP_PAYMENT_DATE])
    cleanValues[fields.DRP_PAYMENT_DATE] = toAPIDateString(cleanValues[fields.DRP_PAYMENT_DATE]);
  if (cleanValues[fields.DRP_LAST_DATE])
    cleanValues[fields.DRP_LAST_DATE] = toAPIDateString(cleanValues[fields.DRP_LAST_DATE]);
  if (cleanValues[fields.OPTION_ELECTION_DATE])
    cleanValues[fields.OPTION_ELECTION_DATE] = toAPIDateString(cleanValues[fields.OPTION_ELECTION_DATE]);

  return ReinvestmentPlanForm.clean(cleanValues);
};

const DividendForm = ({ form, nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Dividend fields</Col>
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Dividend type"
                fullWidth
                name={withNameSpace(nameSpace, fields.DIVIDEND_TYPE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.dividendType}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Declaration date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.DECLARATION_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.declarationDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Financial year ending date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.FINANCIAL_YEAR_ENDING_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.financialYearEndingDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Ratio old"
                fullWidth
                name={withNameSpace(nameSpace, fields.RATIO_OLD)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.ratioOld}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Ratio new"
                fullWidth
                name={withNameSpace(nameSpace, fields.RATIO_NEW)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.ratioNew}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Period ending date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.PERIOD_ENDING_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.periodEndingDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Base currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.BASE_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.baseCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Amount per unit in base currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.AMOUNT_PER_UNIT_IN_BASE_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.amountPerUnitInBaseCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Payment currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAYMENT_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.paymentCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Amount per unit in payment currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.AMOUNT_PER_UNIT_IN_PAYMENT_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.amountPerUnitInPaymentCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Franked amount per unit in payment currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.FRANKED_AMOUNT_PER_UNIT_IN_PAYMENT_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.frankedAmountPerUnitInPaymentCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Tax rate"
                fullWidth
                name={withNameSpace(nameSpace, fields.TAX_RATE)}
                readOnly={updateAnnouncement}
                placeholder="0-100%"
                endAdornment="%"
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.taxRate}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={Checkbox}
                label="Estimate"
                fullWidth
                name={withNameSpace(nameSpace, fields.ESTIMATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.estimate}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={Checkbox}
                label="NIL payment"
                fullWidth
                name={withNameSpace(nameSpace, fields.NIL_PAYMENT)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.nilPayment}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="DRP reinvestment price"
                fullWidth
                name={withNameSpace(nameSpace, fields.DRP_REINVESTMENT_PRICE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.drpReinvestmentPrice}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="DRP payment date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.DRP_PAYMENT_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.drpPaymentDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="DRP last date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.DRP_LAST_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.drpLastDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Option election date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.OPTION_ELECTION_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.optionElectionDate)}
              </Col>
            )}
          </Grid>
        </CardBody>
      </Col>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Additional information fields</Col>
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Company tax rate"
                fullWidth
                name={withNameSpace(nameSpace, fields.COMPANY_TAX_RATE)}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.companyTaxRate}
              </Col>
            )}
            <ReinvestmentPlanForm
              form={form}
              name={withNameSpace(nameSpace, fields.RE_INVESTMENT_PLAN)}
              nameSpace={withNameSpace(nameSpace, fields.RE_INVESTMENT_PLAN)}
            />
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

DividendForm.fields = fields;
DividendForm.initialValues = initialValues;
DividendForm.schema = schema;
DividendForm.clean = clean;

export default DividendForm;
