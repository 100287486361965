import { Col, Grid, CardBody, TextField } from "capitalroadkit";
import React, { useEffect } from "react";
import { FastField, Field } from "formik";
import clone from "lodash/cloneDeep";
import withNameSpace from "../../../../functions/withNameSpace";
import { MenuItem } from "@material-ui/core";
import { parseEnum } from "../../../../resources/formatters/Formatter";
import AccountType from "../../../../types/CashService/Accounts/AccountType";
import BeneficiaryForm from "./BeneficiaryForms/BeneficiaryForm";
import BeneficiaryType from "../../../../types/CashService/Accounts/BeneficiaryType";
import Collapse from "@material-ui/core/Collapse";
import OrganisationForm from "./BeneficiaryForms/OrganisationForm";
import IndividualForm from "./BeneficiaryForms/IndividualForm";

const fields = {
  CLIENT_ID: "clientId",
  NAME: "name",
  COUNTRY: "country",
  ACCOUNT_TYPE: "accountType",
  NUMBER_OF_BENEFICIARIES: "numberOfBeneficiaries",
  BENEFICIARIES: "beneficiaries",
};

const initialValues = {
  [fields.CLIENT_ID]: "",
  [fields.NAME]: "",
  [fields.COUNTRY]: "AU",
  [fields.ACCOUNT_TYPE]: "",
  [fields.NUMBER_OF_BENEFICIARIES]: 2,
  [fields.BENEFICIARIES]: [],
};

const clean = (values) => {
  let cleanValues = clone(values);
  delete cleanValues[fields.ACCOUNT_TYPE];
  delete cleanValues[fields.NUMBER_OF_BENEFICIARIES];

  cleanValues = BeneficiaryForm.clean(cleanValues);

  return cleanValues;
};

const createBeneficiary = (beneficiaries, type) => {
  if (beneficiaries.length > 0 && beneficiaries[0].type === type) return beneficiaries[0];

  let beneficiary = { ...BeneficiaryForm.initialValues, type };

  if (type === BeneficiaryType.COMPANY || type === BeneficiaryType.TRUST)
    beneficiary = { ...beneficiary, ...OrganisationForm.initialValues };
  else beneficiary = { ...beneficiary, ...IndividualForm.initialValues };

  return beneficiary;
};

const AccountForm = ({ nameSpace, form, countries, findCountryForCode }) => {
  useEffect(() => {
    if (form.values[fields.ACCOUNT_TYPE]) {
      const beneficiaries = form.values[fields.BENEFICIARIES];

      switch (form.values[fields.ACCOUNT_TYPE]) {
        case AccountType.INDIVIDUAL:
          form.values[fields.BENEFICIARIES] = [createBeneficiary(beneficiaries, BeneficiaryType.INDIVIDUAL)];
          break;
        case AccountType.JOINT:
          const requiredAmount = form.values[fields.NUMBER_OF_BENEFICIARIES];

          if (beneficiaries.length !== 0)
            beneficiaries[0] = createBeneficiary(beneficiaries, BeneficiaryType.INDIVIDUAL);

          if (beneficiaries.length < requiredAmount)
            for (let i = beneficiaries.length; i < requiredAmount; i++)
              beneficiaries.push({
                ...BeneficiaryForm.initialValues,
                ...IndividualForm.initialValues,
                type: BeneficiaryType.INDIVIDUAL,
              });
          else if (beneficiaries.length > requiredAmount)
            for (let i = beneficiaries.length; i > requiredAmount; i--) beneficiaries.pop();

          form.values[fields.BENEFICIARIES] = beneficiaries;
          break;
        case AccountType.COMPANY:
          form.values[fields.BENEFICIARIES] = [createBeneficiary(beneficiaries, BeneficiaryType.COMPANY)];
          break;
        case AccountType.TRUST:
          form.values[fields.BENEFICIARIES] = [createBeneficiary(beneficiaries, BeneficiaryType.TRUST)];
          break;

        default:
          break;
      }
    }
  }, [form.values[fields.ACCOUNT_TYPE], form.values[fields.NUMBER_OF_BENEFICIARIES]]);

  return (
    <Grid nested>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Account fields</Col>
            <Col sm={12}>
              <FastField
                component={TextField}
                label="Client ID"
                fullWidth
                name={withNameSpace(nameSpace, fields.CLIENT_ID)}
                required
              />
            </Col>
            <Col sm={12}>
              <FastField
                component={TextField}
                label="Name"
                fullWidth
                name={withNameSpace(nameSpace, fields.NAME)}
                required
              />
            </Col>
            <Col sm={12}>
              <FastField
                component={TextField}
                label="Country"
                fullWidth
                name={withNameSpace(nameSpace, fields.COUNTRY)}
                required
                select
              >
                {countries.map((country) => (
                  <MenuItem value={country.value}>{country.key}</MenuItem>
                ))}
              </FastField>
            </Col>
            <Col sm={12}>
              <FastField
                component={TextField}
                label="Account type"
                fullWidth
                name={withNameSpace(nameSpace, fields.ACCOUNT_TYPE)}
                required
                select
              >
                {Object.values(AccountType).map((type) => (
                  <MenuItem value={type}>{parseEnum(type)}</MenuItem>
                ))}
              </FastField>
            </Col>
            <Col sm={12}>
              <Collapse in={form.values[fields.ACCOUNT_TYPE] === AccountType.JOINT}>
                <Field
                  component={TextField}
                  label="No. beneficiaries"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.NUMBER_OF_BENEFICIARIES)}
                  required
                  select
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Field>
              </Collapse>
            </Col>
          </Grid>
        </CardBody>
      </Col>
      {form.values[fields.BENEFICIARIES].map((beneficiary, index) => (
        <BeneficiaryForm
          countries={countries}
          findCountryForCode={findCountryForCode}
          form={form}
          index={index}
          nameSpace={`${fields.BENEFICIARIES}[${index}]`}
        />
      ))}
    </Grid>
  );
};

AccountForm.fields = fields;
AccountForm.initialValues = initialValues;
AccountForm.clean = clean;

export default AccountForm;
