export const host = () => {
  if (window.location.href.includes("localhost")) return "http://localhost:9000/mse-asset";
  if (window.location.href.includes("nonprod")) {
    if (window.location.href.includes("dev2")) {
      return "https://services-dev2.nonprod.capitalroadonline.com.au/mse-asset";
    }

    if (window.location.href.includes("dev")) {
      return "https://services-dev.nonprod.capitalroadonline.com.au/mse-asset";
    }

    if (window.location.href.includes("sit2")) {
      return "https://services-sit2.nonprod.capitalroadonline.com.au/mse-asset";
    }

    if (window.location.href.includes("sit")) {
      return "https://services-sit.nonprod.capitalroadonline.com.au/mse-asset";
    }

    if (window.location.href.includes("demo2")) {
      return "https://services-demo2.nonprod.capitalroadonline.com.au/mse-asset";
    }

    if (window.location.href.includes("demo")) {
      return "https://services-demo.nonprod.capitalroadonline.com.au/mse-asset";
    }

    if (window.location.href.includes("uat2")) {
      return "https://services-uat2.nonprod.capitalroadonline.com.au/mse-asset";
    }

    if (window.location.href.includes("uat")) {
      return "https://services-uat.nonprod.capitalroadonline.com.au/mse-asset";
    }
  }
  return "https://services-prod.prod.capitalroadonline.com.au/mse-asset";
};
