import axios from "axios";
import { host } from "./utilities";

export const listCorporateActionsAxios = () => (config) => axios.get(`${host()}/corporate-action/list`, config);
export const getCorporateActionAxios = (uuid) => (config) => axios.get(`${host()}/corporate-action/${uuid}`, config);
export const findOverrideForCorporateActionWithUuidAxios = (uuid) => (config) =>
  axios.get(`${host()}/corporate-action/${uuid}/override`, config);
export const findOverriddenForCorporateActionWithUuidAxios = (uuid) => (config) =>
  axios.get(`${host()}/corporate-action/${uuid}/overridden`, config);
export const createCorporateActionAxios =
  () =>
  (config, data = {}) =>
    axios.post(`${host()}/corporate-action`, data, config);
export const updateCorporateActionAxios =
  (uuid) =>
  (config, data = {}) =>
    axios.put(`${host()}/corporate-action/${uuid}`, data, config);
export const addTagAxios =
  (uuid, tag) =>
  (config, data = {}) =>
    axios.post(`${host()}/corporate-action/${uuid}/tags/${tag}`, data, config);
export const removeTagAxios = (uuid, tag) => (config) =>
  axios.delete(`${host()}/corporate-action/${uuid}/tags/${tag}`, config);
export const addNoteAxios =
  (uuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/corporate-action/${uuid}/notes`, data, config);
export const removeNoteAxios = (uuid, note) => (config) =>
  axios.delete(`${host()}/corporate-action/${uuid}/notes/${note}`, config);
export const addInternalNoteAxios =
  (uuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/corporate-action/${uuid}/notes/internal`, data, config);
export const removeInternalNoteAxios = (uuid, note) => (config) =>
  axios.delete(`${host()}/corporate-action/${uuid}/notes/internal/${note}`, config);
export const calculateEntitlementsAxios =
  (uuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/corporate-action/${uuid}/calculate-entitlements-bulk`, data, config);
export const getCorporateActionActivitiesAxios = (caUuid) => (config) =>
  axios.get(`${host()}/corporate-action/${caUuid}/activities`, config);
export const createCorporateActionActivityAxios =
  (caUuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/corporate-action/${caUuid}/activity`, data, config);
