import { TextField } from "capitalroadkit";
import { Field } from "formik";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import clone from "lodash/cloneDeep";

const fields = {
  FILTER: "filter",
  GROUP: "group",
  TYPE: "type",
};

const initialValues = (group) => ({
  [fields.FILTER]: "",
  [fields.GROUP]: group || "",
  [fields.TYPE]: "CLIENT",
});

const clean = (values) => {
  let cleanValues = clone(values);
  for (const propName in cleanValues) {
    if (cleanValues[propName] === "") {
      delete cleanValues[propName];
    }
  }

  return cleanValues;
};

const ClientLedgerSearchForm = () => (
  <>
    <Field component={TextField} label="Filter" name={fields.FILTER} />
    <Field component={TextField} label="Group" name={fields.GROUP} select>
      <MenuItem value="">All</MenuItem>
      <MenuItem value="RETAIL">Retail</MenuItem>
      <MenuItem value="NON_RETAIL">Non-retail</MenuItem>
    </Field>
  </>
);

ClientLedgerSearchForm.fields = fields;
ClientLedgerSearchForm.clean = clean;
ClientLedgerSearchForm.initialValues = initialValues;

export default ClientLedgerSearchForm;
