import { Collapse, Tooltip } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { Col, CardBody, Grid, TextField } from "capitalroadkit";
import { Field } from "formik";
import clone from "lodash/cloneDeep";
import moment from "moment";
import * as Yup from "yup";

import withNameSpace from "../../../../functions/withNameSpace";
import { toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import CouponRateType from "../../../../types/corporateActions/CouponRateType";
import FrequencyType from "../../../../types/corporateActions/FrequencyType";

const fields = {
  COUPON_DATE: "couponDate",
  MATURITY_DATE: "maturityDate",
  COUPON_RATE_TYPE: "couponRateType",
  COUPON_RATE: "couponRate",
  PAYMENT_CURRENCY: "paymentCurrency",
  COUPON_FREQUENCY: "couponFrequency",
  COUPON_PERIOD: "couponPeriod",
  COUPON_YEAR: "couponYear",
};

const initialValues = (ca) => ({
  [fields.COUPON_DATE]: toDateString(ca?.couponDate) || "",
  [fields.MATURITY_DATE]: toDateString(ca?.maturityDate) || "",
  [fields.COUPON_RATE_TYPE]: ca?.couponRateType || "",
  [fields.COUPON_RATE]: ca?.couponRate || "",
  [fields.PAYMENT_CURRENCY]: ca?.paymentCurrency || "AUD",
  [fields.COUPON_FREQUENCY]: ca?.couponFrequency || "",
  [fields.COUPON_PERIOD]: ca?.couponPeriod || "",
  [fields.COUPON_YEAR]: ca?.couponYear || moment().format("yyyy"),
});

const clean = (values) => {
  const clean = clone(values);

  if (clean[fields.COUPON_DATE]) clean[fields.COUPON_DATE] = toAPIDateString(clean[fields.COUPON_DATE]);
  if (clean[fields.MATURITY_DATE]) clean[fields.MATURITY_DATE] = toAPIDateString(clean[fields.MATURITY_DATE]);
  //remove freq period year if rate type is amount
  if (clean[fields.COUPON_RATE_TYPE] === CouponRateType.AMOUNT) {
    delete clean[fields.COUPON_FREQUENCY];
    delete clean[fields.COUPON_PERIOD];
    delete clean[fields.COUPON_YEAR];
  }
  return clean;
};

const schema = Yup.object({});

const InterestForm = ({ form, nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Interest fields</Col>

            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Coupon date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.COUPON_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.couponDate)}
              </Col>
            )}

            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Maturity date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.MATURITY_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.maturityDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 4 : 6}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.COUPON_RATE_TYPE)]}
                component={TextField}
                label="Coupon rate type"
                fullWidth
                name={withNameSpace(nameSpace, fields.COUPON_RATE_TYPE)}
                select
              >
                {Object.values(CouponRateType).map((type) => (
                  <MenuItem value={type}>{type}</MenuItem>
                ))}
              </Field>
            </Col>
            <Col sm={overriddenCa ? 4 : 6}>
              <Tooltip
                title={
                  form.values[withNameSpace(nameSpace, fields.COUPON_RATE_TYPE)] === CouponRateType.PERCENTAGE
                    ? "Percentage expressed as decimals. eg: 7% = 0.07"
                    : ""
                }
                style={{ maxWidth: "none" }}
                placement="right-start"
              >
                <Field
                  component={TextField}
                  label="Coupon rate"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.COUPON_RATE)}
                  readOnly={updateAnnouncement}
                />
              </Tooltip>
            </Col>
            {overriddenCa && (
              <Col sm={2} style={{ marginTop: "35px" }}>
                {overriddenCa.couponRateType}
              </Col>
            )}
            {overriddenCa && (
              <Col sm={2} style={{ marginTop: "35px" }}>
                {overriddenCa.couponRate}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Payment currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAYMENT_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.paymentCurrency}
              </Col>
            )}
            <Collapse
              in={form.values[withNameSpace(nameSpace, fields.COUPON_RATE_TYPE)] !== CouponRateType.AMOUNT}
              style={{ width: "100%" }}
            >
              <Col sm={overriddenCa ? 8 : 12}>
                <Field
                  key={form.values[withNameSpace(nameSpace, fields.COUPON_FREQUENCY)]}
                  component={TextField}
                  label="Coupon frequency"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.COUPON_FREQUENCY)}
                  select
                >
                  {Object.values(FrequencyType).map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </Field>
              </Col>
              {overriddenCa && (
                <Col sm={4} style={{ marginTop: "35px" }}>
                  {overriddenCa.couponFrequency}
                </Col>
              )}
              <Col sm={overriddenCa ? 8 : 12}>
                <Field
                  component={TextField}
                  label="Coupon period"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.COUPON_PERIOD)}
                  readOnly={updateAnnouncement}
                />
              </Col>
              {overriddenCa && (
                <Col sm={4} style={{ marginTop: "35px" }}>
                  {overriddenCa.couponPeriod}
                </Col>
              )}
              <Col sm={overriddenCa ? 8 : 12}>
                <Field
                  component={TextField}
                  label="Coupon year"
                  fullWidth
                  mask="9999"
                  name={withNameSpace(nameSpace, fields.COUPON_YEAR)}
                  readOnly={updateAnnouncement}
                />
              </Col>
              {overriddenCa && (
                <Col sm={4} style={{ marginTop: "35px" }}>
                  {overriddenCa.couponYear}
                </Col>
              )}
            </Collapse>
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

InterestForm.fields = fields;
InterestForm.initialValues = initialValues;
InterestForm.schema = schema;
InterestForm.clean = clean;

export default InterestForm;
