import {
  Button,
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
  IconButton,
  InfoBox,
  Modal,
  Grid,
  Col,
} from "capitalroadkit";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import React, { cloneElement, useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { createActivityIncomeComponentAxios } from "../../../../../api/CorporateActionActivitiyResource";
import IncomeComponentForm from "../../../../../forms/IncomeComponentForm";
import ActivityComponentsTable from "../../../../../components/ActivityComponentsTable";
import useAPI from "../../../../../../../hooks/useAPI";
import { deleteIncomeComponentAxios } from "../../../../../api/IncomeComponentResource";
import ActivityType from "../../../../../../../types/corporateActions/Activities/ActivityType";

const CreateIncomeComponentModal = ({ button, caActivity, refreshAPI }) => {
  const [open, setOpen] = useState(false);
  const [schema, setSchema] = useState(IncomeComponentForm.schema);
  const [activityComponents, setActivityComponents] = useState(caActivity.activityComponents);
  const [componentToDelete, setComponentToDelete] = useState(null);

  const createIncomeComponentForm = useFormik({
    initialValues: IncomeComponentForm.initialValues(),
    onSubmit: (values) => createIncomeComponentAPI.fetch({}, IncomeComponentForm.clean(values)),
    validationSchema: schema,
  });

  const createIncomeComponentAPI = useAPI(createActivityIncomeComponentAxios(caActivity.uuid), { immediate: false });

  useEffect(() => {
    const { response, loading } = createIncomeComponentAPI;

    if (response && !loading) {
      setActivityComponents([...activityComponents, createIncomeComponentAPI.response.data]);
      createIncomeComponentForm.resetForm();
    }
  }, [createIncomeComponentAPI.response, createIncomeComponentAPI.loading]);

  const deleteIncomeComponentAPI = useAPI(deleteIncomeComponentAxios(componentToDelete), { immediate: false });

  useEffect(() => {
    if (componentToDelete) deleteIncomeComponentAPI.fetch();
  }, [componentToDelete]);

  useEffect(() => {
    const { response, loading } = deleteIncomeComponentAPI;

    if (response && !loading) {
      let i;

      activityComponents.forEach((component, index) => {
        if (component.uuid === componentToDelete) i = index;
      });
      let updatedList = [...activityComponents];
      if (i !== undefined) {
        updatedList.splice(i, 1);
        setActivityComponents(updatedList);
      }
    }
  }, [deleteIncomeComponentAPI.response, deleteIncomeComponentAPI.loading]);

  return (
    <>
      {button && cloneElement(button, { onClick: () => setOpen(true) })}
      <Modal open={open} size="xl">
        <Card>
          <CardHeader>
            <CardTitle>Create income components</CardTitle>
            <CardActions>
              <IconButton
                icon={faTimes}
                onClick={() => {
                  refreshAPI.fetch();
                  createIncomeComponentForm.resetForm();
                  setOpen(false);
                }}
              />
            </CardActions>
          </CardHeader>
          <CardBody>
            <div style={{ display: "flex", gap: "8px" }}>
              <CardBody nested>
                <FormikProvider value={createIncomeComponentForm}>
                  <Form>
                    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                      <IncomeComponentForm form={createIncomeComponentForm} setSchema={setSchema} />
                      {createIncomeComponentAPI.error && (
                        <InfoBox
                          variant={InfoBox?.Variant.ERROR}
                          value={createIncomeComponentAPI.error.response?.data}
                        />
                      )}
                      <div style={{ display: "flex", justifyContent: "end", gap: "8px" }}>
                        <Button
                          color="primary"
                          loading={createIncomeComponentAPI.loading}
                          onClick={() => createIncomeComponentForm.submitForm()}
                        >
                          Create
                        </Button>
                      </div>
                    </div>
                  </Form>
                </FormikProvider>
              </CardBody>
              {caActivity.type === ActivityType.INCOME_ACTIVITY && (
                <ActivityComponentsTable data={activityComponents} remove={setComponentToDelete} />
              )}
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default CreateIncomeComponentModal;
