import { useTheme } from "capitalroadkit";
import React from "react";

const LocalCodeChangeDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>New local code</th>
        <td>{ca.newLocalCode}</td>
        <td>{secondaryCa?.newLocalCode}</td>
      </tr>
      <tr>
        <th>Old local code</th>
        <td>{ca.oldLocalCode}</td>
        <td>{secondaryCa?.oldLocalCode}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Event type</th>
        <td>{ca.eventType}</td>
        <td>{secondaryCa?.eventType}</td>
      </tr>
      <tr>
        <th>Action flag</th>
        <td>{ca.actionFlag}</td>
        <td>{secondaryCa?.actionFlag}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Par value currency</th>
        <td>{ca.parValueCurrency}</td>
        <td>{secondaryCa?.parValueCurrency}</td>
      </tr>
      <tr>
        <th>Security par value</th>
        <td>{ca.securityParValue}</td>
        <td>{secondaryCa?.securityParValue}</td>
      </tr>
    </>
  );
};

export default LocalCodeChangeDetails;
