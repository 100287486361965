const TransactionType = Object.freeze({
  ACC: "ACC",
  MCC: "MCC",
  ACD: "ACD",
  MCD: "MCD",
  CA2: "CA2",
  WT2: "WT2",
  CDP: "CDP",
  CUP: "CUP",
  TCG: "TCG",
  TGC: "TGC",
  NFR: "NFR",
  NFE: "NFE",
  NFC: "NFC",
});

export default TransactionType;
