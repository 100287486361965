import { faClipboard } from "@fortawesome/pro-regular-svg-icons/faClipboard";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem } from "@material-ui/core";
import { Dropdown, Table, useTheme } from "capitalroadkit";
import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import { parseEnum, toCurrency } from "../../../resources/formatters/Formatter";
import LedgerSubType from "../../../types/CashService/LedgerSubType";

const LedgerTable = ({ data, hiddenColumns, isSystem, isClient }) => {
  const navigate = useNavigate();
  const [theme] = useTheme();

  const isNotSummaryOrSystem = (original) =>
    !(original.uuid === "NON_RETAIL_CLIENT" || original.uuid === "RETAIL_CLIENT" || isSystem);
  const isSummary = (original) => original.uuid === "NON_RETAIL_CLIENT" || original.uuid === "RETAIL_CLIENT";

  const transactionSearchLink = (value, original, isBalance) => (
    <Link
      to={"/mse-cash-active/transactions"}
      state={{ uuid: original.uuid, subType: original.subType, group: original.group }}
      style={{ color: (isBalance && value) > 0 ? theme.palette.danger.main : "" }}
    >
      {isBalance ? toCurrency(value) : value}
    </Link>
  );

  const columns = useMemo(
    () => [
      {
        id: "LedgerTable",
        columns: [
          {
            id: "actions",
            sort: false,
            Cell: ({ row: { original } }) => {
              if (isNotSummaryOrSystem(original)) {
                return (
                  <Dropdown button={<FontAwesomeIcon icon={faEllipsisV} style={{ cursor: "pointer" }} />}>
                    <Link to={`/mse-cash-active/ledger-balance/${original.uuid}/accounts`}>
                      <MenuItem>View accounts</MenuItem>
                    </Link>
                    <Link to={`/mse-cash-active/ledger-balance/${original.uuid}/payments`}>
                      <MenuItem>View payments</MenuItem>
                    </Link>
                    <Link to={`/mse-cash-active/ledger-balance/${original.uuid}/payments/new`}>
                      <MenuItem>New payment</MenuItem>
                    </Link>
                    <Link to={`/mse-cash-active/ledger-balance/${original.uuid}/payment-schedules`}>
                      <MenuItem>View scheduled payments</MenuItem>
                    </Link>
                    <Link to={`/mse-cash-active/ledger-balance/${original.uuid}/payment-schedules/new`}>
                      <MenuItem>New scheduled payment</MenuItem>
                    </Link>
                    <Link to={`/mse-cash-active/ledger-balance/${original.uuid}/payees`}>
                      <MenuItem>View payees</MenuItem>
                    </Link>
                    <Link to={`/mse-cash-active/ledger-balance/${original.uuid}/payees/new`}>
                      <MenuItem>New payee</MenuItem>
                    </Link>
                  </Dropdown>
                );
              }

              if (isSummary(original)) {
                return (
                  <FontAwesomeIcon
                    icon={faClipboard}
                    onClick={() =>
                      navigate("/mse-cash-active/client-ledgers", {
                        state: { group: original.group, subType: LedgerSubType.CLI },
                      })
                    }
                    style={{ cursor: "pointer", fontSize: "1rem" }}
                  />
                );
              }

              return null;
            },
          },
          {
            accessor: "type",
            Header: "Type",
          },
          {
            accessor: "name",
            Header: "Name",
            Cell: ({ cell: { value }, row: { original } }) => transactionSearchLink(value, original, false),
          },
          {
            accessor: "group",
            Header: "Group",
            Cell: ({ value }) => parseEnum(value),
          },
          {
            accessor: "subType",
            Header: "Sub type",
          },
          {
            accessor: "holdersReference",
            Header: "Holders reference",
          },
          {
            accessor: "debitBalance",
            rightAlign: true,
            Header: "Debit balance",
            Cell: ({ cell: { value }, row: { original } }) => transactionSearchLink(value, original, true),
          },
          {
            accessor: "creditBalance",
            rightAlign: true,
            Header: "Credit balance",
            Cell: ({ cell: { value }, row: { original } }) => transactionSearchLink(value, original, true),
          },
          {
            accessor: "netBalance",
            rightAlign: true,
            Header: "Net balance",
            Cell: ({ cell: { value }, row: { original } }) => transactionSearchLink(value, original, true),
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
      sortBy={[
        {
          id: isClient ? "netBalance" : "name",
          desc: true,
        },
      ]}
    />
  );
};

export default LedgerTable;
