import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Grid,
  Page,
  PageHeader,
  CardTitle,
  Button,
  InfoBox,
  CardActions,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import AssetForm from "../../forms/AssetForm";
import useAPI from "../../../../hooks/useAPI";
import { createAssetAxios } from "../../api/AssetResource";

const NewAsset = () => {
  const navigate = useNavigate();

  const createAssetAPI = useAPI(createAssetAxios(), { immediate: false });
  const [schema, setSchema] = useState(AssetForm.schema());

  const assetForm = useFormik({
    initialValues: AssetForm.initialValues(),
    onSubmit: (values) => {
      const clean = AssetForm.clean(values);
      createAssetAPI.fetch({}, clean);
    },
    validationSchema: schema,
    enableReinitialize: true,
  });

  const response = createAssetAPI.response;
  const loading = createAssetAPI.loading;
  const error = createAssetAPI.error;

  return (
    <Page id="CreateAsset">
      <Grid>
        <PageHeader heading="Create asset" />
        <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>Create asset</CardTitle>
            </CardHeader>
            <FormikProvider value={assetForm}>
              <Form>
                <Grid nested>
                  <Col lg={4} md={6} sm={12}>
                    <CardBody>
                      <Grid nested>
                        <Col sm={12}>
                          <AssetForm form={assetForm} setSchema={setSchema} />
                        </Col>
                        {error && (
                          <Col sm={12}>
                            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
                          </Col>
                        )}
                        {response && !error && (
                          <Col sm={12}>
                            <InfoBox
                              variant={InfoBox?.Variant.SUCCESS}
                              value={
                                <Grid nested>
                                  <Col sm={6} style={{ marginTop: "20px" }}>
                                    Successfully created!
                                  </Col>
                                  <Col sm={6} style={{ marginTop: "16px", textAlign: "right" }}>
                                    <Button
                                      color="success"
                                      variant="text"
                                      style={{ marginLeft: "4px" }}
                                      onClick={() => navigate("/mse-asset/assets")}
                                    >
                                      Back
                                    </Button>
                                    <Button
                                      color="success"
                                      variant="text"
                                      style={{ marginLeft: "4px" }}
                                      onClick={() => navigate(`/mse-asset/assets/${response.data.uuid}`)}
                                    >
                                      View asset
                                    </Button>
                                  </Col>
                                </Grid>
                              }
                            />
                          </Col>
                        )}
                        <Col sm={12} style={{ marginTop: "16px", textAlign: "right" }}>
                          <Button
                            color="warning"
                            disable={loading}
                            style={{ marginLeft: "4px" }}
                            onClick={() => navigate("/mse-asset/assets")}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="success"
                            disable={loading}
                            loading={loading}
                            style={{ marginLeft: "4px" }}
                            type="submit"
                          >
                            Save
                          </Button>
                        </Col>
                      </Grid>
                    </CardBody>
                  </Col>
                </Grid>
              </Form>
            </FormikProvider>
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

export default NewAsset;
