import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import {
  Button,
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Grid,
  IconButton,
  InfoBox,
  Modal,
} from "capitalroadkit";
import React, { useEffect } from "react";
import useAPI from "../../../../hooks/useAPI";
import { postCliTransactionAxios } from "../../api/TransactionResource";

const PostTransactionModal = ({ listLedgerTransactionsAPI, transaction, setTransaction, form }) => {
  const postCliTransactionAPI = useAPI(postCliTransactionAxios(transaction?.uuid), { immediate: false });

  useEffect(() => {
    if (postCliTransactionAPI.response && !postCliTransactionAPI.loading) {
      setTransaction(null);
      if (form) form.submitForm();
      else listLedgerTransactionsAPI.fetch();
    }
  }, [postCliTransactionAPI.response, postCliTransactionAPI.loading]);

  return (
    <Modal open={transaction}>
      {transaction && (
        <Card>
          <CardHeader>
            <CardTitle>Post transaction</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setTransaction(null)} />
            </CardActions>
          </CardHeader>
          <CardBody>
            <Grid nested>
              {postCliTransactionAPI.error && (
                <Col sm={12}>
                  <InfoBox variant={InfoBox.Variant.ERROR} value={postCliTransactionAPI.error.response.data} />
                </Col>
              )}
              <Col sm={12} style={{ textAlign: "right" }}>
                <Button color="danger" disabled={postCliTransactionAPI.loading} onClick={() => setTransaction(null)}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  loading={postCliTransactionAPI.loading}
                  onClick={() => postCliTransactionAPI.fetch()}
                  style={{ marginLeft: "1rem" }}
                  type="submit"
                >
                  Post
                </Button>
              </Col>
            </Grid>
          </CardBody>
        </Card>
      )}
    </Modal>
  );
};
export default PostTransactionModal;
