import { Col, Grid, TextField, Checkbox, Button } from "capitalroadkit";
import { Field } from "formik";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { parseEnum } from "../../../../resources/formatters/Formatter";
import CorporateActionType from "../../../../types/corporateActions/CorporateActionType";
import Chip from "@material-ui/core/Chip";

const fields = {
  TYPE: "type",
  TAGS: "tags",
  TAG: "tag",
  ASSET_CODE: "asset-code",
  DATE_TYPE: "dateType",
  DATE: "date",
  HIDE_OVERRIDDEN: "overridden",
};

const initialValues = {
  [fields.TYPE]: "",
  [fields.TAGS]: [],
  [fields.TAG]: "",
  [fields.ASSET_CODE]: "",
  [fields.DATE_TYPE]: "ex-date",
  [fields.DATE]: "",
  [fields.HIDE_OVERRIDDEN]: false,
};

const CorporateActionsSearchForm = ({ form }) => {
  const removeTag = (tag) => {
    form.setFieldValue(
      fields.TAGS,
      form.values[fields.TAGS].filter((value) => value !== tag)
    );
    form.submitForm();
  };

  return (
    <>
      <Col sm={12} style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        <Field component={TextField} label="Type" name={fields.TYPE} select>
          <MenuItem value="">All</MenuItem>
          {Object.values(CorporateActionType).map((type) => (
            <MenuItem value={type}>{parseEnum(type)}</MenuItem>
          ))}
        </Field>
        <Field component={TextField} label="Asset code" name={fields.ASSET_CODE} />
        <Field component={TextField} label="Date type" name="dateType" select>
          <MenuItem value="ex-date">Ex date</MenuItem>
          <MenuItem value="record-date">Record date</MenuItem>
          <MenuItem value="payment-date">Payment date</MenuItem>
          <MenuItem value="date-of-announcement">Date of announcement</MenuItem>
          <MenuItem value="date-of-revision">Date of revision</MenuItem>
          <MenuItem value="processing-date">Processing date</MenuItem>
          <MenuItem value="application-closing-date">Application closing date</MenuItem>
        </Field>
        <Field component={TextField} label="Date" mask="99/99/9999" name={fields.DATE} />
        <Field component={Checkbox} label="Hide overridden" name={fields.HIDE_OVERRIDDEN} />
      </Col>
      <Col sm={12}>
        <Grid nested>
          <Col lg={4} md={4} sm={12}>
            <Field
              component={TextField}
              label="Tags"
              name={fields.TAG}
              fullWidth
              endAdornment={
                <Button
                  style={{ alignSelf: "flex-end", marginBottom: "4px" }}
                  color="primary"
                  variant="text"
                  disable={!form.values || form.values[fields.TAG] === ""}
                  onClick={() => form.submitForm()}
                >
                  Add
                </Button>
              }
            />
          </Col>
          <Col lg={12}>
            <div style={{ maxWidth: "100%", display: "flex", flexWrap: "wrap", gap: "4px", marginRight: "4px" }}>
              {form.values[fields.TAGS]
                .sort((a, b) => a.length - b.length)
                .map((tag) => (
                  <Chip
                    key={tag}
                    tabIndex={-1}
                    label={tag}
                    style={{ maxWidth: "100%", margin: "4px 0" }}
                    onDelete={() => removeTag(tag)}
                  />
                ))}
              {form.values[fields.TAGS].length > 0 && (
                <Button
                  style={{ alignSelf: "flex-end", marginBottom: "4px" }}
                  color="primary"
                  variant="text"
                  disable={!form.values || form.values[fields.TAG] === ""}
                  onClick={() => {
                    if (form.values[fields.TAGS] !== []) {
                      form.setFieldValue(fields.TAGS, []);
                      form.setFieldValue(fields.TAG, "");
                      form.submitForm();
                    }
                  }}
                >
                  Clear all
                </Button>
              )}
            </div>
          </Col>
        </Grid>
      </Col>
    </>
  );
};

CorporateActionsSearchForm.fields = fields;
CorporateActionsSearchForm.initialValues = initialValues;

export default CorporateActionsSearchForm;
