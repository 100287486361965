import { TextField } from "capitalroadkit";
import * as Yup from "yup";
import clone from "lodash/cloneDeep";
import { Field, useFormikContext } from "formik";
import withNameSpace from "../../../../../functions/withNameSpace";
import React, { useEffect, useState } from "react";
import VolumeCalculatorType from "../../../../../types/corporateActions/Activities/VolumeCalculatorType";
import RatioForm from "./RatioForm";
import MultiplierForm from "./MultiplierForm";
import FixedForm from "./FixedForm";
import { MenuItem } from "@material-ui/core";
import { parseEnum } from "../../../../../resources/formatters/Formatter";
import IncomeComponentForm from "../../IncomeComponentForm";
import ActivityForm from "../index";

const fields = {
  TYPE: "type",
};

const initialValues = (volumeCalculator) => {
  const baseInitialValues = {
    [fields.TYPE]: volumeCalculator?.type || "",
  };

  switch (volumeCalculator?.type) {
    case VolumeCalculatorType.RATIO:
      return { ...baseInitialValues, ...RatioForm.initialValues(volumeCalculator) };
    case VolumeCalculatorType.MULTIPLIER:
      return { ...baseInitialValues, ...MultiplierForm.initialValues(volumeCalculator) };
    case VolumeCalculatorType.FIXED:
      return { ...baseInitialValues, ...FixedForm.initialValues(volumeCalculator) };
    default:
      return baseInitialValues;
  }
};

const clean = (values, calculatorField) => {
  let cleanValues = clone(values);

  let volumeCalculator;

  switch (cleanValues[calculatorField][fields.TYPE]) {
    case VolumeCalculatorType.RATIO:
      volumeCalculator = RatioForm.clean(cleanValues, calculatorField);
      break;
    case VolumeCalculatorType.MULTIPLIER:
      volumeCalculator = MultiplierForm.clean(cleanValues, calculatorField);
      break;
    case VolumeCalculatorType.FIXED:
      volumeCalculator = FixedForm.clean(cleanValues, calculatorField);
      break;
    default:
      break;
  }

  delete cleanValues[calculatorField];
  cleanValues[calculatorField] = volumeCalculator;

  return cleanValues;
};

const schema = (optional) => {
  return Yup.object().shape({
    [fields.TYPE]: optional ? Yup.string() : Yup.string().required("Type required"),
    [FixedForm.fields.VOLUME]: Yup.number().when("type", {
      is: VolumeCalculatorType.FIXED,
      then: Yup.number().required("Volume required"),
    }),
    [MultiplierForm.fields.MULTIPLIER]: Yup.number().when("type", {
      is: VolumeCalculatorType.MULTIPLIER,
      then: Yup.number().required("Multiplier required"),
    }),
    [RatioForm.fields.SCALE]: Yup.number().when("type", {
      is: VolumeCalculatorType.RATIO,
      then: Yup.number().required("Scale required"),
    }),
    [RatioForm.fields.NUMERATOR]: Yup.number()
      .integer()
      .when("type", {
        is: VolumeCalculatorType.RATIO,
        then: Yup.number().required("Numerator required").min(1),
      }),
    [RatioForm.fields.DENOMINATOR]: Yup.number()
      .integer()
      .when("type", {
        is: VolumeCalculatorType.RATIO,
        then: Yup.number().required("Denominator required").min(1),
      }),
  });
};

const VolumeCalculatorForm = ({ value, title, nameSpace, setSchema, isActivityForm, optional }) => {
  const { setFieldValue, values } = useFormikContext();
  const [type, setType] = useState(value.type);

  useEffect(() => {
    if (value[fields.TYPE] && value[fields.TYPE] !== type) {
      setType(value[fields.TYPE]);
      setSchema(isActivityForm ? ActivityForm.schema : IncomeComponentForm.schema(values));

      setFieldValue(nameSpace, { [fields.TYPE]: value[fields.TYPE], ...initialValues(value) });
    }
  }, [value.type]);

  const renderVolumeCalculatorForm = () => {
    switch (value[fields.TYPE]) {
      case VolumeCalculatorType.RATIO:
        return <RatioForm nameSpace={nameSpace} />;
      case VolumeCalculatorType.MULTIPLIER:
        return <MultiplierForm nameSpace={nameSpace} />;
      case VolumeCalculatorType.FIXED:
        return <FixedForm nameSpace={nameSpace} />;
      default:
        return null;
    }
  };

  return (
    <>
      {title}
      <Field component={TextField} label="Type" name={withNameSpace(nameSpace, fields.TYPE)} required select>
        {optional && (
          <MenuItem key="none" value="">
            N/A
          </MenuItem>
        )}
        {Object.entries(VolumeCalculatorType).map((type) => (
          <MenuItem key={type[0]} value={type[0]}>
            {parseEnum(type[0])}
          </MenuItem>
        ))}
      </Field>
      {renderVolumeCalculatorForm()}
    </>
  );
};

VolumeCalculatorForm.fields = fields;
VolumeCalculatorForm.initialValues = initialValues;
VolumeCalculatorForm.schema = schema;
VolumeCalculatorForm.clean = clean;

export default VolumeCalculatorForm;
