import { CardBody, Grid, Col } from "capitalroadkit";
import React from "react";
import TaxCountryReview from "./TaxCountryReview";
import BeneficiaryType from "../../../../types/CashService/Accounts/BeneficiaryType";
import { parseBoolean, parseEnum, toDateString } from "../../../../resources/formatters/Formatter";

const BeneficiaryReview = ({ beneficiary, index, findCountryForCode }) => (
  <CardBody nested>
    <Grid nested>
      <Col sm={12}>
        <table
          style={{
            borderCollapse: "collapse",
            tableLayout: "fixed",
            textAlign: "left",
            width: "100%",
          }}
        >
          <tr>
            <th>Beneficiary {index + 1}</th>
          </tr>
          <tr>
            <th>Beneficiary name:</th>
            <td>{beneficiary.name}</td>
          </tr>
          <tr>
            <th>Type:</th>
            <td>{parseEnum(beneficiary.type)}</td>
          </tr>
          <tr>
            <th>Aus tax resident only:</th>
            <td>{parseBoolean(beneficiary.additionalData.australianTaxResidentOnly)}</td>
          </tr>
          {beneficiary.type === BeneficiaryType.INDIVIDUAL && (
            <>
              <tr>
                <th>TFN:</th>
                <td>{beneficiary.taxFileNumber}</td>
              </tr>
              <tr>
                <th>Date of birth:</th>
                <td>{toDateString(beneficiary.dateOfBirth)}</td>
              </tr>
              <tr>
                <th>Country of birth:</th>
                <td>{findCountryForCode(beneficiary.additionalData.placeOfBirth)}</td>
              </tr>
            </>
          )}
          {beneficiary.type !== BeneficiaryType.INDIVIDUAL && (
            <>
              <tr>
                <th>ABN:</th>
                <td>{beneficiary.abn}</td>
              </tr>
              <tr>
                <th>Country of establishment:</th>
                <td>{findCountryForCode(beneficiary.additionalData.placeOfEstablishmentIncorporation)}</td>
              </tr>
              <tr>
                <th>Country of effective management:</th>
                <td>{findCountryForCode(beneficiary.additionalData.placeOfEffectiveManagement)}</td>
              </tr>
            </>
          )}
          <tr>
            <th>Email:</th>
            <td>{beneficiary.email}</td>
          </tr>
          <tr>
            <th>Address:</th>
            <td>{beneficiary.primaryAddress.addressLine}</td>
          </tr>
          <tr>
            <tr />
            <td>
              {beneficiary.primaryAddress.city} {beneficiary.primaryAddress.state} {beneficiary.primaryAddress.postCode}
            </td>
          </tr>
          {beneficiary.taxCountries.map((taxCountry, index) => (
            <TaxCountryReview taxCountry={taxCountry} index={index} findCountryForCode={findCountryForCode} />
          ))}
        </table>
      </Col>
    </Grid>
  </CardBody>
);

export default BeneficiaryReview;
