import {
  InfoBox,
  Card,
  CardBody,
  CardTitle,
  CardActions,
  CardHeader,
  Col,
  Grid,
  Page,
  PageHeader,
  Button,
  useSessionStorage,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import clone from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";

import AssetTable from "../../components/AssetTable";

import Loading from "../../../../Loading";
import useAPI from "../../../../hooks/useAPI";
import AssetSearchForm from "../../forms/AssetSearchForm";
import { searchAssetsAxios } from "../../api/AssetResource";

const AssetsIndex = () => {
  const navigate = useNavigate();

  const [lastParams, setLastParams] = useSessionStorage("LastParams", {});

  const listAssetsAPI = useAPI(searchAssetsAxios(), { initialParams: { ...lastParams, limit: 10 } });

  const debouncedListAssetsAPI = useRef(debounce(listAssetsAPI.fetch, 500));

  useEffect(() => {
    if (listAssetsAPI.lastParams) {
      setLastParams(listAssetsAPI.lastParams);
    }
  }, [listAssetsAPI.lastParams]);

  const assetSearchForm = useFormik({
    initialValues: AssetSearchForm.initialValues,
    onSubmit: (values) => {
      const clean = clone(values);

      for (const propName in clean) {
        if (clean[propName] === "") {
          delete clean[propName];
        }
      }

      debouncedListAssetsAPI.current({ ...clean, limit: 10, offset: 0 });
    },
  });

  useEffect(() => {
    Object.entries(lastParams).forEach((param) => {
      if (assetSearchForm.values[param[0]] !== param[1]) {
        switch (param[0]) {
          case "limit":
          case "offset":
            break;
          default:
            assetSearchForm.setFieldValue(param[0], param[1]);
        }
      }
    });
  }, []);

  const response = listAssetsAPI.response;
  const error = listAssetsAPI.error;
  const loading = listAssetsAPI.loading;

  if (response) {
    const assets = listAssetsAPI.response.data.list;

    return (
      <Page id="Assets">
        <Grid>
          <PageHeader heading="Assets" />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Asset search</CardTitle>
                <CardActions>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() => navigate("/mse-asset/assets/new")}
                    style={{ marginLeft: "8px" }}
                  >
                    New asset
                  </Button>
                </CardActions>
              </CardHeader>
              <CardBody>
                <FormikProvider value={assetSearchForm}>
                  <Form>
                    <div
                      style={{
                        alignItems: "end",
                        display: "flex",
                        marginBottom: "4px",
                        flexWrap: "wrap",
                        gap: "16px",
                      }}
                    >
                      <AssetSearchForm form={assetSearchForm} />
                      <Button color="primary" loading={loading} type="submit">
                        Search
                      </Button>
                    </div>
                  </Form>
                </FormikProvider>
              </CardBody>
              <AssetTable api={listAssetsAPI} data={assets} />
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page id="Assets">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default AssetsIndex;
