import { useTheme } from "capitalroadkit";
import { toDateString } from "../../../../../../resources/formatters/Formatter";

const CompanyMeetingDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>AGM date</th>
        <td>{toDateString(ca.agmDate)}</td>
        <td>{toDateString(secondaryCa?.agmDate)}</td>
      </tr>
      <tr>
        <th>City address</th>
        <td>{ca.cityAddress}</td>
        <td>{secondaryCa?.cityAddress}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Meeting time</th>
        <td>{ca.meetingTime}</td>
        <td>{secondaryCa?.meetingTime}</td>
      </tr>
      <tr>
        <th>Meeting type</th>
        <td>{ca.meetingType}</td>
        <td>{secondaryCa?.meetingType}</td>
      </tr>
    </>
  );
};

export default CompanyMeetingDetails;
