import { Card, CardBody, Col, Grid, Page, PageHeader, Button, InfoBox, useSessionStorage } from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import useAPI from "../../../../hooks/useAPI";
import { createClientAccountAxios } from "../../api/CashActiveEntityResource";
import AccountForm from "../../forms/AccountForms/AccountForm";
import AccountReview from "../../components/Account/AccountReview";
import { listCountriesAxios } from "../../api/ReferenceDataResource";
import Loading from "../../../../Loading";

const NewAccount = () => {
  const navigate = useNavigate();
  const [countries, setCountries] = useSessionStorage("countries", []);

  const listCountriesAPI = useAPI(listCountriesAxios(), { immediate: false });

  useEffect(() => {
    if (countries.length === 0) listCountriesAPI.fetch();
  }, [countries]);

  useEffect(() => {
    if (listCountriesAPI.response) setCountries(listCountriesAPI.response.data);
  }, [listCountriesAPI.response]);

  const findCountryForCode = (code) => {
    const country = countries.find((country) => country.value === code);
    if (country) return country.key;
    return code;
  };

  const createClientAccountAPI = useAPI(createClientAccountAxios(), { immediate: false });

  const [review, setReview] = useState(false);
  const [accountRequest, setAccountRequest] = useState(null);

  const accountForm = useFormik({
    initialValues: AccountForm.initialValues,
    onSubmit: (values) => {
      const clean = AccountForm.clean(values);
      for (const propName in clean) {
        if (clean[propName] === "") {
          delete clean[propName];
        }
      }
      setAccountRequest(clean);
      setReview(true);
    },
  });

  const sendAccountRequest = () => createClientAccountAPI.fetch({}, accountRequest);

  const { response, loading, error } = createClientAccountAPI;

  if (countries.length > 0)
    return (
      <Page id="NewAccount">
        <Grid>
          <PageHeader heading="Create account" />
          <Col sm={12}>
            <Card>
              <FormikProvider value={accountForm}>
                <Form>
                  <Grid nested>
                    <Col lg={4} md={6} sm={12}>
                      <CardBody>
                        <Grid nested>
                          {review ? (
                            <Col sm={12}>
                              {accountRequest !== null && (
                                <AccountReview
                                  accountRequest={accountRequest}
                                  countries={countries}
                                  findCountryForCode={findCountryForCode}
                                />
                              )}
                            </Col>
                          ) : (
                            <Col sm={12}>
                              <AccountForm
                                form={accountForm}
                                countries={countries}
                                findCountryForCode={findCountryForCode}
                              />
                            </Col>
                          )}
                          {response && !error && (
                            <Col sm={12}>
                              <InfoBox
                                variant={InfoBox?.Variant.SUCCESS}
                                value={
                                  <Grid nested>
                                    <Col sm={6} style={{ marginTop: "20px" }}>
                                      Successfully created!
                                    </Col>
                                    <Col sm={6} style={{ marginTop: "16px", textAlign: "right" }}>
                                      <Button
                                        color="success"
                                        variant="text"
                                        style={{ marginLeft: "4px" }}
                                        onClick={() => navigate(-1)}
                                      >
                                        Back
                                      </Button>
                                      <Button
                                        color="success"
                                        variant="text"
                                        style={{ marginLeft: "4px" }}
                                        onClick={() =>
                                          navigate(`/mse-cash-active/ledger-balance/${response.data.uuid}/accounts`)
                                        }
                                      >
                                        View accounts
                                      </Button>
                                    </Col>
                                  </Grid>
                                }
                              />
                            </Col>
                          )}
                          {error && (
                            <Col sm={12}>
                              <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
                            </Col>
                          )}
                          <Col sm={12} style={{ marginTop: "16px", textAlign: "right" }}>
                            {review && response && (
                              <>
                                <Button
                                  color="warning"
                                  disable={loading}
                                  loading={loading}
                                  style={{ marginLeft: "4px" }}
                                  onClick={() => navigate(-1)}
                                >
                                  Back
                                </Button>
                                <Button
                                  color="success"
                                  disable={loading}
                                  loading={loading}
                                  style={{ marginLeft: "4px" }}
                                  onClick={() => {
                                    accountForm.resetForm();
                                    createClientAccountAPI.reset();
                                    setReview(!review);
                                  }}
                                >
                                  Create another
                                </Button>
                              </>
                            )}
                            {review && !response && (
                              <>
                                <Button
                                  color="warning"
                                  disable={loading}
                                  loading={loading}
                                  style={{ marginLeft: "4px" }}
                                  onClick={() => setReview(!review)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  color="success"
                                  disable={loading}
                                  loading={loading}
                                  style={{ marginLeft: "4px" }}
                                  onClick={() => sendAccountRequest()}
                                >
                                  Submit
                                </Button>
                              </>
                            )}
                            {!review && !response && (
                              <>
                                <Button
                                  color="warning"
                                  disable={loading}
                                  style={{ marginLeft: "4px" }}
                                  onClick={() => navigate(-1)}
                                >
                                  Cancel
                                </Button>
                                <Button color="success" disable={loading} style={{ marginLeft: "4px" }} type="submit">
                                  Review
                                </Button>
                              </>
                            )}
                          </Col>
                        </Grid>
                      </CardBody>
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  if (listCountriesAPI.loading) return <Loading />;
  if (listCountriesAPI.error)
    return (
      <Page id="New account">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={listCountriesAPI.error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default NewAccount;
