import { Table } from "capitalroadkit";
import { useMemo } from "react";
import { parseEnum, toCurrency } from "../../../../../../resources/formatters/Formatter";

const ActivityComponentsTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        id: "EntitlementSubRow",
        columns: [
          {
            accessor: "componentTypeUuid",
            Header: "Type",
            Cell: ({ cell }) => parseEnum(cell.value),
          },
          {
            accessor: "grossAmount",
            Header: "Gross",
            rightAlign: true,
            Cell: ({ cell }) => toCurrency(cell.value),
          },
          {
            accessor: "taxAmount",
            Header: "Tax",
            rightAlign: true,
            Cell: ({ cell }) => toCurrency(cell.value),
          },
          {
            accessor: "netAmount",
            Header: "Net",
            rightAlign: true,
            Cell: ({ cell }) => toCurrency(cell.value),
          },
        ],
      },
    ],
    []
  );

  return <Table size="small" columns={columns} data={data} />;
};

export default ActivityComponentsTable;
