import PayType from "../types/corporateActions/PayType";
import ProgressType from "../types/corporateActions/ProgressType";

export const payTypeDescription = (type) => {
  switch (type) {
    case "B":
      return PayType.B;
    case "C":
      return PayType.C;
    case "D":
      return PayType.D;
    case "S":
      return PayType.S;
    case "T":
      return PayType.T;
    default:
      return "Pay type is currently unknown";
  }
};

export const progressTypeDescription = (type) => {
  switch (type) {
    case "O":
      return ProgressType.O;
    case "L":
      return ProgressType.L;
    case "C":
      return ProgressType.C;
    default:
      return "";
  }
};
