import { useTheme } from "capitalroadkit";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { parseEnum, toDateString } from "../../../../../../resources/formatters/Formatter";
import { payTypeDescription, progressTypeDescription } from "../../../../../../functions/corpActionFieldDescription";
import DOMPurify from "dompurify";

const TakeoverDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Sub type</th>
        <td>{parseEnum(ca.subType)}</td>
        <td>{parseEnum(secondaryCa?.subType)}</td>
      </tr>
      <tr>
        <th>Fraction rounding</th>
        <td>{ca.fractionRounding}</td>
        <td>{secondaryCa?.fractionRounding}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Ratio old</th>
        <td>{ca.ratioOld}</td>
        <td>{secondaryCa?.ratioOld}</td>
      </tr>
      <tr>
        <th>Ratio new</th>
        <td>{ca.ratioNew}</td>
        <td>{secondaryCa?.ratioNew}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Action flag</th>
        <td>{ca.actionFlag}</td>
        <td>{secondaryCa?.actionFlag}</td>
      </tr>
      <tr>
        <th>Hostile flag</th>
        <td>{ca.hostileFlag}</td>
        <td>{secondaryCa?.hostileFlag}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Withdrawal rights</th>
        <td>{ca.withdrawalRights}</td>
        <td>{secondaryCa?.withdrawalRights}</td>
      </tr>
      <tr>
        <th>Resultant security</th>
        <td>{ca.resultantSecurity}</td>
        <td>{secondaryCa?.resultantSecurity}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Target name</th>
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ca.targetName) }} />
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(secondaryCa?.targetName) }} />
      </tr>
      <tr>
        <th>Target ISIN</th>
        <td>{ca.targetISIN}</td>
        <td>{secondaryCa?.targetISIN}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Acquirer name</th>
        <td>{ca.acquirerName}</td>
        <td>{secondaryCa?.acquirerName}</td>
      </tr>
      <tr>
        <th>Acquirer ISIN</th>
        <td>{ca.acquirerISIN}</td>
        <td>{secondaryCa?.acquirerISIN}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Progress of takeover</th>
        <td>
          <Tooltip title={progressTypeDescription(ca.progressOfTakeover)} placement="right">
            <div style={{ width: "25px" }}>{ca.progressOfTakeover}</div>
          </Tooltip>
        </td>
        <td>
          <Tooltip title={progressTypeDescription(secondaryCa?.progressOfTakeover)} placement="right">
            <div style={{ width: "25px" }}>{secondaryCa?.progressOfTakeover}</div>
          </Tooltip>
        </td>
      </tr>
      <tr>
        <th>Payment currency</th>
        <td>{ca.paymentCurrency}</td>
        <td>{secondaryCa?.paymentCurrency}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Unconditional date</th>
        <td>{toDateString(ca.unconditionalDate)}</td>
        <td>{toDateString(secondaryCa?.unconditionalDate)}</td>
      </tr>
      <tr>
        <th>CM Acq date</th>
        <td>{toDateString(ca.cmAcqDate)}</td>
        <td>{toDateString(secondaryCa?.cmAcqDate)}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Option election date</th>
        <td>{toDateString(ca.optionElectionDate)}</td>
        <td>{toDateString(secondaryCa?.optionElectionDate)}</td>
      </tr>
      <tr>
        <th>Minimum price</th>
        <td>{ca.minimumPrice}</td>
        <td>{secondaryCa?.minimumPrice}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Maximum price</th>
        <td>{ca.maximumPrice}</td>
        <td>{secondaryCa?.maximumPrice}</td>
      </tr>
      <tr>
        <th>Priority</th>
        <td>{ca.priority}</td>
        <td>{secondaryCa?.priority}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Pay type</th>
        <td>
          <Tooltip title={payTypeDescription(ca.payType)} placement="right">
            <div style={{ width: "25px" }}>{ca.payType}</div>
          </Tooltip>
        </td>
        <td>
          <Tooltip title={payTypeDescription(secondaryCa?.payType)} placement="right">
            <div style={{ width: "25px" }}>{secondaryCa?.payType}</div>
          </Tooltip>
        </td>
      </tr>
      <tr>
        <th>Par value currency</th>
        <td>{ca.parValueCurrency}</td>
        <td>{secondaryCa?.parValueCurrency}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Open date</th>
        <td>{toDateString(ca.openDate)}</td>
        <td>{toDateString(secondaryCa?.openDate)}</td>
      </tr>
      <tr>
        <th>Close date</th>
        <td>{toDateString(ca.closeDate)}</td>
        <td>{toDateString(secondaryCa?.closeDate)}</td>
      </tr>
    </>
  );
};

export default TakeoverDetails;
