import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faFileArchive } from "@fortawesome/pro-regular-svg-icons/faFileArchive";
import { faFileCode } from "@fortawesome/pro-regular-svg-icons/faFileCode";
import { faFileExcel } from "@fortawesome/pro-regular-svg-icons/faFileExcel";
import { faFileImage } from "@fortawesome/pro-regular-svg-icons/faFileImage";
import { faFilePdf } from "@fortawesome/pro-regular-svg-icons/faFilePdf";
import { faFileWord } from "@fortawesome/pro-regular-svg-icons/faFileWord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import numeral from "numeral";
import React from "react";

moment.updateLocale("en", {
  calendar: {
    lastDay: "DD/MM/YYYY",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: "DD/MM/YYYY",
    nextWeek: "DD/MM/YYYY",
    sameElse: "DD/MM/YYYY",
  },
});

export const parseBoolean = (value) => (value ? "Yes" : "No");
export const parseCamelCase = (value) => {
  value = value.replace(/([A-Z])/g, " $1");
  const splitValue = value.split(" ");
  splitValue.forEach((subString, index) => {
    splitValue[index] = subString.charAt(0).toLowerCase() + subString.slice(1);
  });
  value = splitValue.join(" ");
  return value.charAt(0).toUpperCase() + value.slice(1);
};
export const parseEnum = (value) =>
  value
    ? value
        .split("_")
        .join(" ")
        .split("-")
        .join(" ")
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
    : null;

export const parseEnumSentenceCase = (value) => {
  if (value) {
    const string = value.split("_").join(" ").split("-").join(" ").toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return null;
};

export const parsePascalCase = (value) => parseCamelCase(value.charAt(0).toLowerCase() + value.slice(1));

export const toCurrency = (value) => numeral(value).format("$0,0.00[00]");
export const toAPIDateString = (value) => moment(value, "DD/MM/YYYY").format("YYYY-MM-DD");
export const toAPIDateTimeString = (date, time) => `${toAPIDateString(date)}T${time}:00.000`;
export const toCurrencyPrice = (value) => numeral(value).format("$0,0.00[00]");
export const toDateString = (value) => (value ? moment(value).format("DD/MM/YYYY") : null);
export const toDecimalString = (value) => (value ? numeral(value).format("0,0.00") : null);
export const toOrderDateTimeString = (value) => moment(value).format("DD/MM/YYYY h:mm:ss a");
export const toPercentString = (value) => (value && isFinite(value) ? `${value.toFixed(2)}%` : `0%`);
export const toRelativeDateString = (value) => moment(value).calendar();
export const toTimeString = (value) => moment(value).format("h:mm:ss a");
export const toUTCTimeString = (value) => moment.utc(value).format();

export class Formatter {
  static currencyFormatter = (value) => numeral(value).format("$0,0.00");

  static currencyUnderlineFormatter = (cell) => {
    const number = numeral(cell);
    numeral.zeroFormat("$0.00");
    numeral.nullFormat("");
    return <span style={{ textDecoration: "underline" }}>{number.format("$0,0[.]00")}</span>;
  };

  static getDayMonthYearObject = (date) => {
    const momentDate = moment(date, "DD/MM/YYYY");
    return {
      month: momentDate.format("M"),
      day: momentDate.format("D"),
      year: momentDate.format("YYYY"),
    };
  };

  static formatDateDayMonthYear = (day, month, year) => moment(`${day}/${month}/${year}`, "DD/MM/YYYY");

  static addDayToDate = (date, days) => (date ? moment(date, "DD/MM/YYYY").add(days, "days") : null);

  static formatDate(date) {
    return date ? moment(date).format("DD/MM/YYYY") : null;
  }

  static formatCountryCodeAndNumber = (enteredNumber) => {
    const fullNumber = enteredNumber.replace(/-/g, "");
    let countryCode = fullNumber.substr(0, fullNumber.indexOf(" "));
    countryCode = countryCode.replace(/\+/g, "").replace(/ /g, "");

    let number = fullNumber.substr(fullNumber.indexOf(" ") + 1);
    number = number.replace(/\+/g, "").replace(/ /g, "");

    return {
      countryCode,
      number,
    };
  };

  static formatLabel = (label) => {
    const lower = label.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.substring(1).replace(/_/g, " ");
  };

  static addressString = (a) => {
    if (a !== null && a !== undefined) return `${a.line1} ${a.city} ${a.state} ${a.postcode} ${a.country}`;
    return null;
  };

  static isBefore = (date1, date2) => moment(date1).isBefore(date2);

  static extensionFormatterIcon(ext) {
    if (ext === "image/jpeg" || ext === "image/gif" || ext === "image/png") {
      return <FontAwesomeIcon icon={faFileImage} />;
    }
    if (ext === "application/pdf") {
      return <FontAwesomeIcon icon={faFilePdf} />;
    }
    if (
      ext === " application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      ext === "application/msword"
    ) {
      return <FontAwesomeIcon icon={faFileWord} />;
    }
    if (
      ext === "application/vnd.ms-excel" ||
      ext === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return <FontAwesomeIcon icon={faFileExcel} />;
    }
    if (ext === "zip" || ext === "tar") {
      return <FontAwesomeIcon icon={faFileArchive} />;
    }
    if (ext === "xml" || ext === "json") {
      return <FontAwesomeIcon icon={faFileCode} />;
    }
    return <FontAwesomeIcon icon={faFile} />;
  }
}
