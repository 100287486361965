import { useTheme } from "capitalroadkit";
import { faClipboard } from "@fortawesome/pro-regular-svg-icons/faClipboard";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons/faPaperPlane";
import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faStickyNote } from "@fortawesome/pro-regular-svg-icons/faStickyNote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import { Table } from "capitalroadkit";
import React, { useMemo } from "react";
import { parseEnum, toCurrency, toDateString } from "../../../../resources/formatters/Formatter";
import PostingStatus from "../../../../types/CashService/Transactions/PostingStatus";
import TransactionChannelType from "../../../../types/CashService/Transactions/TransactionChannelType";

const TransactionsTable = ({
  data,
  api,
  hiddenColumns,
  isNaturalLedger,
  isClient,
  setPostTransaction,
  setTransaction,
  setUpdateTransaction,
  showLedgerInfo,
}) => {
  const [theme] = useTheme();

  const columns = useMemo(
    () => [
      {
        id: "Transactions",
        Header: "",
        Footer: "",
        columns: [
          {
            Header: "uuid",
            accessor: "uuid",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Transaction date",
            accessor: "transactionDate",
            rightAlign: true,
            Cell: ({ cell }) => toDateString(cell.value),
          },
          {
            Header: "Value date",
            accessor: "valueDate",
            rightAlign: true,
            Cell: ({ cell }) => toDateString(cell.value),
          },
          {
            Header: "Channel",
            accessor: "transactionChannel",
            Cell: ({ cell }) => parseEnum(cell.value),
          },
          {
            Header: "Source",
            accessor: "naturalAccountSource",
            Cell: ({ cell }) => parseEnum(cell.value),
          },
          {
            Header: isNaturalLedger ? "Status" : "Posting status",
            accessor: isNaturalLedger ? "status" : "statusOfPosting",
          },
          {
            Header: "Bank transaction type",
            accessor: "transactionType",
          },
          {
            Header: "Bank reference",
            accessor: "reference",
          },
          {
            Header: "Currency",
            accessor: "currency",
          },
          {
            Header: "Amount",
            accessor: "signedAmount",
            rightAlign: true,
            Cell: ({ cell }) => (
              <p
                style={{
                  color: cell.value > 0 ? theme.palette.success.main : cell.value < 0 ? theme.palette.danger.main : "",
                }}
              >
                {toCurrency(cell.value)}
              </p>
            ),
          },
          {
            id: "actions",
            sort: false,
            headerStyle: {
              width: "7.25rem",
            },
            Cell: ({ row: { original } }) => (
              <>
                {original.notes?.length > 0 && (
                  <Tooltip title="Transaction notes">
                    <div style={{ display: "inline-block" }}>
                      <FontAwesomeIcon
                        icon={faStickyNote}
                        onClick={() => setTransaction(original)}
                        style={{
                          cursor: "pointer",
                          fontSize: "1rem",
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
                {original.type === "CLI_LEDGER_TRANSACTION" && original.statusOfPosting !== PostingStatus.COMPLETED && (
                  <>
                    {(original.transactionChannel === TransactionChannelType.UNKNOWN ||
                      original.transactionChannel === TransactionChannelType.NATURAL_ACCOUNT ||
                      original.transactionChannel === TransactionChannelType.GENERAL_LEDGER) && (
                      <Tooltip title="Update transaction">
                        <div
                          style={{
                            display: "inline-block",
                            marginLeft: original.notes?.length > 0 ? ".5rem" : null,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPencil}
                            onClick={() => setUpdateTransaction(original)}
                            style={{
                              cursor: "pointer",
                              fontSize: "1rem",
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}
                    <Tooltip title="Post transaction">
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: ".5rem",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          onClick={() => setPostTransaction(original)}
                          style={{
                            cursor: "pointer",
                            fontSize: "1rem",
                          }}
                        />
                      </div>
                    </Tooltip>
                  </>
                )}
                {showLedgerInfo && (
                  <Tooltip title="Ledger information">
                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: ".5rem",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faClipboard}
                        onClick={() => setTransaction(original)}
                        style={{
                          cursor: "pointer",
                          fontSize: "1rem",
                        }}
                      />
                    </div>
                  </Tooltip>
                )}
              </>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      api={
        isClient
          ? {
              limit: api.response.data.limit,
              offset: api.response.data.offset,
              totalNumberOfRecords: api.response.data.totalNumberOfRecords,
              fetch: api.fetch,
              params: api.lastParams,
            }
          : null
      }
      columns={columns}
      data={data}
      hiddenColumns={["uuid", ...hiddenColumns]}
      sortBy={[
        {
          id: "transactionDate",
          desc: true,
        },
      ]}
    />
  );
};

export default TransactionsTable;
