import { Col, CardBody, Grid, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import AssetDiscriminator from "../../../../types/corporateActions/AssetDiscriminator";
import MenuItem from "@material-ui/core/MenuItem";
import * as Yup from "yup";
import {
  assetCodeRegex,
  assetCodeWarningString,
  isinRegex,
  isinWarningString,
} from "../../../../resources/CorpActionFormValues";

const fields = {
  FRACTION_ROUNDING: "fractionRounding",
  RATIO_OLD: "ratioOld",
  RATIO_NEW: "ratioNew",
  ACTION_FLAG: "actionFlag",
  PRIORITY: "priority",
  PRIMARY_INSTRUMENT_CLOSE_PRICE: "primaryInstrumentClosePrice",
  CHILD_CLOSE_PRICE: "childClosePrice",
  CHILD_INSTRUMENT: "childInstrument",
};

const initialValues = (ca) => ({
  [fields.FRACTION_ROUNDING]: ca?.fractionRounding || "",
  [fields.RATIO_OLD]: ca?.ratioOld || "",
  [fields.RATIO_NEW]: ca?.ratioNew || "",
  [fields.ACTION_FLAG]: ca?.actionFlag || "",
  [fields.PRIORITY]: ca?.priority || "",
  [fields.PRIMARY_INSTRUMENT_CLOSE_PRICE]: ca?.primaryInstrumentClosePrice || "",
  [fields.CHILD_CLOSE_PRICE]: ca?.childClosePrice || "",
  [fields.CHILD_INSTRUMENT]: ca?.childInstrument || "",
});

const clean = (values) => {
  return values;
};

const schema = Yup.object({});

const DemergerForm = ({ form, nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Demerger fields</Col>
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Fraction rounding"
                fullWidth
                name={withNameSpace(nameSpace, fields.FRACTION_ROUNDING)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.fractionRounding}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Ratio old"
                fullWidth
                name={withNameSpace(nameSpace, fields.RATIO_OLD)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.ratioOld}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Ratio new"
                fullWidth
                name={withNameSpace(nameSpace, fields.RATIO_NEW)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.ratioNew}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Action flag"
                fullWidth
                name={withNameSpace(nameSpace, fields.ACTION_FLAG)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.actionFlag}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Priority"
                fullWidth
                name={withNameSpace(nameSpace, fields.PRIORITY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.priority}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Primary instrument close price"
                fullWidth
                name={withNameSpace(nameSpace, fields.PRIMARY_INSTRUMENT_CLOSE_PRICE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.primaryInstrumentClosePrice}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Child close price"
                fullWidth
                name={withNameSpace(nameSpace, fields.CHILD_CLOSE_PRICE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.childClosePrice}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Child instrument"
                fullWidth
                name={withNameSpace(nameSpace, fields.CHILD_INSTRUMENT)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.childInstrument}
              </Col>
            )}
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

DemergerForm.fields = fields;
DemergerForm.initialValues = initialValues;
DemergerForm.schema = schema;
DemergerForm.clean = clean;

export default DemergerForm;
