import { createUseStyles } from "react-jss";

const len = "242.776657104492px";
const time = "1s";

const useStyles = createUseStyles(() => ({
  loader: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0) rotate(45deg)",
  },
  outline: ({ mountDelay }) => ({
    strokeDasharray: `calc(${len} * 0.01), ${len}`,
    strokeDashoffset: "0px",
    animation: `$anim ${time} linear infinite`,
    animationDelay: mountDelay,
  }),
  "@keyframes anim": {
    "12.5%": {
      strokeDasharray: `calc(${len} * 0.14), ${len}`,
      strokeDashoffset: `calc(-${len} * 0.11)`,
    },
    "43.75%": {
      strokeDasharray: `calc(${len} * 0.35), ${len}`,
      strokeDashoffset: `calc(-${len} * 0.35)`,
    },
    "100%": {
      strokeDasharray: `calc(${len} * 0.01), $len`,
      strokeDashoffset: `calc(-${len} * 0.99)`,
    },
  },
}));

export default useStyles;
