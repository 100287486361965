import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Grid,
  Page,
  PageHeader,
  CardTitle,
  Button,
  InfoBox,
  CardActions,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import useAPI from "../../../../hooks/useAPI";
import AssetForm from "../../forms/AssetForm";
import Loading from "../../../../Loading";
import { getAssetAxios, updateAssetAxios } from "../../api/AssetResource";

const UpdateAsset = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [asset, setAsset] = useState(null);
  const [schema, setSchema] = useState(null);

  const getAssetAPI = useAPI(getAssetAxios(uuid));

  useEffect(() => {
    const { response, loading } = getAssetAPI;

    if (response && !loading) setAsset(response.data);
  }, [getAssetAPI.response, getAssetAPI.loading]);

  const updateAssetAPI = useAPI(updateAssetAxios(uuid), { immediate: false });

  const assetForm = useFormik({
    initialValues: AssetForm.initialValues(asset),
    onSubmit: (values) => {
      const clean = AssetForm.clean(values);
      updateAssetAPI.fetch({}, clean);
    },
    validationSchema: AssetForm.schema(asset),
    enableReinitialize: true,
  });

  const { response, loading, error } = updateAssetAPI;

  if (asset) {
    return (
      <Page id="UpdateAsset">
        <Grid>
          <PageHeader heading="Update asset" />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Update asset</CardTitle>
              </CardHeader>
              <FormikProvider value={assetForm}>
                <Form>
                  <Grid nested>
                    <Col lg={4} md={6} sm={12}>
                      <CardBody>
                        <Grid nested>
                          <Col sm={12}>
                            <AssetForm form={assetForm} setSchema={setSchema} asset={asset} />
                          </Col>
                          {error && (
                            <Col sm={12}>
                              <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
                            </Col>
                          )}
                          {response && !error && (
                            <Col sm={12}>
                              <InfoBox
                                variant={InfoBox?.Variant.SUCCESS}
                                value={
                                  <Grid nested>
                                    <Col sm={6} style={{ marginTop: "20px" }}>
                                      Successfully updated!
                                    </Col>
                                    <Col sm={6} style={{ marginTop: "16px", textAlign: "right" }}>
                                      <Button
                                        color="success"
                                        variant="text"
                                        style={{ marginLeft: "4px" }}
                                        onClick={() => navigate("/mse-asset/assets")}
                                      >
                                        Back
                                      </Button>
                                      <Button
                                        color="success"
                                        variant="text"
                                        style={{ marginLeft: "4px" }}
                                        onClick={() => navigate(`/mse-asset/assets/${response.data.uuid}`)}
                                      >
                                        View asset
                                      </Button>
                                    </Col>
                                  </Grid>
                                }
                              />
                            </Col>
                          )}
                          <Col sm={12} style={{ marginTop: "16px", textAlign: "right" }}>
                            <Button
                              color="warning"
                              disable={loading}
                              style={{ marginLeft: "4px" }}
                              onClick={() => navigate("/mse-asset/assets")}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="success"
                              disable={loading}
                              loading={loading}
                              style={{ marginLeft: "4px" }}
                              type="submit"
                            >
                              Save
                            </Button>
                          </Col>
                        </Grid>
                      </CardBody>
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (getAssetAPI.loading) return <Loading />;
  if (getAssetAPI.error)
    return (
      <Page id="UpdateAsset">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={getAssetAPI.error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default UpdateAsset;
