import { Col, Grid, CardBody, TextField, Checkbox } from "capitalroadkit";
import React, { useEffect } from "react";
import { Field } from "formik";
import clone from "lodash/cloneDeep";
import { MenuItem } from "@material-ui/core";
import * as Yup from "yup";

import { parseEnum } from "../../../../resources/formatters/Formatter";
import withNameSpace from "../../../../functions/withNameSpace";

import AssetType from "../../../../types/Assets/AssetType";
import CreateAssetType from "../../../../types/Assets/CreateAssetType";
import SecurityForm from "./SecurityForm";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";

const fields = {
  ASSET_SOURCE: "assetSource",
  PROVIDER: "provider",
  TYPE: "type",
  NAME: "name",
  NAME_OVERRIDE: "nameOverride",
  ASSET_CODE: "assetCode",
  SCALE: "scale",
  CURRENCY: "currency",
  COUNTRY_OF_ISSUE: "countryOfIssue",
  LISTED: "listed",
  SUSPENDED: "suspended",
  ACTIVE: "active",
};

const initialValues = (asset) => {
  const baseInitialValues = {
    [fields.ASSET_SOURCE]: asset?.assetSource || "SYSTEM",
    [fields.PROVIDER]: asset?.provider || "ADMIN",
    [fields.TYPE]: asset?.type || "",
    [fields.NAME]: asset?.name || "",
    [fields.NAME_OVERRIDE]: asset?.nameOverride || "",
    [fields.ASSET_CODE]: asset?.assetCode || "",
    [fields.CURRENCY]: asset?.currency || "",
    [fields.COUNTRY_OF_ISSUE]: asset?.countryOfIssue || "",
    [fields.LISTED]: asset?.listed || false,
    [fields.SUSPENDED]: asset?.suspended || false,
    [fields.ACTIVE]: asset?.active || true,
  };

  switch (asset?.type) {
    case AssetType.EQUITY:
    case AssetType.EXCHANGE_TRADED_FUND:
    case AssetType.DEBT_SECURITY:
      return { ...baseInitialValues, ...SecurityForm.initialValues(asset) };

    default:
      return baseInitialValues;
  }
};

const schema = (asset) => {
  const optionalSchema = () => {
    switch (asset?.type) {
      case AssetType.EQUITY:
      case AssetType.EXCHANGE_TRADED_FUND:
      case AssetType.DEBT_SECURITY:
        return SecurityForm.schema.fields;

      default:
        return null;
    }
  };

  return Yup.object({
    [fields.CURRENCY]: Yup.string().matches(/^[A-Z]{3}$/, "Must provide a valid currency eg. AUD"),
    [fields.COUNTRY_OF_ISSUE]: Yup.string().matches(/^[A-Z]{2}$/, "Must provide a valid country of issue eg. AU"),
    ...optionalSchema(),
  });
};

const clean = (values) => {
  let cleanValues = clone(values);

  switch (values[fields.TYPE]) {
    case AssetType.EQUITY:
    case AssetType.EXCHANGE_TRADED_FUND:
    case AssetType.DEBT_SECURITY:
      cleanValues = SecurityForm.clean(cleanValues);
      break;

    default:
      break;
  }

  for (const propName in cleanValues) {
    if (cleanValues[propName] === "") {
      delete cleanValues[propName];
    }
  }
  return cleanValues;
};

const AssetForm = ({ form, nameSpace, asset, setSchema }) => {
  let type = null;
  if (asset?.type) type = asset?.type;
  else if (form.values?.type) type = form.values[fields.TYPE];
  else type = AssetType.EQUITY;

  useEffect(() => {
    if (setSchema && form.values?.type !== "") setSchema(schema({ type: form.values?.type }));
  }, [form.values?.type]);

  useEffect(() => {
    switch (type) {
      case AssetType.EQUITY:
      case AssetType.EXCHANGE_TRADED_FUND:
      case AssetType.DEBT_SECURITY:
      case AssetType.WARRANT:
        form.setValues({ ...initialValues(asset), type, ...SecurityForm.initialValues(asset) });
        break;

      default:
        break;
    }
  }, [type]);

  const renderAssetForm = () => {
    switch (type) {
      case AssetType.EQUITY:
      case AssetType.EXCHANGE_TRADED_FUND:
      case AssetType.DEBT_SECURITY:
        return (
          <SecurityForm
            form={form}
            nameSpace={nameSpace}
            asset={asset}
            listed={form.values[withNameSpace(nameSpace, fields.LISTED)]}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Grid nested>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Base asset fields</Col>
            <Col sm={12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.TYPE)]}
                component={TextField}
                label="Type"
                fullWidth
                name={withNameSpace(nameSpace, fields.TYPE)}
                readOnly={asset?.type}
                required
                select
              >
                {Object.values(CreateAssetType).map((type) => (
                  <MenuItem value={type}>{parseEnum(type)}</MenuItem>
                ))}
              </Field>
            </Col>
            <Col sm={12}>
              <Field
                component={TextField}
                label="Name"
                fullWidth
                name={withNameSpace(nameSpace, fields.NAME)}
                readOnly={asset?.name}
                required
              />
            </Col>
            <Collapse in={asset?.name} style={{ width: "100%" }}>
              <Col sm={12}>
                <Tooltip title={preferredNameTooltip()} style={{ maxWidth: "none" }} placement="right-start">
                  <Field
                    component={TextField}
                    label="Name override"
                    fullWidth
                    name={withNameSpace(nameSpace, fields.NAME_OVERRIDE)}
                    required
                  />
                </Tooltip>
              </Col>
            </Collapse>
            <Collapse in={!form.values[withNameSpace(nameSpace, fields.LISTED)]} style={{ width: "100%" }}>
              <Col sm={12}>
                <Field
                  component={TextField}
                  label="Asset code"
                  fullWidth
                  name={withNameSpace(nameSpace, fields.ASSET_CODE)}
                  readOnly={asset?.assetCode}
                  required={!form.values[withNameSpace(nameSpace, fields.LISTED)]}
                />
              </Col>
            </Collapse>
            <Col sm={12}>
              <Field
                component={TextField}
                label="Currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.CURRENCY)}
                readOnly={asset?.currency}
                required
                select
              >
                <MenuItem value="AUD">AUD</MenuItem>
              </Field>
            </Col>
            <Col sm={12}>
              <Field
                component={TextField}
                label="Country of issue"
                fullWidth
                name={withNameSpace(nameSpace, fields.COUNTRY_OF_ISSUE)}
                required
                select
              >
                <MenuItem value="AU">AU</MenuItem>
              </Field>
            </Col>
            <Col sm={12}>
              <Field
                component={Checkbox}
                label="Listed"
                fullWidth
                name={withNameSpace(nameSpace, fields.LISTED)}
                readOnly={asset}
              />
            </Col>
            <Col sm={12}>
              <Field
                component={Checkbox}
                label="Suspended"
                fullWidth
                name={withNameSpace(nameSpace, fields.SUSPENDED)}
              />
            </Col>
            <Col sm={12}>
              <Field component={Checkbox} label="Active" fullWidth name={withNameSpace(nameSpace, fields.ACTIVE)} />
            </Col>
          </Grid>
        </CardBody>
      </Col>
      {renderAssetForm()}
    </Grid>
  );
};

const preferredNameTooltip = () =>
  "The name override will be used to override the value in the asset name field. " +
  "You can remove it and it will revert back to the original.";

AssetForm.fields = fields;
AssetForm.initialValues = initialValues;
AssetForm.schema = schema;
AssetForm.clean = clean;

export default AssetForm;
