import { CardBody, InfoBox } from "capitalroadkit";

import PaymentsTable from "../../components/PaymentsTable";
import Loading from "../../../../Loading";
import useAPI from "../../../../hooks/useAPI";
import { listDomesticPaymentsAxios } from "../../api/CashActiveEntityResource";
import PaymentStatusEnum from "../../../../types/CashService/Payment/PaymentStatusEnum";

const PaymentsTab = () => {
  const listDomesticPaymentsAPI = useAPI(listDomesticPaymentsAxios(), {
    initialParams: { status: PaymentStatusEnum.PENDING_APPROVAL },
  });

  const response = listDomesticPaymentsAPI.response;
  const loading = listDomesticPaymentsAPI.loading;
  const error = listDomesticPaymentsAPI.error;

  if (response) {
    const payments = listDomesticPaymentsAPI.response.data;

    return (
      <PaymentsTable
        data={payments}
        refreshData={() => listDomesticPaymentsAPI.fetch(listDomesticPaymentsAPI.response.config.params)}
      />
    );
  }
  if (loading) {
    return (
      <CardBody>
        <Loading />
      </CardBody>
    );
  }
  if (error) {
    return (
      <CardBody>
        <InfoBox variant={InfoBox.Variant.ERROR} value={error.response?.data} />
      </CardBody>
    );
  }
  return null;
};

export default PaymentsTab;
