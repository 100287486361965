import { Button, Card, CardHeader, CardTitle, Col, Dropdown } from "capitalroadkit";
import React from "react";
import useAPI from "../../../../../../hooks/useAPI";
import Loading from "../../../../../../Loading";
import ActivityTable from "../../../../components/ActivityTable";
import { CardActions, MenuItem } from "@material-ui/core";
import CreateActivityModal from "./CreateActivityModal";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateIncomeComponentModal from "./incomeComponents/CreateIncomeComponentModal";
import ActivityType from "../../../../../../types/corporateActions/Activities/ActivityType";
import ViewActivityModal from "./ViewActivityModal";
import { getCorporateActionActivitiesAxios } from "../../../../api/CorporateActionsResource";
import UpdateActivityModal from "./UpdateActivityModal";
import DeleteActivityModal from "./DeleteActivityModal";

const ActivityMaintenance = ({ ca, refreshApi }) => {
  const getCorporateActionActivitiesAPI = useAPI(getCorporateActionActivitiesAxios(ca.uuid), { immediate: true });

  if (getCorporateActionActivitiesAPI.response) {
    return (
      <Col sm={12}>
        <Card>
          <CardHeader>
            <CardTitle>Activities</CardTitle>
            <CardActions>
              <CreateActivityModal
                ca={ca}
                button={
                  <Button
                    color="primary"
                    disabled={!ca.qualityControlPassed}
                    variant="text"
                    style={{ cursor: "pointer" }}
                  >
                    + Activity
                  </Button>
                }
                refreshAPI={refreshApi}
              />
            </CardActions>
          </CardHeader>
          <ActivityTable
            data={getCorporateActionActivitiesAPI.response.data}
            actions={({ row: { original } }) => (
              <Dropdown
                button={
                  <FontAwesomeIcon icon={faEllipsisV} style={{ cursor: "pointer", width: "32px", margin: "5px" }} />
                }
              >
                <ViewActivityModal
                  activity={original}
                  button={<MenuItem>&#8203;View</MenuItem>}
                  refreshAPI={getCorporateActionActivitiesAPI}
                />
                <UpdateActivityModal
                  activity={original}
                  button={<MenuItem>&#8203;Update</MenuItem>}
                  refreshAPI={getCorporateActionActivitiesAPI}
                />
                {original.type === ActivityType.INCOME_ACTIVITY && (
                  <CreateIncomeComponentModal
                    caActivity={original}
                    button={<MenuItem>&#8203;Add components</MenuItem>}
                    refreshAPI={getCorporateActionActivitiesAPI}
                  />
                )}
                <DeleteActivityModal
                  activity={original}
                  button={<MenuItem>&#8203;Delete</MenuItem>}
                  refreshAPI={refreshApi}
                  getCorporateActionActivitiesAPI={getCorporateActionActivitiesAPI}
                />
              </Dropdown>
            )}
          />
        </Card>
      </Col>
    );
  }
  return <Loading />;
};

export default ActivityMaintenance;
