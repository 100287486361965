import { createContext, useContext } from "react";

import { useHealth } from "./HealthContext";

import useAPI from "../hooks/useAPI";
import { corporateActionsServiceUserRolesAxios } from "../Routes/CorporateActionsService/api/UserResource";
import { assetServiceUserRolesAxios } from "../Routes/AssetService/api/UserResource";
import { cashServiceUserRolesAxios } from "../Routes/CashService/api/UserResource";
import Loading from "../Loading";

const RoleContext = createContext(null);

const RoleProvider = ({ children }) => {
  const { assetServiceHealthCheckAPI, cashServiceHealthCheckAPI, corporateActionsServiceHealthCheckAPI } = useHealth();

  const assetServiceUserRolesAPI = useAPI(assetServiceUserRolesAxios(), {
    immediate: assetServiceHealthCheckAPI.response,
  });
  const cashServiceUserRolesAPI = useAPI(cashServiceUserRolesAxios(), {
    immediate: cashServiceHealthCheckAPI.response,
  });
  const corporateActionsServiceUserRolesAPI = useAPI(corporateActionsServiceUserRolesAxios(), {
    immediate: corporateActionsServiceHealthCheckAPI.response,
  });

  const loading =
    assetServiceUserRolesAPI.loading ||
    cashServiceHealthCheckAPI.loading ||
    corporateActionsServiceHealthCheckAPI.loading;

  if (loading) return <Loading />;
  return (
    <RoleContext.Provider
      value={{ assetServiceUserRolesAPI, cashServiceUserRolesAPI, corporateActionsServiceUserRolesAPI }}
    >
      {children}
    </RoleContext.Provider>
  );
};

const useRoles = () => useContext(RoleContext);

export default RoleProvider;
export { useRoles };
