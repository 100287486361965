import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { Tooltip } from "@material-ui/core";
import {
  Button,
  Card,
  CardActions,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Grid,
  IconButton,
  InfoBox,
  Modal,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";

import PaymentStatusEnum from "../../../../types/CashService/Payment/PaymentStatusEnum";
import PaymentStatusForm from "../../forms/PaymentStatusForm";
import useAPI from "../../../../hooks/useAPI";
import { updateDomesticPaymentAxios } from "../../api/DomesticPaymentResource";

const PaymentsTableActions = ({ payment, refreshData }) => {
  const [open, setOpen] = useState(false);

  const updateDomesticPaymentStatusAPI = useAPI(updateDomesticPaymentAxios(payment.uuid), { immediate: false });

  const paymentStatusForm = useFormik({
    initialValues: PaymentStatusForm.initialValues,
    onSubmit: (values) => updateDomesticPaymentStatusAPI.fetch({}, values),
  });

  useEffect(() => {
    const { response, loading } = updateDomesticPaymentStatusAPI;

    if (response && !loading) refreshData();
  }, [updateDomesticPaymentStatusAPI.response, updateDomesticPaymentStatusAPI.loading]);

  return (
    <>
      {(payment.status === PaymentStatusEnum.PENDING_APPROVAL ||
        payment.status === PaymentStatusEnum.ON_HOLD ||
        payment.status === PaymentStatusEnum.ERROR ||
        payment.status === PaymentStatusEnum.READY_FOR_PROCESSING) && (
        <Tooltip title="Update status">
          <div onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faPencil} />
          </div>
        </Tooltip>
      )}

      <Modal open={open}>
        <Card>
          <CardHeader>
            <CardTitle>Update status</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setOpen(false)} />
            </CardActions>
          </CardHeader>
          <CardBody>
            <FormikProvider value={paymentStatusForm}>
              <Form>
                <Grid nested>
                  <PaymentStatusForm payment={payment} />
                  <Col sm={12} style={{ textAlign: "right" }}>
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                  </Col>
                  {updateDomesticPaymentStatusAPI.response && (
                    <Col sm={12}>
                      <InfoBox variant={InfoBox.Variant.SUCCESS} value="Status updated successfully" />
                    </Col>
                  )}
                  {updateDomesticPaymentStatusAPI.error && (
                    <Col sm={12}>
                      <InfoBox
                        variant={InfoBox.Variant.ERROR}
                        value={updateDomesticPaymentStatusAPI.error.response?.data}
                      />
                    </Col>
                  )}
                </Grid>
              </Form>
            </FormikProvider>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default PaymentsTableActions;
