import { Table } from "capitalroadkit";
import React, { useMemo } from "react";
import { parseEnum, toCurrency, toDateString } from "../../../../resources/formatters/Formatter";
import { Link } from "react-router-dom";
import PaymentScheduleTableActions from "./PaymentScheduleTableActions";

const PaymentSchedulesTable = ({ data, hiddenColumns, refreshData }) => {
  const columns = useMemo(
    () => [
      {
        id: "PaymentSchedulesTable",
        columns: [
          {
            id: "actions",
            Cell: ({ cell: { original } }) => (
              <PaymentScheduleTableActions paymentSchedule={original} refreshData={refreshData} />
            ),
          },
          {
            accessor: "clientLedger.name",
            Header: "Ledger",
            Cell: ({ cell: { value }, row: { original } }) => (
              <Link
                to={"/mse-cash-active/transactions"}
                state={{
                  uuid: original.clientLedger.uuid,
                  subType: original.clientLedger.ledgerType,
                  group: original.clientLedger.group,
                }}
              >
                {value}
              </Link>
            ),
          },
          {
            accessor: "name",
            Header: "Payee",
          },
          {
            accessor: "bsbNumber",
            Header: "BSB number",
          },
          {
            accessor: "accountNumber",
            Header: "Account number",
          },
          {
            accessor: "frequency",
            Header: "Frequency",
            Cell: ({ cell: { value } }) => parseEnum(value),
          },
          {
            accessor: "startDate",
            Header: "Start date",
            Cell: ({ cell: { value } }) => toDateString(value),
          },
          {
            accessor: "amount",
            rightAlign: true,
            Header: "Amount",
            Cell: ({ cell: { value } }) => toCurrency(value),
          },
          {
            accessor: "narration",
            Header: "Narration",
          },
          {
            accessor: "status",
            Header: "Status",
            Cell: ({ cell: { value } }) => parseEnum(value),
          },
        ],
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
      sortBy={[
        {
          id: "dateReceived",
          desc: true,
        },
      ]}
    />
  );
};

export default PaymentSchedulesTable;
