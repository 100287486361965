const PaymentScheduleFrequencyEnum = Object.freeze({
  ONCE: "ONCE",
  WEEKLY: "WEEKLY",
  FORTNIGHTLY: "FORTNIGHTLY",
  MONTHLY: "MONTHLY",
  BI_MONTHLY: "BI_MONTHLY",
  QUARTERLY: "QUARTERLY",
  HALF_YEARLY: "HALF_YEARLY",
  ANNUALLY: "ANNUALLY",
});

export default PaymentScheduleFrequencyEnum;
