import axios from "axios";
import { host } from "./utilities";

export const approvePaymentScheduleAxios =
  (uuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/payment-schedule/${uuid}/approve`, data, config);
export const rejectPaymentScheduleAxios =
  (uuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/payment-schedule/${uuid}/reject`, data, config);
export const cancelPaymentScheduleAxios = (uuid) => (config) =>
  axios.delete(`${host()}/payment-schedule/${uuid}`, config);
