import { TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import * as Yup from "yup";
import MenuItem from "@material-ui/core/MenuItem";
import clone from "lodash/cloneDeep";
import { parseEnum } from "../../../../resources/formatters/Formatter";

const fields = {
  HOLDINGS: "quantityAtExDate",
  PARTICIPATION_CONDITION: "participationCondition",
};

const initialValues = {
  [fields.HOLDINGS]: "",
  [fields.PARTICIPATION_CONDITION]: "",
};

const schema = Yup.object({
  [fields.HOLDINGS]: Yup.string().required("Holdings amount required"),
});

const clean = (values) => {
  const cleanValues = clone(values);

  for (const propName in cleanValues) {
    if (cleanValues[propName] === "") {
      delete cleanValues[propName];
    }
  }

  return cleanValues;
};

const EntitlementsForm = ({ nameSpace, ca }) => (
  <>
    <Field component={TextField} label="Holdings" name={withNameSpace(nameSpace, fields.HOLDINGS)} required />
    <Field
      component={TextField}
      label="Participation condition"
      name={withNameSpace(nameSpace, fields.PARTICIPATION_CONDITION)}
      required
      select
    >
      <MenuItem key="none" value="">
        <em>All</em>
      </MenuItem>
      {ca.participationModes.map((cond) => (
        <MenuItem key={cond} value={cond}>
          {parseEnum(cond)}
        </MenuItem>
      ))}
    </Field>
  </>
);

EntitlementsForm.fields = fields;
EntitlementsForm.clean = clean;
EntitlementsForm.initialValues = initialValues;
EntitlementsForm.schema = schema;

export default EntitlementsForm;
