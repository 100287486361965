import { Button, Card, CardBody, CardHeader, CardTitle, Col, Grid, InfoBox, Page, PageHeader } from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect } from "react";

import useAPI from "../../../../../../../../../hooks/useAPI";
import { createPayeeAxios } from "../../../../../../../api/ClientLedgerResource";
import PayeeForm from "../forms/PayeeForm";
import { useClientLedger } from "../../../context/ClientLedgerContext";

const NewPayee = () => {
  const clientLedger = useClientLedger();

  const createPayeeAPI = useAPI(createPayeeAxios(clientLedger.uuid), { immediate: false });

  const payeeForm = useFormik({
    initialValues: PayeeForm.initialValues,
    onSubmit: (values) => createPayeeAPI.fetch({}, values),
  });

  useEffect(() => {
    const { response, loading } = createPayeeAPI;

    if (response && !loading) payeeForm.resetForm();
  }, [createPayeeAPI.response, createPayeeAPI.loading]);

  return (
    <Page>
      <Grid>
        <PageHeader heading="New payee" subHeading={clientLedger.name} />
        <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>Create new payee</CardTitle>
            </CardHeader>
            <CardBody>
              <FormikProvider value={payeeForm}>
                <Form>
                  <Grid nested>
                    <PayeeForm form={payeeForm} values={payeeForm.values} />
                    <Col sm={12} style={{ textAlign: "right" }}>
                      <div style={{ maxWidth: "512px", width: "100%" }}>
                        <Button color="primary" loading={createPayeeAPI.loading} type="submit">
                          Submit
                        </Button>
                      </div>
                    </Col>
                    {createPayeeAPI.response && (
                      <Col sm={12}>
                        <div style={{ maxWidth: "512px", width: "100%" }}>
                          <InfoBox variant={InfoBox.Variant.SUCCESS} value="Payee created successfully" />
                        </div>
                      </Col>
                    )}
                    {createPayeeAPI.error && (
                      <Col sm={12}>
                        <div style={{ maxWidth: "512px", width: "100%" }}>
                          <InfoBox variant={InfoBox.Variant.ERROR} value={createPayeeAPI.error.response?.data} />
                        </div>
                      </Col>
                    )}
                  </Grid>
                </Form>
              </FormikProvider>
            </CardBody>
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

export default NewPayee;
