import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "capitalroadkit";
import { Field } from "formik";
import moment from "moment";
import React from "react";
import clone from "lodash/cloneDeep";
import { parseEnum, toAPIDateString } from "../../../resources/formatters/Formatter";
import TransactionType from "../../../types/CashService/Transactions/TransactionType";
import PostingStatus from "../../../types/CashService/Transactions/PostingStatus";
import GroupType from "../../../types/CashService/GroupType";
import LedgerSubType from "../../../types/CashService/LedgerSubType";

const fields = {
  LEDGER_UUID: "ledgerUuid",
  SUB_TYPE: "subType",
  FILTER: "filter",
  GROUP: "group",
  POSTING_STATUS: "postingStatus",
  TRANSACTION_TYPE: "transactionType",
  FROM_DATE: "fromDate",
  TO_DATE: "toDate",
};

const initialValues = (uuid, subType, group) => ({
  [fields.LEDGER_UUID]: uuid || "",
  [fields.SUB_TYPE]: subType || LedgerSubType.CLI,
  [fields.FILTER]: "",
  [fields.GROUP]: group || "",
  [fields.TRANSACTION_TYPE]: "",
  [fields.POSTING_STATUS]: uuid ? "" : PostingStatus.PENDING,
  [fields.FROM_DATE]: "",
  [fields.TO_DATE]: moment().format("DD/MM/YYYY"),
});

const clean = (values) => {
  const cleanValues = clone(values);

  if (cleanValues[fields.FROM_DATE]) cleanValues[fields.FROM_DATE] = toAPIDateString(cleanValues[fields.FROM_DATE]);
  if (cleanValues[fields.TO_DATE]) cleanValues[fields.TO_DATE] = toAPIDateString(cleanValues[fields.TO_DATE]);

  for (const propName in cleanValues) if (cleanValues[propName] === "") delete cleanValues[propName];

  return cleanValues;
};

const LedgerTransactionSearchForm = ({ ledger }) => (
  <>
    {!ledger && (
      <>
        <Field component={TextField} label="Filter" name={fields.FILTER} />
        <Field component={TextField} label="Group" name={fields.GROUP} select>
          <MenuItem value="">All</MenuItem>
          <MenuItem value={GroupType.RETAIL}>Retail</MenuItem>
          <MenuItem value={GroupType.NON_RETAIL}>Non-retail</MenuItem>
        </Field>
      </>
    )}
    <Field component={TextField} label="Transaction type" name={fields.TRANSACTION_TYPE} select>
      <MenuItem value="">
        <em>All</em>
      </MenuItem>
      {Object.entries(TransactionType).map((type) => (
        <MenuItem value={type[0]}>{type[1]}</MenuItem>
      ))}
    </Field>
    <Field component={TextField} label="Posting status" name={fields.POSTING_STATUS} select>
      <MenuItem value="">
        <em>All</em>
      </MenuItem>
      {Object.values(PostingStatus).map((status) => (
        <MenuItem value={status}>{parseEnum(status)}</MenuItem>
      ))}
    </Field>
    <Field component={TextField} label="From" mask="99/99/9999" name={fields.FROM_DATE} required />
    <Field component={TextField} label="To" mask="99/99/9999" name={fields.TO_DATE} required />
  </>
);

LedgerTransactionSearchForm.fields = fields;
LedgerTransactionSearchForm.clean = clean;
LedgerTransactionSearchForm.initialValues = initialValues;

export default LedgerTransactionSearchForm;
