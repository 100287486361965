import { CardBody, useTheme, Button, InfoBox } from "capitalroadkit";
import DividendDetails from "./subTypes/DividendDetails";
import Chip from "@material-ui/core/Chip";
import ConsolidationDetails from "./subTypes/ConsolidationDetails";
import EntitlementIssueDetails from "./subTypes/EntitlementIssueDetails";
import TakeoverDetails from "./subTypes/TakeoverDetails";
import BuyBackDetails from "./subTypes/BuyBackDetails";
import BonusDetails from "./subTypes/BonusDetails";
import LocalCodeChangeDetails from "./subTypes/LocalCodeChangeDetails";
import SecurityNameChangeDetails from "./subTypes/SecurityNameChangeDetails";
import { useNavigate } from "react-router-dom";
import DemergerDetails from "./subTypes/DemergerDetails";
import CompanyMeetingDetails from "./subTypes/CompanyMeetingDetails";
import InterestDetails from "./subTypes/InterestDetails";
import CorporateActionType from "../../../../../types/corporateActions/CorporateActionType";
import CorporateActionSource from "../../../../../types/corporateActions/CorporateActionSource";
import { parseEnum, toDateString } from "../../../../../resources/formatters/Formatter";
import ReturnOfCapitalDetails from "./subTypes/ReturnOfCapitalDetails";

const CorporateActionDetails = ({ ca, secondaryCa }) => {
  const navigate = useNavigate();
  const [theme] = useTheme();

  const renderTypeDetails = () => {
    let type = null;
    if (ca?.type) type = ca?.type.replace("_CORPORATE_ACTION", "");

    switch (type) {
      case CorporateActionType.DIVIDEND:
        return <DividendDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.CONSOLIDATION:
        return <ConsolidationDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.ENTITLEMENT_ISSUE:
        return <EntitlementIssueDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.TAKEOVER:
        return <TakeoverDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.BUY_BACK:
        return <BuyBackDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.BONUS:
        return <BonusDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.SECURITY_NAME_CHANGE:
        return <SecurityNameChangeDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.LOCAL_CODE_CHANGE:
        return <LocalCodeChangeDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.DEMERGER:
        return <DemergerDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.COMPANY_MEETING:
        return <CompanyMeetingDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.INTEREST:
        return <InterestDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;
      case CorporateActionType.RETURN_OF_CAPITAL:
        return <ReturnOfCapitalDetails ca={ca} secondaryCa={secondaryCa} theme={theme} />;

      default:
        return null;
    }
  };

  return (
    <CardBody>
      <table
        style={{
          borderCollapse: "collapse",
          tableLayout: "fixed",
          textAlign: "left",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th></th>
            {ca?.source === CorporateActionSource.ANNOUNCEMENT ? (
              <>
                <th>Morningstar</th>
                <th>
                  {secondaryCa && (
                    <Button
                      color="primary"
                      variant="text"
                      style={{ marginLeft: "-16px", fontSize: "16px" }}
                      onClick={() => navigate(`/mse-corp-action/corporate-actions/${secondaryCa?.uuid}`)}
                    >
                      User
                    </Button>
                  )}
                </th>
              </>
            ) : (
              <>
                <th>User</th>
                <th>
                  {secondaryCa && (
                    <Button
                      color="primary"
                      variant="text"
                      style={{ marginLeft: "-8px", fontSize: "16px" }}
                      onClick={() => navigate(`/mse-corp-action/corporate-actions/${secondaryCa?.uuid}`)}
                    >
                      Morningstar
                    </Button>
                  )}
                </th>
              </>
            )}
          </tr>
          {(ca?.overrideId || secondaryCa?.overrideId) && (
            <tr>
              <th></th>
              <th>
                {ca.overrideId && (
                  <InfoBox
                    nested
                    style={{ margin: "-16px 0 -24px -32px" }}
                    variant={InfoBox?.Variant.WARNING}
                    value="This corporate action has been overridden."
                  />
                )}
              </th>
              <th>
                {secondaryCa?.overrideId && (
                  <InfoBox
                    nested
                    style={{ margin: "-8px 0 -24px -32px" }}
                    variant={InfoBox?.Variant.WARNING}
                    value="This corporate action has been overridden."
                  />
                )}
              </th>
            </tr>
          )}
        </thead>
        <br />
        <tbody>
          <tr style={{ backgroundColor: theme.palette.background.main }}>
            <th>Type</th>
            <td>{parseEnum(ca.type)}</td>
            <td>{parseEnum(secondaryCa?.type)}</td>
          </tr>
          <tr>
            <th>Asset code</th>
            <td>{ca.assetCode}</td>
            <td>{secondaryCa?.assetCode}</td>
          </tr>
          <tr style={{ backgroundColor: theme.palette.background.main }}>
            <th>Description</th>
            <td>{ca.description}</td>
            <td>{secondaryCa?.description}</td>
          </tr>
          <tr>
            <th>Date of announcement</th>
            <td>{toDateString(ca.dateOfAnnouncement)}</td>
            <td>{toDateString(secondaryCa?.dateOfAnnouncement)}</td>
          </tr>
          <tr style={{ backgroundColor: theme.palette.background.main }}>
            <th>Date of revision</th>
            <td>{toDateString(ca.dateOfRevision)}</td>
            <td>{toDateString(secondaryCa?.dateOfRevision)}</td>
          </tr>
          <tr>
            <th>Processing date</th>
            <td>{toDateString(ca.processingDate)}</td>
            <td>{toDateString(secondaryCa?.processingDate)}</td>
          </tr>
          <tr style={{ backgroundColor: theme.palette.background.main }}>
            <th>Ex date</th>
            <td>{toDateString(ca.exDate)}</td>
            <td>{toDateString(secondaryCa?.exDate)}</td>
          </tr>
          <tr>
            <th>Record date</th>
            <td>{toDateString(ca.recordDate)}</td>
            <td>{toDateString(secondaryCa?.recordDate)}</td>
          </tr>
          <tr style={{ backgroundColor: theme.palette.background.main }}>
            <th>Payment date</th>
            <td>{toDateString(ca.paymentDate)}</td>
            <td>{toDateString(secondaryCa?.paymentDate)}</td>
          </tr>
          <tr>
            <th>Application closing date</th>
            <td>{toDateString(ca.applicationClosingDate)}</td>
            <td>{toDateString(secondaryCa?.applicationClosingDate)}</td>
          </tr>
          <tr style={{ backgroundColor: theme.palette.background.main }}>
            <th>Source</th>
            <td>{parseEnum(ca.source)}</td>
            <td>{parseEnum(secondaryCa?.source)}</td>
          </tr>
          <br />
          {renderTypeDetails()}
          <br />
          {ca?.tags?.length > 0 && (
            <>
              <tr>
                <th>Tags</th>
              </tr>
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "left",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                {ca.tags.map((tag) => (
                  <Chip label={tag} />
                ))}
              </div>
            </>
          )}
        </tbody>
      </table>
    </CardBody>
  );
};

export default CorporateActionDetails;
