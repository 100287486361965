const AnnouncementType = Object.freeze({
  ANNOUNCEMENT: "ANNOUNCEMENT",
  BONUS_ANNOUNCEMENT: "BONUS_ANNOUNCEMENT",
  BUYBACK_ANNOUNCEMENT: "BUYBACK_ANNOUNCEMENT",
  CAPITAL_REDUCTION_ANNOUNCEMENT: "CAPITAL_REDUCTION_ANNOUNCEMENT",
  COMPANY_MEETING_ANNOUNCEMENT: "COMPANY_MEETING_ANNOUNCEMENT",
  CONSOLIDATION_ANNOUNCEMENT: "CONSOLIDATION_ANNOUNCEMENT",
  DE_MERGER_ANNOUNCEMENT: "DE_MERGER_ANNOUNCEMENT",
  DISTRIBUTION_ANNOUNCEMENT: "DISTRIBUTION_ANNOUNCEMENT",
  DIVIDEND_ANNOUNCEMENT: "DIVIDEND_ANNOUNCEMENT",
  ENTITLEMENT_ISSUE_ANNOUNCEMENT: "ENTITLEMENT_ISSUE_ANNOUNCEMENT",
  FINANCIAL_YEAR_CHANGE_ANNOUNCEMENT: "FINANCIAL_YEAR_CHANGE_ANNOUNCEMENT",
  GENERAL_ANNOUNCEMENT: "GENERAL_ANNOUNCEMENT",
  INCORPORATION_CHANGE_ANNOUNCEMENT: "INCORPORATION_CHANGE_ANNOUNCEMENT",
  INTERNATIONAL_CODE_CHANGE_ANNOUNCEMENT: "INTERNATIONAL_CODE_CHANGE_ANNOUNCEMENT",
  ISSUER_NAME_CHANGE_ANNOUNCEMENT: "ISSUER_NAME_CHANGE_ANNOUNCEMENT",
  LISTING_STATUS_ANNOUNCEMENT: "LISTING_STATUS_ANNOUNCEMENT",
  LOCAL_CODE_CHANGE_ANNOUNCEMENT: "LOCAL_CODE_CHANGE_ANNOUNCEMENT",
  LOT_CHANGE_ANNOUNCEMENT: "LOT_CHANGE_ANNOUNCEMENT",
  MARKET_CHANGE_ANNOUNCEMENT: "MARKET_CHANGE_ANNOUNCEMENT",
  NEW_LISTING_ANNOUNCEMENT: "NEW_LISTING_ANNOUNCEMENT",
  ODD_LOT_OFFER_ANNOUNCEMENT: "ODD_LOT_OFFER_ANNOUNCEMENT",
  RETURN_OF_CAPITAL_ANNOUNCEMENT: "RETURN_OF_CAPITAL_ANNOUNCEMENT",
  SECURITY_NAME_CHANGE_ANNOUNCEMENT: "SECURITY_NAME_CHANGE_ANNOUNCEMENT",
  SECURITY_RE_CLASSIFICATION_ANNOUNCEMENT: "SECURITY_RE_CLASSIFICATION_ANNOUNCEMENT",
  SECURITY_SWAP_ANNOUNCEMENT: "SECURITY_SWAP_ANNOUNCEMENT",
  SUB_DIVISION_ANNOUNCEMENT: "SUB_DIVISION_ANNOUNCEMENT",
  TAKEOVER_ANNOUNCEMENT: "TAKEOVER_ANNOUNCEMENT",
});

export default AnnouncementType;
