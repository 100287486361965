import React from "react";
import { parseTinExemptionEnum } from "../../../../types/CashService/Accounts/TinExemptionType";

const TaxCountryReview = ({ taxCountry, index, findCountryForCode }) => (
  <>
    <br />
    <tr>
      <th>Tax country {index + 1}</th>
    </tr>
    <tr>
      <th>Country:</th>
      <td>{findCountryForCode(taxCountry.country)}</td>
    </tr>
    {taxCountry.tin && (
      <tr>
        <th>TIN:</th>
        <td>{taxCountry.tin}</td>
      </tr>
    )}
    {taxCountry.noTinReason && (
      <tr>
        <th>Exemption:</th>
        <td>{parseTinExemptionEnum(taxCountry.noTinReason)}</td>
      </tr>
    )}
  </>
);

export default TaxCountryReview;
