import { TextField, useSessionStorage } from "capitalroadkit";
import { Field } from "formik";
import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";

import { parseEnum } from "../../../resources/formatters/Formatter";
import useAPI from "../../../hooks/useAPI";
import Loading from "../../../Loading";
import { getAssetTypesListAxios } from "../api/AssetResource";

const fields = {
  TYPE: "asset-type",
  FILTER: "filter",
};

const initialValues = {
  [fields.TYPE]: "",
  [fields.FILTER]: "",
};

const AssetSearchForm = () => {
  const [assetTypes, setAssetTypes] = useSessionStorage("AssetTypes", []);

  const getAssetTypesListAPI = useAPI(getAssetTypesListAxios(), { immediate: assetTypes.length === 0 });

  useEffect(() => {
    if (getAssetTypesListAPI.response) setAssetTypes(getAssetTypesListAPI.response.data);
  }, [getAssetTypesListAPI.response]);

  if (getAssetTypesListAPI.loading) return <Loading />;
  return (
    <>
      <Field component={TextField} label="Type" name={fields.TYPE} select>
        <MenuItem value="">All</MenuItem>
        {assetTypes.map((type) => (
          <MenuItem value={type.key}>{parseEnum(type.value)}</MenuItem>
        ))}
      </Field>
      <Field component={TextField} label="Filter" name={fields.FILTER} />
    </>
  );
};

AssetSearchForm.fields = fields;
AssetSearchForm.initialValues = initialValues;

export default AssetSearchForm;
