import { CardBody, Col, Grid, TextField } from "capitalroadkit";
import { Field } from "formik";
import withNameSpace from "../../../../functions/withNameSpace";
import * as Yup from "yup";

const fields = {
  FRACTION_ROUNDING: "fractionRounding",
  RATIO_OLD: "ratioOld",
  RATIO_NEW: "ratioNew",
  ACTION_FLAG: "actionFlag",
  PRIORITY: "priority",
};

const initialValues = (ca) => ({
  [fields.FRACTION_ROUNDING]: ca?.fractionRounding || "",
  [fields.RATIO_OLD]: ca?.ratioOld || "",
  [fields.RATIO_NEW]: ca?.ratioNew || "",
  [fields.ACTION_FLAG]: ca?.actionFlag || "",
  [fields.PRIORITY]: ca?.priority || "",
});

const clean = (values) => {
  return values;
};

const schema = Yup.object({});

const BonusForm = ({ nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <Col sm={12}>
      <CardBody nested>
        <Grid nested>
          <Col sm={12}>Bonus fields</Col>
          <Col sm={overriddenCa ? 8 : 12}>
            <Field
              component={TextField}
              label="Fraction rounding"
              fullWidth
              name={withNameSpace(nameSpace, fields.FRACTION_ROUNDING)}
              readOnly={updateAnnouncement}
            />
          </Col>
          {overriddenCa && (
            <Col sm={4} style={{ marginTop: "35px" }}>
              {overriddenCa.fractionRounding}
            </Col>
          )}
          <Col sm={overriddenCa ? 8 : 12}>
            <Field
              component={TextField}
              label="Ratio old"
              fullWidth
              name={withNameSpace(nameSpace, fields.RATIO_OLD)}
              readOnly={updateAnnouncement}
            />
          </Col>
          {overriddenCa && (
            <Col sm={4} style={{ marginTop: "35px" }}>
              {overriddenCa.ratioOld}
            </Col>
          )}
          <Col sm={overriddenCa ? 8 : 12}>
            <Field
              component={TextField}
              label="Ratio new"
              fullWidth
              name={withNameSpace(nameSpace, fields.RATIO_NEW)}
              readOnly={updateAnnouncement}
            />
          </Col>
          {overriddenCa && (
            <Col sm={4} style={{ marginTop: "35px" }}>
              {overriddenCa.ratioNew}
            </Col>
          )}
          <Col sm={overriddenCa ? 8 : 12}>
            <Field
              component={TextField}
              label="Action flag"
              fullWidth
              name={withNameSpace(nameSpace, fields.ACTION_FLAG)}
              readOnly={updateAnnouncement}
            />
          </Col>
          {overriddenCa && (
            <Col sm={4} style={{ marginTop: "35px" }}>
              {overriddenCa.actionFlag}
            </Col>
          )}
          <Col sm={overriddenCa ? 8 : 12}>
            <Field
              component={TextField}
              label="Priority"
              fullWidth
              name={withNameSpace(nameSpace, fields.PRIORITY)}
              readOnly={updateAnnouncement}
            />
          </Col>
          {overriddenCa && (
            <Col sm={4} style={{ marginTop: "35px" }}>
              {overriddenCa.priority}
            </Col>
          )}
        </Grid>
      </CardBody>
    </Col>
  );
};

BonusForm.fields = fields;
BonusForm.initialValues = initialValues;
BonusForm.schema = schema;
BonusForm.clean = clean;

export default BonusForm;
