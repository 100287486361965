import {
  Button,
  Card,
  CardBody,
  CardActions,
  CardHeader,
  CardTitle,
  Col,
  Grid,
  InfoBox,
  Page,
  PageHeader,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useClientLedger } from "../../../context/ClientLedgerContext";
import useAPI from "../../../../../../../../../hooks/useAPI";
import { submitPaymentScheduleAxios } from "../../../../../../../api/ClientLedgerResource";
import PaymentScheduleForm from "../forms/PaymentScheduleForm";

const ClientLedgerNewPaymentSchedule = () => {
  const clientLedger = useClientLedger();

  const submitPaymentScheduleAPI = useAPI(submitPaymentScheduleAxios(clientLedger.uuid), { immediate: false });

  const paymentScheduleForm = useFormik({
    initialValues: PaymentScheduleForm.initialValues,
    onSubmit: (values) => submitPaymentScheduleAPI.fetch({}, PaymentScheduleForm.clean(values)),
  });

  useEffect(() => {
    const { response, loading } = submitPaymentScheduleAPI;

    if (response && !loading) paymentScheduleForm.resetForm();
  }, [submitPaymentScheduleAPI.response, submitPaymentScheduleAPI.loading]);

  return (
    <Page>
      <Grid>
        <PageHeader heading="New scheduled payment" subHeading={clientLedger.name} />
        <Col sm={12}>
          <Card>
            <CardHeader>
              <CardTitle>New scheduled payment</CardTitle>
              <CardActions>
                <Link to={`/mse-cash-active/ledger-balance/${clientLedger.uuid}/payment-schedules`}>
                  <Button color="primary" variant="text">
                    View schedule payments
                  </Button>
                </Link>
              </CardActions>
            </CardHeader>
            <CardBody>
              <FormikProvider value={paymentScheduleForm}>
                <Form>
                  <Grid nested>
                    <PaymentScheduleForm form={paymentScheduleForm} />
                    <Col sm={12} style={{ textAlign: "right" }}>
                      <div style={{ maxWidth: "512px", width: "100%" }}>
                        <Button color="primary" loading={submitPaymentScheduleAPI.loading} type="submit">
                          Submit
                        </Button>
                      </div>
                    </Col>
                    {submitPaymentScheduleAPI.response && (
                      <Col sm={12}>
                        <div style={{ maxWidth: "512px", width: "100%" }}>
                          <InfoBox variant={InfoBox.Variant.SUCCESS} value="Scheduled payment created successfully" />
                        </div>
                      </Col>
                    )}
                    {submitPaymentScheduleAPI.error && (
                      <Col sm={12}>
                        <div style={{ maxWidth: "512px", width: "100%" }}>
                          <InfoBox
                            variant={InfoBox.Variant.ERROR}
                            value={submitPaymentScheduleAPI.error.response?.data}
                          />
                        </div>
                      </Col>
                    )}
                  </Grid>
                </Form>
              </FormikProvider>
            </CardBody>
          </Card>
        </Col>
      </Grid>
    </Page>
  );
};

export default ClientLedgerNewPaymentSchedule;
