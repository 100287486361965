import axios from "axios";
import { host } from "./utilities";

export const updateCorporateActionActivityAxios =
  (aUuid) =>
  (config, data = {}) =>
    axios.put(`${host()}/activity/${aUuid}`, data, config);
export const deleteCorporateActionActivityAxios = (aUuid) => (config) =>
  axios.delete(`${host()}/activity/${aUuid}`, config);

export const createActivityIncomeComponentAxios =
  (activityUuid) =>
  (config, data = {}) =>
    axios.post(`${host()}/activity/${activityUuid}/income-component`, data, config);
