import { CardBody, Col, Grid, TextField } from "capitalroadkit";
import React from "react";
import { Field } from "formik";
import * as Yup from "yup";

import withNameSpace from "../../../../functions/withNameSpace";
import clone from "lodash/cloneDeep";
import { parseEnum, toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import { MenuItem } from "@material-ui/core";
import EntitlementIssueSubType from "../../../../types/corporateActions/EntitlementIssueSubType";
import AssetDiscriminator from "../../../../types/corporateActions/AssetDiscriminator";

const fields = {
  OVER_SUBSCRIPTION: "overSubscription",
  PRIMARY_INSTRUMENT_CLOSE_PRICE: "primaryInstrumentClosePrice",
  SUBSCRIPTION_PRICE_FROM_ISSUE_PRICE: "subscriptionPriceFromIssuePrice",
  START_SUBSCRIPTION_DATE: "startSubscriptionDate",
  END_SUBSCRIPTION_DATE: "endSubscriptionDate",
  FRACTION_ROUNDING: "fractionRounding",
  RATIO_OLD: "ratioOld",
  RATIO_NEW: "ratioNew",

  //rights non renounceable fields
  START_TRADING_DATE: "startTradingDate",
  END_TRADING_DATE: "endTradingDate",

  //additional information
  SUB_TYPE: "subType",
};

const initialValues = (ca) => ({
  [fields.OVER_SUBSCRIPTION]: ca?.overSubscription || "",
  [fields.PRIMARY_INSTRUMENT_CLOSE_PRICE]: ca?.primaryInstrumentClosePrice || "",
  [fields.SUBSCRIPTION_PRICE_FROM_ISSUE_PRICE]: ca?.subscriptionPriceFromIssuePrice || "",
  [fields.START_SUBSCRIPTION_DATE]: toDateString(ca?.startSubscriptionDate) || "",
  [fields.END_SUBSCRIPTION_DATE]: toDateString(ca?.endSubscriptionDate) || "",
  [fields.START_TRADING_DATE]: toDateString(ca?.startTradingDate) || "",
  [fields.END_TRADING_DATE]: toDateString(ca?.endTradingDate) || "",
  [fields.FRACTION_ROUNDING]: ca?.fractionRounding || "",
  [fields.RATIO_OLD]: ca?.ratioOld || "",
  [fields.RATIO_NEW]: ca?.ratioNew || "",
  [fields.SUB_TYPE]: ca?.subType || "",
});

const clean = (values) => {
  const clean = clone(values);

  if (clean[fields.START_SUBSCRIPTION_DATE])
    clean[fields.START_SUBSCRIPTION_DATE] = toAPIDateString(clean[fields.START_SUBSCRIPTION_DATE]);
  if (clean[fields.END_SUBSCRIPTION_DATE])
    clean[fields.END_SUBSCRIPTION_DATE] = toAPIDateString(clean[fields.END_SUBSCRIPTION_DATE]);

  if (clean[fields.SUB_TYPE] !== EntitlementIssueSubType.RIGHTS_ISSUE_NON_RENOUNCEABLE) {
    if (clean[fields.START_TRADING_DATE])
      clean[fields.START_TRADING_DATE] = toAPIDateString(clean[fields.START_TRADING_DATE]);
    if (clean[fields.END_TRADING_DATE])
      clean[fields.END_TRADING_DATE] = toAPIDateString(clean[fields.END_TRADING_DATE]);
  }

  if (clean[fields.SUB_TYPE] !== EntitlementIssueSubType.RIGHTS_ISSUE_NON_RENOUNCEABLE) {
    delete clean[fields.START_TRADING_DATE];
    delete clean[fields.END_TRADING_DATE];
  }

  return clean;
};

const schema = Yup.object({});

const EntitlementIssueForm = ({ form, nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Entitlement issue fields</Col>
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Over subscription"
                fullWidth
                name={withNameSpace(nameSpace, fields.OVER_SUBSCRIPTION)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.overSubscription}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Primary instrument close price"
                fullWidth
                name={withNameSpace(nameSpace, fields.PRIMARY_INSTRUMENT_CLOSE_PRICE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.primaryInstrumentClosePrice}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Subscription price from issue price"
                fullWidth
                name={withNameSpace(nameSpace, fields.SUBSCRIPTION_PRICE_FROM_ISSUE_PRICE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.subscriptionPriceFromIssuePrice}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Start subscription date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.START_SUBSCRIPTION_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.startSubscriptionDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="End subscription date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.END_SUBSCRIPTION_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {form.values[fields.SUB_TYPE] === EntitlementIssueSubType.RIGHTS_ISSUE_NON_RENOUNCEABLE && (
              <>
                <Col sm={overriddenCa ? 8 : 12}>
                  <Field
                    component={TextField}
                    label="Start trading date"
                    fullWidth
                    mask="99/99/9999"
                    name={withNameSpace(nameSpace, fields.START_TRADING_DATE)}
                    readOnly={updateAnnouncement}
                  />
                </Col>
                {overriddenCa && (
                  <Col sm={4} style={{ marginTop: "35px" }}>
                    {toDateString(overriddenCa.startTradingDate)}
                  </Col>
                )}
                <Col sm={overriddenCa ? 8 : 12}>
                  <Field
                    component={TextField}
                    label="End trading date"
                    fullWidth
                    mask="99/99/9999"
                    name={withNameSpace(nameSpace, fields.END_TRADING_DATE)}
                    readOnly={updateAnnouncement}
                  />
                </Col>
                {overriddenCa && (
                  <Col sm={4} style={{ marginTop: "35px" }}>
                    {toDateString(overriddenCa.endTradingDate)}
                  </Col>
                )}
              </>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Fraction rounding"
                fullWidth
                name={withNameSpace(nameSpace, fields.FRACTION_ROUNDING)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.fractionRounding}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Ratio old"
                fullWidth
                name={withNameSpace(nameSpace, fields.RATIO_OLD)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.ratioOld}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Ratio new"
                fullWidth
                name={withNameSpace(nameSpace, fields.RATIO_NEW)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.ratioNew}
              </Col>
            )}
          </Grid>
        </CardBody>
      </Col>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Additional information fields</Col>
            <Col sm={12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.SUB_TYPE)]}
                component={TextField}
                label="Sub type"
                fullWidth
                name={withNameSpace(nameSpace, fields.SUB_TYPE)}
                required
                select
              >
                {Object.values(EntitlementIssueSubType).map((type) => (
                  <MenuItem value={type}>{parseEnum(type)}</MenuItem>
                ))}
              </Field>
            </Col>
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

EntitlementIssueForm.fields = fields;
EntitlementIssueForm.initialValues = initialValues;
EntitlementIssueForm.schema = schema;
EntitlementIssueForm.clean = clean;

export default EntitlementIssueForm;
