import { Card, CardBody, Modal, CardHeader, CardTitle, IconButton, CardActions, useTheme } from "capitalroadkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useState } from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

const QualityControlNotesModal = ({ corporateAction }) => {
  const [theme] = useTheme();
  const [open, setOpen] = useState(false);

  if (!corporateAction?.qualityControlPassed) {
    return (
      <>
        <Tooltip title="Quality control notes">
          <div style={{ display: "inline-block", marginLeft: "1rem" }}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              onClick={() => setOpen(true)}
              style={{
                cursor: "pointer",
                color: theme.palette.danger.main,
              }}
            />
          </div>
        </Tooltip>
        <Modal open={open}>
          {open && (
            <Card>
              <CardHeader>
                <CardTitle>Quality control notes</CardTitle>
                <CardActions>
                  <IconButton icon={faTimes} onClick={() => setOpen(false)} />
                </CardActions>
              </CardHeader>
              <CardBody>
                {corporateAction.qualityControlNotes.length > 0 ? (
                  <ul style={{ marginLeft: "1rem" }}>
                    {corporateAction.qualityControlNotes.map((note) => (
                      <li style={{ color: theme.palette.danger.main }}>{note.value}</li>
                    ))}
                  </ul>
                ) : (
                  "No notes"
                )}
              </CardBody>
            </Card>
          )}
        </Modal>
      </>
    );
  }
  return null;
};

export default QualityControlNotesModal;
