import MenuItem from "@material-ui/core/MenuItem";
import { Col, TextField } from "capitalroadkit";
import { Field } from "formik";

import PaymentStatusEnum from "../../../types/CashService/Payment/PaymentStatusEnum";

const fields = {
  STATUS: "status",
};

const initialValues = () => ({
  [fields.STATUS]: "",
});

const PaymentStatusForm = ({ payment }) => (
  <Col sm={12}>
    <Field component={TextField} label="Status" name={fields.STATUS} required select>
      {(payment.status === PaymentStatusEnum.PENDING_APPROVAL ||
        payment.status === PaymentStatusEnum.ON_HOLD ||
        payment.status === PaymentStatusEnum.ERROR) && (
        <MenuItem value={PaymentStatusEnum.READY_FOR_PROCESSING}>Ready for processing</MenuItem>
      )}
      {(payment.status === PaymentStatusEnum.PENDING_APPROVAL ||
        payment.status === PaymentStatusEnum.READY_FOR_PROCESSING ||
        payment.status === PaymentStatusEnum.ERROR) && <MenuItem value={PaymentStatusEnum.ON_HOLD}>On hold</MenuItem>}
      {payment.status === PaymentStatusEnum.PENDING_APPROVAL && (
        <MenuItem value={PaymentStatusEnum.REJECTED}>Rejected</MenuItem>
      )}
    </Field>
  </Col>
);

PaymentStatusForm.fields = fields;
PaymentStatusForm.initialValues = initialValues;

export default PaymentStatusForm;
