import Tooltip from "@material-ui/core/Tooltip";
import {
  InfoBox,
  Card,
  CardHeader,
  CardBody,
  Col,
  Grid,
  Page,
  PageHeader,
  CardTitle,
  CardActions,
  Button,
} from "capitalroadkit";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import EntitlementsModal from "./entitlements/EntitlementsModal";
import CorporateActionNotes from "./CorporateActionNotes";
import CorporateActionDetails from "./CorporateActionDetails";
import TagsModal from "./TagsModal";
import NotesModal from "./NotesModal";
import {
  findOverriddenForCorporateActionWithUuidAxios,
  findOverrideForCorporateActionWithUuidAxios,
  getCorporateActionAxios,
} from "../../../api/CorporateActionsResource";
import useAPI from "../../../../../hooks/useAPI";
import Loading from "../../../../../Loading";
import CorporateActionSource from "../../../../../types/corporateActions/CorporateActionSource";
import ActivityMaintenance from "./activities/ActivityMaintenance";

const CorporateAction = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [corporateAction, setCorporateAction] = useState(null);
  const [secondaryCorporateAction, setSecondaryCorporateAction] = useState(null);

  const getCorporateActionAPI = useAPI(getCorporateActionAxios(uuid), { immediate: false });

  useEffect(() => {
    if (uuid) getCorporateActionAPI.fetch();
  }, [uuid]);

  useEffect(() => {
    const { response, loading, error } = getCorporateActionAPI;

    if (response && !loading && !error) {
      setCorporateAction(response.data);

      if (response.data.overriddenId) findOverriddenAPI.fetch();
      if (response.data.overrideId) findOverrideAPI.fetch();
    }
  }, [getCorporateActionAPI.response, getCorporateActionAPI.loading, getCorporateActionAPI.error]);

  const findOverrideAPI = useAPI(findOverrideForCorporateActionWithUuidAxios(uuid), { immediate: false });

  useEffect(() => {
    const { response, loading, error } = findOverrideAPI;

    if (response && !loading && !error) setSecondaryCorporateAction(response.data);
  }, [findOverrideAPI.response, findOverrideAPI.loading, findOverrideAPI.error]);

  const findOverriddenAPI = useAPI(findOverriddenForCorporateActionWithUuidAxios(uuid), { immediate: false });

  useEffect(() => {
    const { response, loading, error } = findOverriddenAPI;

    if (response && !loading && !error) setSecondaryCorporateAction(response.data);
  }, [findOverriddenAPI.response, findOverriddenAPI.loading, findOverriddenAPI.error]);

  const error = getCorporateActionAPI.error;
  const loading = getCorporateActionAPI.loading || findOverrideAPI.loading || findOverriddenAPI.loading;

  if (corporateAction) {
    return (
      <Page id="CorporateActions">
        <Grid>
          <PageHeader heading="Corporate action" />
          {loading ? (
            <Col sm={12}>
              <Loading />
            </Col>
          ) : (
            <>
              <Col sm={12}>
                <Card>
                  <CardHeader>
                    <CardTitle>Corporate action details</CardTitle>
                    <CardActions>
                      <Tooltip title={corporateAction?.overrideId ? "Already overridden" : "Edit"}>
                        <div style={{ display: "inline-block" }}>
                          <Button
                            color="primary"
                            variant="text"
                            style={{ marginLeft: "4px" }}
                            disabled={corporateAction?.overrideId}
                            onClick={() => navigate(`/mse-corp-action/corporate-actions/${uuid}/update`)}
                          >
                            Edit
                          </Button>
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={
                          corporateAction?.overrideId
                            ? "Already overridden"
                            : corporateAction?.source === CorporateActionSource.USER
                            ? "Cant override when source = USER"
                            : "Override"
                        }
                      >
                        <div style={{ display: "inline-block" }}>
                          <Button
                            color="primary"
                            variant="text"
                            style={{ marginLeft: "4px" }}
                            disabled={
                              corporateAction?.overrideId || corporateAction?.source === CorporateActionSource.USER
                            }
                            onClick={() => navigate(`/mse-corp-action/corporate-actions/${uuid}/override`)}
                          >
                            Override
                          </Button>
                        </div>
                      </Tooltip>
                      <EntitlementsModal corporateAction={corporateAction} />
                      <TagsModal originalCorporateAction={corporateAction} updateCorporateAction={setCorporateAction} />
                      <NotesModal corporateAction={corporateAction} setCorporateAction={setCorporateAction} />
                      <NotesModal corporateAction={corporateAction} setCorporateAction={setCorporateAction} internal />
                    </CardActions>
                  </CardHeader>
                  {!corporateAction?.qualityControlPassed && (
                    <CardBody>
                      <Grid nested>
                        {!corporateAction?.qualityControlPassed && (
                          <Col sm={12}>
                            <InfoBox
                              variant={InfoBox?.Variant.ERROR}
                              value={
                                corporateAction?.qualityControlNotes?.length > 0 && (
                                  <>
                                    Quality control notes:
                                    {corporateAction.qualityControlNotes.map((note) => (
                                      <p>{note.value}</p>
                                    ))}
                                  </>
                                )
                              }
                            />
                          </Col>
                        )}
                      </Grid>
                    </CardBody>
                  )}
                  <CorporateActionDetails ca={corporateAction} secondaryCa={secondaryCorporateAction} />
                </Card>
              </Col>
              <CorporateActionNotes ca={corporateAction} setCorporateAction={setCorporateAction} />
              <ActivityMaintenance ca={corporateAction} refreshApi={getCorporateActionAPI} />
            </>
          )}
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page id="CorporateAction">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default CorporateAction;
