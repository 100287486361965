import { useTheme } from "capitalroadkit";
import React from "react";

const BonusDetails = ({ ca, secondaryCa }) => {
  const [theme] = useTheme();

  return (
    <>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Fraction rounding</th>
        <td>{ca.fractionRounding}</td>
        <td>{secondaryCa?.fractionRounding}</td>
      </tr>
      <tr>
        <th>Ratio old</th>
        <td>{ca.ratioOld}</td>
        <td>{secondaryCa?.ratioOld}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Ratio new</th>
        <td>{ca.ratioNew}</td>
        <td>{secondaryCa?.ratioNew}</td>
      </tr>
      <tr>
        <th>Action flag</th>
        <td>{ca.actionFlag}</td>
        <td>{secondaryCa?.actionFlag}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Priority</th>
        <td>{ca.priority}</td>
        <td>{secondaryCa?.priority}</td>
      </tr>
      <tr>
        <th>Primary instrument close price</th>
        <td>{ca.primaryInstrumentClosePrice}</td>
        <td>{secondaryCa?.primaryInstrumentClosePrice}</td>
      </tr>
      <tr style={{ backgroundColor: theme.palette.background.main }}>
        <th>Child close price</th>
        <td>{ca.childClosePrice}</td>
        <td>{secondaryCa?.childClosePrice}</td>
      </tr>
      <tr>
        <th>Child instrument</th>
        <td>{ca.childInstrument}</td>
        <td>{secondaryCa?.childInstrument}</td>
      </tr>
    </>
  );
};

export default BonusDetails;
