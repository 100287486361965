import { InfoBox, Page } from "capitalroadkit";
import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import useAPI from "../../../../../../../hooks/useAPI";
import Loading from "../../../../../../../Loading";
import { getLedgerAxios } from "../../../../../api/LedgerResource";

const ClientLedgerContext = createContext(null);

const ClientLedgerProvider = ({ children }) => {
  const { uuid } = useParams();

  const getLedgerAPI = useAPI(getLedgerAxios(uuid));

  const response = getLedgerAPI.response;
  const loading = getLedgerAPI.loading;
  const error = getLedgerAPI.error;

  if (response) {
    const ledger = getLedgerAPI.response.data;

    return <ClientLedgerContext.Provider value={ledger}>{children}</ClientLedgerContext.Provider>;
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page>
        <InfoBox variant={InfoBox.Variant.ERROR} value={error.response?.data} />
      </Page>
    );
  return null;
};

const useClientLedger = () => useContext(ClientLedgerContext);

export default ClientLedgerProvider;
export { useClientLedger };
