import {
  Card,
  Grid,
  Col,
  CardBody,
  Modal,
  CardHeader,
  CardTitle,
  IconButton,
  CardActions,
  Button,
  InfoBox,
} from "capitalroadkit";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import ModalActivityTable from "./ModalActivityTable";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { calculateEntitlementsAxios } from "../../../../api/CorporateActionsResource";
import useAPI from "../../../../../../hooks/useAPI";
import EntitlementsForm from "../../../../forms/EntitlementsForm";
import Loading from "../../../../../../Loading";

const EntitlementsModal = ({ corporateAction, useIcon }) => {
  const [open, setOpen] = useState(false);

  const calculateEntitlementsAPI = useAPI(calculateEntitlementsAxios(corporateAction?.uuid), { immediate: false });

  const entitlementsForm = useFormik({
    initialValues: EntitlementsForm.initialValues,
    onSubmit: (values) =>
      calculateEntitlementsAPI.fetch({}, [{ ...EntitlementsForm.clean(values), entityUuid: "Samothy" }]),
    validationSchema: EntitlementsForm.schema,
  });

  const response = calculateEntitlementsAPI.response;
  const error = calculateEntitlementsAPI.error;
  const loading = calculateEntitlementsAPI.loading;

  return (
    <>
      <Tooltip
        title={
          corporateAction?.overrideId
            ? "Disabled for overridden corporate actions"
            : !corporateAction?.readyForProcessing
            ? "Corporate action not ready for processing"
            : "Entitlements calculator"
        }
      >
        <div style={{ display: "inline-block", marginLeft: useIcon ? "1rem" : "" }}>
          {useIcon ? (
            <FontAwesomeIcon
              icon={faCalculator}
              onClick={() => {
                if (!(corporateAction.overrideId || !corporateAction?.readyForProcessing)) setOpen(true);
              }}
              style={{
                opacity: corporateAction.overrideId || !corporateAction?.readyForProcessing ? "0.25" : "",
                cursor: "pointer",
              }}
            />
          ) : (
            <Button
              color="primary"
              disabled={corporateAction.overrideId || !corporateAction?.readyForProcessing}
              onClick={() => setOpen(true)}
              style={{ marginLeft: "4px" }}
              variant="text"
            >
              Calculate entitlements
            </Button>
          )}
        </div>
      </Tooltip>
      <Modal open={open} size="xl">
        {open && (
          <Card>
            <CardHeader>
              <CardTitle>Calculate entitlements</CardTitle>
              <CardActions>
                <IconButton icon={faTimes} onClick={() => setOpen(false)} />
              </CardActions>
            </CardHeader>
            <CardBody>
              <FormikProvider value={entitlementsForm}>
                <Form>
                  <Grid nested>
                    <Col sm={12} style={{ display: "flex", gap: "16px" }}>
                      <EntitlementsForm form={entitlementsForm} ca={corporateAction} />
                      <Button color="primary" style={{ marginTop: "16px" }} loading={loading} type="submit">
                        Calculate
                      </Button>
                    </Col>
                  </Grid>
                </Form>
              </FormikProvider>
            </CardBody>
            {error && (
              <CardBody>
                <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
              </CardBody>
            )}
            {loading && (
              <CardBody>
                <Loading />
              </CardBody>
            )}
            <ModalActivityTable data={(response?.data.length > 0 && response?.data[0].activities) || []} />
          </Card>
        )}
      </Modal>
    </>
  );
};

export default EntitlementsModal;
