import { Button, Card, CardBody, useTheme } from "capitalroadkit";
import { Link } from "react-router-dom";

const ServiceCheck = ({ healthCheckAPI, link, rolesAPI, title }) => {
  const [theme] = useTheme();

  const getStatusColor = () => {
    if (healthCheckAPI.response) return theme.palette.success.main;
    if (healthCheckAPI.error) return theme.palette.danger.main;
    return theme.palette.warning.main;
  };

  const getButtonText = () => {
    if (healthCheckAPI.response) {
      if (!rolesAPI.response) return "Unauthorized";
      return "Open";
    }
    return "Service down";
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardBody style={{ display: "flex" }}>
        <div style={{ flexGrow: "1" }}>{title}</div>
        <div>
          <div style={{ backgroundColor: getStatusColor(), borderRadius: "50%", height: "12px", width: "12px" }} />
        </div>
      </CardBody>
      <div style={{ padding: "8px" }}>
        <Link to={link}>
          <Button color="primary" variant="text" disabled={!healthCheckAPI.response || !rolesAPI.response}>
            {getButtonText()}
          </Button>
        </Link>
      </div>
    </Card>
  );
};

export default ServiceCheck;
