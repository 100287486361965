import { MenuItem } from "@material-ui/core";
import { Col, TextField } from "capitalroadkit";
import { Field } from "formik";
import { useEffect } from "react";

import BpayBillerForm from "./BpayBillerForm";
import DomesticPayeeForm from "./DomesticPayeeForm";

import PayeeTypeEnum from "../../../../../../../../../../types/CashService/Payees/PayeeTypeEnum";
import withNameSpace from "../../../../../../../../../../functions/withNameSpace";
import { parseEnum } from "../../../../../../../../../../resources/formatters/Formatter";

const fields = {
  TYPE: "type",
  NAME: "name",
  NICKNAME: "nickname",
};

const initialValues = {
  [fields.TYPE]: PayeeTypeEnum.BPAY_BILLER,
  [fields.NAME]: "",
  [fields.NICKNAME]: "",
};

const renderForm = ({ nameSpace, type, values }) => {
  switch (type) {
    case PayeeTypeEnum.BPAY_BILLER:
      return <BpayBillerForm nameSpace={nameSpace} values={values} />;

    case PayeeTypeEnum.DOMESTIC_PAYEE:
      return <DomesticPayeeForm nameSpace={nameSpace} values={values} />;

    default:
      return null;
  }
};

const PayeeForm = ({ form, nameSpace, values }) => {
  useEffect(() => {
    if (values.type) {
      switch (values.type) {
        case PayeeTypeEnum.BPAY_BILLER:
          form.setValues({
            ...initialValues,
            ...BpayBillerForm.initialValues,
            [fields.TYPE]: PayeeTypeEnum.BPAY_BILLER,
          });
          break;

        case PayeeTypeEnum.DOMESTIC_PAYEE:
          form.setValues({
            ...initialValues,
            ...DomesticPayeeForm.initialValues,
            [fields.TYPE]: PayeeTypeEnum.DOMESTIC_PAYEE,
          });
          break;

        default:
          form.setValues({ ...initialValues, [fields.TYPE]: values.type });
          break;
      }
    }
  }, [values.type]);

  return (
    <>
      <Col sm={12}>
        <Field component={TextField} label="Type" name={withNameSpace(nameSpace, fields.TYPE)} required select>
          {Object.entries(PayeeTypeEnum).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {parseEnum(value)}
            </MenuItem>
          ))}
        </Field>
      </Col>
      <Col sm={12} style={{ display: "flex", gap: "8px" }}>
        <Field component={TextField} label="Name" name={withNameSpace(nameSpace, fields.NAME)} required />
        <Field component={TextField} label="Nickname" name={withNameSpace(nameSpace, fields.NICKNAME)} />
      </Col>
      {renderForm({ nameSpace, type: values[fields.TYPE], values })}
    </>
  );
};

PayeeForm.fields = fields;
PayeeForm.initialValues = initialValues;

export default PayeeForm;
