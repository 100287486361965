import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faBan } from "@fortawesome/pro-regular-svg-icons/faBan";
import { useEffect } from "react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";

import useAPI from "../../../../hooks/useAPI";
import {
  approvePaymentScheduleAxios,
  cancelPaymentScheduleAxios,
  rejectPaymentScheduleAxios,
} from "../../api/PaymentScheduleResource";
import PaymentScheduleStatusEnum from "../../../../types/CashService/PaymentSchedule/PaymentScheduleStatusEnum";

const PaymentScheduleTableActions = ({ paymentSchedule, refreshData }) => {
  const approvePaymentScheduleAPI = useAPI(approvePaymentScheduleAxios(paymentSchedule.uuid), { immediate: false });

  useEffect(() => {
    const { response, loading } = approvePaymentScheduleAPI;

    if (response && !loading) refreshData();
  }, [approvePaymentScheduleAPI.response, approvePaymentScheduleAPI.loading]);

  const rejectPaymentScheduleAPI = useAPI(rejectPaymentScheduleAxios(paymentSchedule.uuid), { immediate: false });

  useEffect(() => {
    const { response, loading } = rejectPaymentScheduleAPI;

    if (response && !loading) refreshData();
  }, [rejectPaymentScheduleAPI.response, rejectPaymentScheduleAPI.loading]);

  const cancelPaymentScheduleAPI = useAPI(cancelPaymentScheduleAxios(paymentSchedule.uuid), { immediate: false });

  useEffect(() => {
    const { response, loading } = cancelPaymentScheduleAPI;

    if (response && !loading) refreshData();
  }, [cancelPaymentScheduleAPI.response, cancelPaymentScheduleAPI.loading]);

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      {approvePaymentScheduleAPI.loading || rejectPaymentScheduleAPI.loading || cancelPaymentScheduleAPI.loading ? (
        <FontAwesomeIcon icon={faSpinnerThird} spin />
      ) : (
        <>
          {paymentSchedule.status === PaymentScheduleStatusEnum.PENDING_APPROVAL && (
            <>
              <Tooltip title="Approve">
                <div onClick={() => rejectPaymentScheduleAPI.fetch()} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              </Tooltip>
              <Tooltip title="Reject">
                <div onClick={() => rejectPaymentScheduleAPI.fetch()} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </Tooltip>
            </>
          )}
          {paymentSchedule.status === PaymentScheduleStatusEnum.ACTIVE && (
            <Tooltip title="Cancel">
              <div onClick={() => rejectPaymentScheduleAPI.fetch()} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faBan} />
              </div>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentScheduleTableActions;
