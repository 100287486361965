export const TinExemptionType = Object.freeze({
  C: "TIN applied for",
  A: "TIN has not been issued",
  B: "TIN not required",
  P: "TIN not issued by country",
  Z: "TIN unobtainable",
});

export const parseTinExemptionEnum = (exemptionEnum) => {
  return Object.entries(TinExemptionType).find((exemption) => exemption[0] === exemptionEnum)[1];
};
