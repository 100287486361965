import { Card, CardActions, CardBody, CardHeader, CardTitle, IconButton, Modal, useTheme } from "capitalroadkit";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import React, { cloneElement, useState } from "react";
import {
  parseBoolean,
  parseCamelCase,
  parseEnum,
  toDateString,
} from "../../../../../../resources/formatters/Formatter";
import ActivityComponentsTable from "../../../../components/ActivityComponentsTable";
import ActivityType from "../../../../../../types/corporateActions/Activities/ActivityType";
import VolumeCalculatorType from "../../../../../../types/corporateActions/Activities/VolumeCalculatorType";

const ViewActivityModal = ({ button, activity }) => {
  const [theme] = useTheme();
  const [open, setOpen] = useState(false);

  const objToTableRows = (obj, title, hideKey) => (
    <>
      <tr>
        <th style={{ paddingTop: "32px" }}>{title}</th>
      </tr>
      {Object.entries(obj)
        .filter((val) => typeof val[1] !== "object" && val[0] !== "uuid")
        .map((val, index) => (
          <tr key={val[0]} style={{ backgroundColor: (index + 1) % 2 ? theme.palette.background.main : "" }}>
            {!hideKey && <th>{parseCamelCase(val[0])}:</th>}
            <td>{typeof val[1] === "boolean" ? parseBoolean(val[1]) : val[1]}</td>
          </tr>
        ))}
    </>
  );

  const volumeCalculatorRows = (volCalc) => {
    switch (volCalc.type) {
      case VolumeCalculatorType.RATIO:
        return (
          <>
            <tr style={{ backgroundColor: theme.palette.background.main }}>
              <th>Numerator</th>
              <td>{volCalc.numerator}</td>
            </tr>
            <tr>
              <th>Denominator</th>
              <td>{volCalc.denominator}</td>
            </tr>
            <tr style={{ backgroundColor: theme.palette.background.main }}>
              <th>Rounding mode</th>
              <td>{parseEnum(volCalc.roundingMode)}</td>
            </tr>
            <tr>
              <th>Scale</th>
              <td>{volCalc.scale}</td>
            </tr>
          </>
        );
      case VolumeCalculatorType.MULTIPLIER:
        return (
          <>
            <tr style={{ backgroundColor: theme.palette.background.main }}>
              <th>Multiplier</th>
              <td>{volCalc.multiplier}</td>
            </tr>
            <tr>
              <th>Rounding mode</th>
              <td>{parseEnum(volCalc.roundingMode)}</td>
            </tr>
            <tr style={{ backgroundColor: theme.palette.background.main }}>
              <th>Scale</th>
              <td>{volCalc.scale}</td>
            </tr>
          </>
        );
      case VolumeCalculatorType.FIXED:
        return (
          <>
            <tr style={{ backgroundColor: theme.palette.background.main }}>
              <th>Volume</th>
              <td>{volCalc.volume}</td>
            </tr>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {button && cloneElement(button, { onClick: () => setOpen(true) })}
      <Modal open={open} size={"l"}>
        <Card>
          <CardHeader>
            <CardTitle>View activity</CardTitle>
            <CardActions>
              <IconButton icon={faTimes} onClick={() => setOpen(false)} />
            </CardActions>
          </CardHeader>
          <CardBody>
            <table
              style={{
                borderCollapse: "collapse",
                tableLayout: "fixed",
                textAlign: "left",
                width: "100%",
              }}
            >
              <tr>
                <th style={{ paddingTop: "32px" }}>Details</th>
              </tr>
              <tr style={{ backgroundColor: theme.palette.background.main }}>
                <th>Type</th>
                <td>{parseEnum(activity.type)}</td>
              </tr>
              <tr>
                <th>Activity description</th>
                <td>{activity.description}</td>
              </tr>
              <tr style={{ backgroundColor: theme.palette.background.main }}>
                <th>Asset code</th>
                <td>{activity.assetCode}</td>
              </tr>
              <tr>
                <th>Participation condition</th>
                <td>{parseEnum(activity.participationCondition)}</td>
              </tr>
              {activity.type === ActivityType.INCOME_ACTIVITY && (
                <>
                  <tr style={{ backgroundColor: theme.palette.background.main }}>
                    <th>Write cash transaction</th>
                    <td>{parseBoolean(activity.cashDistribution)}</td>
                  </tr>
                  {activity.expectedPaymentDate && (
                    <tr>
                      <th>Expected payment date</th>
                      <td>{toDateString(activity.expectedPaymentDate)}</td>
                    </tr>
                  )}
                </>
              )}

              <tr>
                <th style={{ paddingTop: "32px" }}>Volume calculator</th>
              </tr>
              <tr style={{ backgroundColor: theme.palette.background.main }}>
                <th>Volume calculator type</th>
                <td>{parseEnum(activity.volumeCalculator.type)}</td>
              </tr>
              <tr>
                <th>Rate per share</th>
                <td>{activity.volumeCalculator.perShare}</td>
              </tr>
              {volumeCalculatorRows(activity.volumeCalculator)}

              {activity.transactionComponent && (
                <>
                  <tr>
                    <th style={{ paddingTop: "32px" }}>Transaction component</th>
                  </tr>
                  <tr style={{ backgroundColor: theme.palette.background.main }}>
                    <th>Transaction date</th>
                    <td>{toDateString(activity.transactionComponent.transactionDate)}</td>
                  </tr>
                  <tr>
                    <th>Settlement date</th>
                    <td>{toDateString(activity.transactionComponent.settlementDate)}</td>
                  </tr>
                  <tr>
                    <th>Custodian Settlement date</th>
                    <td>{toDateString(activity.transactionComponent.custodianBeneficiarySettlementDate)}</td>
                  </tr>
                  <tr style={{ backgroundColor: theme.palette.background.main }}>
                    <th>Transaction annotation</th>
                    <td>{activity.transactionComponent.annotation}</td>
                  </tr>
                  {(activity.type === ActivityType.ASSET_BUY_ACTIVITY ||
                    activity.type === ActivityType.ASSET_SELL_ACTIVITY) && (
                    <>
                      <tr>
                        <th>Price</th>
                        <td>{activity.transactionComponent.price}</td>
                      </tr>
                      <tr style={{ backgroundColor: theme.palette.background.main }}>
                        <th>Market price</th>
                        <td>{parseBoolean(activity.transactionComponent.marketPrice)}</td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </table>
          </CardBody>
          {activity.type === ActivityType.INCOME_ACTIVITY && (
            <>
              <CardHeader>
                <h6>Income components</h6>
              </CardHeader>
              <ActivityComponentsTable data={activity.activityComponents} />
            </>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default ViewActivityModal;
