import { MenuItem } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Col, CardBody, Grid, TextField } from "capitalroadkit";
import { Field } from "formik";
import clone from "lodash/cloneDeep";

import withNameSpace from "../../../../functions/withNameSpace";
import { toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import { payTypeDescription } from "../../../../functions/corpActionFieldDescription";
import PayType from "../../../../types/corporateActions/PayType";

const fields = {
  END_DATE: "endDate",
  START_DATE: "startDate",
  ACTION_FLAG: "actionFlag",
  WITHDRAWAL_RIGHTS: "withdrawalRights",
  ON_OFF_FLAG: "onOffFlag",
  PAYMENT_CURRENCY: "paymentCurrency",
  MAXIMUM_PRICE: "maximumPrice",
  MINIMUM_PRICE: "minimumPrice",
  PRIORITY: "priority",
  PAY_TYPE: "payType",
  PAR_VALUE_CURRENCY: "parValueCurrency",
  SECURITY_PAR_VALUE: "securityParValue",
};

const initialValues = (ca) => ({
  [fields.END_DATE]: toDateString(ca?.endDate) || "",
  [fields.START_DATE]: toDateString(ca?.startDate) || "",
  [fields.ACTION_FLAG]: ca?.actionFlag || "",
  [fields.WITHDRAWAL_RIGHTS]: ca?.withdrawalRights || "",
  [fields.ON_OFF_FLAG]: ca?.onOffFlag || "",
  [fields.PAYMENT_CURRENCY]: ca?.paymentCurrency || "",
  [fields.MAXIMUM_PRICE]: ca?.maximumPrice || "",
  [fields.MINIMUM_PRICE]: ca?.minimumPrice || "",
  [fields.PRIORITY]: ca?.priority || "",
  [fields.PAY_TYPE]: ca?.payType || "",
  [fields.PAR_VALUE_CURRENCY]: ca?.parValueCurrency || "",
  [fields.SECURITY_PAR_VALUE]: ca?.securityParValue || "",
});

const clean = (values) => {
  const clean = clone(values);

  if (clean[fields.END_DATE]) clean[fields.END_DATE] = toAPIDateString(clean[fields.END_DATE]);
  if (clean[fields.START_DATE]) clean[fields.START_DATE] = toAPIDateString(clean[fields.START_DATE]);

  return clean;
};

const BuyBackForm = ({ form, nameSpace, updateAnnouncement, overriddenCa }) => {
  return (
    <>
      <Col sm={12}>
        <CardBody nested>
          <Grid nested>
            <Col sm={12}>Buy back fields</Col>
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Start date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.START_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.startDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="End date"
                fullWidth
                mask="99/99/9999"
                name={withNameSpace(nameSpace, fields.END_DATE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {toDateString(overriddenCa.endDate)}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Action flag"
                fullWidth
                name={withNameSpace(nameSpace, fields.ACTION_FLAG)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.actionFalg}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Withdrawal rights"
                fullWidth
                name={withNameSpace(nameSpace, fields.WITHDRAWAL_RIGHTS)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.withdrawalRights}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="On off flag"
                fullWidth
                name={withNameSpace(nameSpace, fields.ON_OFF_FLAG)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.onOffFlag}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Payment currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAYMENT_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.paymentCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Minimum price"
                fullWidth
                name={withNameSpace(nameSpace, fields.MINIMUM_PRICE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.minimumPrice}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Maximum price"
                fullWidth
                name={withNameSpace(nameSpace, fields.MAXIMUM_PRICE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.maximumPrice}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Priority"
                fullWidth
                name={withNameSpace(nameSpace, fields.PRIORITY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.priority}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                key={form.values[withNameSpace(nameSpace, fields.PAY_TYPE)]}
                component={TextField}
                label="Pay type"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAY_TYPE)}
                readOnly={updateAnnouncement}
                select
              >
                <MenuItem value="">Pay type is currently unknown</MenuItem>
                {Object.entries(PayType).map((type) => (
                  <MenuItem key={type[0]} value={type[0]}>
                    {type[0] + " (" + type[1] + ")"}
                  </MenuItem>
                ))}
              </Field>
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                <Tooltip title={payTypeDescription(overriddenCa.payType)} placement="right">
                  <div style={{ width: "25px" }}>{overriddenCa.payType}</div>
                </Tooltip>
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Par value currency"
                fullWidth
                name={withNameSpace(nameSpace, fields.PAR_VALUE_CURRENCY)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.parValueCurrency}
              </Col>
            )}
            <Col sm={overriddenCa ? 8 : 12}>
              <Field
                component={TextField}
                label="Security par value"
                fullWidth
                name={withNameSpace(nameSpace, fields.SECURITY_PAR_VALUE)}
                readOnly={updateAnnouncement}
              />
            </Col>
            {overriddenCa && (
              <Col sm={4} style={{ marginTop: "35px" }}>
                {overriddenCa.securityParValue}
              </Col>
            )}
          </Grid>
        </CardBody>
      </Col>
    </>
  );
};

BuyBackForm.fields = fields;
BuyBackForm.initialValues = initialValues;
BuyBackForm.clean = clean;

export default BuyBackForm;
