import * as qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const useAPI = (api, options = {}) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [lastParams, setLastParams] = useState(null);

  const getHeaders = () => ({
    "X-Correlation-ID": uuidv4(),
    "content-type": "application/json",
  });

  const fetch = useCallback(
    (params = {}, data = null, headers = {}) => {
      setLoading(true);
      setError(null);

      if (data) {
        return api(
          {
            headers: { ...getHeaders(), ...headers },
            params,
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
            withCredentials: true,
          },
          data
        )
          .then((resp) => {
            setResponse(resp);
          })
          .catch((err) => {
            setError(err);
            console.error(err);
          })
          .finally(() => {
            setLastParams(params);
            setLoading(false);
          });
      }

      return api({
        headers: { ...getHeaders(), ...headers },
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
        withCredentials: true,
      })
        .then((resp) => {
          setResponse(resp);
        })
        .catch((err) => {
          setError(err);
          console.error(err);
        })
        .finally(() => {
          setLastParams(params);
          setLoading(false);
        });
    },
    [api]
  );

  const reset = () => {
    setLoading(false);
    setResponse(null);
    setError(null);
  };

  useEffect(() => {
    if (options.immediate === undefined ? true : options.immediate) fetch(options.initialParams || {});
  }, []);

  return {
    error,
    fetch,
    lastParams,
    loading,
    reset,
    response,
  };
};

export default useAPI;
