import MenuItem from "@material-ui/core/MenuItem";
import { Col, TextField } from "capitalroadkit";
import { Field } from "formik";
import React from "react";

const fields = {
  TRANSACTION_CHANNEL: "transactionChannel",
  NATURAL_ACCOUNT_SOURCE: "naturalAccountSource",
};

const initialValues = {
  [fields.TRANSACTION_CHANNEL]: "",
  [fields.NATURAL_ACCOUNT_SOURCE]: "",
};

const UpdateTransactionForm = ({ form, original }) => (
  <>
    <Col sm={12}>
      <Field
        component={TextField}
        disabled={original[fields.TRANSACTION_CHANNEL] !== "UNKNOWN"}
        label="Channel"
        name={fields.TRANSACTION_CHANNEL}
        select
      >
        <MenuItem value="NATURAL_ACCOUNT">Natural account</MenuItem>
        <MenuItem value="GENERAL_LEDGER">General ledger</MenuItem>
        <MenuItem value="BANK">Bank</MenuItem>
        <MenuItem value="PLATFORM">Platform</MenuItem>
        <MenuItem value="UNKNOWN">Unknown</MenuItem>
      </Field>
    </Col>
    {(form.values[fields.TRANSACTION_CHANNEL] === "NATURAL_ACCOUNT" ||
      form.values[fields.TRANSACTION_CHANNEL] === "GENERAL_LEDGER") && (
      <Col sm={12}>
        <Field component={TextField} label="Source" name={fields.NATURAL_ACCOUNT_SOURCE} select>
          <MenuItem value="CLIENT">Client</MenuItem>
          <MenuItem value="REGISTRY">Registry</MenuItem>
          <MenuItem value="FINCLEAR">Finclear</MenuItem>
          <MenuItem value="UNKNOWN">Unknown</MenuItem>
        </Field>
      </Col>
    )}
  </>
);

export default UpdateTransactionForm;
export { initialValues as UpdateTransactionFormInitialValues };
