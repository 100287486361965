import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Grid,
  Page,
  PageHeader,
  CardTitle,
  Button,
  InfoBox,
  CardActions,
} from "capitalroadkit";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAPI from "../../../../hooks/useAPI";
import { createCorporateActionAxios, getCorporateActionAxios } from "../../api/CorporateActionsResource";
import Loading from "../../../../Loading";
import CorporateActionSource from "../../../../types/corporateActions/CorporateActionSource";
import CorporateActionForm from "../../forms/CorporateActionForm";

const OverrideCorporateAction = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [corporateAction, setCorporateAction] = useState(null);

  const getCorporateActionAPI = useAPI(getCorporateActionAxios(uuid));

  useEffect(() => {
    const { response, loading, error } = getCorporateActionAPI;

    if (response && !loading && !error) setCorporateAction(response.data);
  }, [getCorporateActionAPI.response, getCorporateActionAPI.loading, getCorporateActionAPI.error]);

  const createCorporateActionAPI = useAPI(createCorporateActionAxios(), { immediate: false });

  const corporateActionForm = useFormik({
    initialValues: CorporateActionForm.initialValues(corporateAction),
    onSubmit: (values) => createCorporateActionAPI.fetch({}, CorporateActionForm.clean(values, corporateAction.uuid)),
    validationSchema: CorporateActionForm.schema(corporateAction),
    enableReinitialize: true,
  });

  const response = createCorporateActionAPI.response;
  const loading = createCorporateActionAPI.loading;
  const error = createCorporateActionAPI.error;

  if (corporateAction) {
    return (
      <Page id="OverrideCorporateAction">
        <Grid>
          <PageHeader heading="Override corporate action" />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Override corporate action</CardTitle>
              </CardHeader>
              {getCorporateActionAPI.response.data.isOverridden ? (
                <CardBody>
                  <Grid nested>
                    <Col sm={12}>
                      <InfoBox
                        variant={InfoBox?.Variant.ERROR}
                        value="Cannot override a corporate action that is already overridden"
                      />
                    </Col>
                  </Grid>
                </CardBody>
              ) : getCorporateActionAPI.response.data.source === CorporateActionSource.USER ? (
                <CardBody>
                  <Grid nested>
                    <Col sm={12}>
                      <InfoBox
                        variant={InfoBox?.Variant.ERROR}
                        value="Cannot override a corporate action created by a user"
                      />
                    </Col>
                  </Grid>
                </CardBody>
              ) : (
                <FormikProvider value={corporateActionForm}>
                  <Form>
                    <Grid nested>
                      <Col lg={4} md={6} sm={12}>
                        <CardBody>
                          <Grid nested>
                            <Col sm={12}>
                              <CorporateActionForm form={corporateActionForm} ca={corporateAction} />
                            </Col>
                            {error && (
                              <Col sm={12}>
                                <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
                              </Col>
                            )}
                            {response && (
                              <Col sm={12}>
                                <InfoBox
                                  variant={InfoBox?.Variant.SUCCESS}
                                  value={
                                    <Grid nested>
                                      <Col sm={6} style={{ marginTop: "20px" }}>
                                        Successfully created!
                                      </Col>
                                      <Col sm={6} style={{ marginTop: "16px", textAlign: "right" }}>
                                        <Button
                                          color="success"
                                          variant="text"
                                          style={{ marginLeft: "4px" }}
                                          onClick={() => navigate(`/mse-corp-action/corporate-actions/${uuid}`)}
                                        >
                                          Back
                                        </Button>
                                        <Button
                                          color="success"
                                          variant="text"
                                          style={{ marginLeft: "4px" }}
                                          onClick={() =>
                                            navigate(
                                              `/mse-corp-action/corporate-actions/${createCorporateActionAPI.response.data.uuid}`
                                            )
                                          }
                                        >
                                          View override
                                        </Button>
                                      </Col>
                                    </Grid>
                                  }
                                />
                              </Col>
                            )}
                            <Col sm={12} style={{ marginTop: "16px", textAlign: "right" }}>
                              <Button
                                color="warning"
                                disable={loading}
                                style={{ marginLeft: "4px" }}
                                onClick={() => navigate(`/mse-corp-action/corporate-actions/${uuid}`)}
                              >
                                Cancel
                              </Button>
                              <Button
                                color="success"
                                disable={loading}
                                loading={loading}
                                style={{ marginLeft: "4px" }}
                                type="submit"
                              >
                                Save
                              </Button>
                            </Col>
                          </Grid>
                        </CardBody>
                      </Col>
                    </Grid>
                  </Form>
                </FormikProvider>
              )}
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (getCorporateActionAPI.loading) return <Loading />;
  if (getCorporateActionAPI.error)
    return (
      <Page id="OverrideCorporateAction">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default OverrideCorporateAction;
