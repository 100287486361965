import React from "react";
import clone from "lodash/cloneDeep";
import * as Yup from "yup";

const fields = {
  // : "fundShortName",
  // : "domicileId",
  // : "domicile",
  // : "purchaseCurrencyId",
  // : "inceptionDate",
  // : "pricingFrequency",
  // : "delayedPricing",
  // : "distributionFrequency",
  // : "dividendDistributionFrequencyDetails",
  // : "dividendInvestmentPlan",
  // : "dividendDate",
  // : "dividend",
  // : "minimumInitialUnit",
  // : "minimumSubsequentUnit",
  // : "minimumSubsequent",
  // : "minimumRedemptionUnit",
  // : "availableRegularInvestment",
  // : "minimumAIP",
  // : "subsequentAIP",
  // : "switchingFee",
  // : "transactionFee",
  // : "transactionCosts",
  // : "indirectCostRatio",
  // : "mer",
  // : "closedToInvestorsClosedToNewDate",
  // : "closedToInvestorsClosedToAllDate",
  // : "salesAreaInstitutionalOnly",
  // : "salesAreaRetailOnly",
  // : "salesAreaRetirementInvestment",
  // : "salesAreaTaxSavingInvestment",
  // : "salesAreaTaxFreeInvestment",
  // : "salesAreaQualifiedInvestment",
  // : "salesAreaReopenDate",
  // : "redemptionFeesUnit",
  // : "redemptionFeesBreakpointUnit",
  // : "redemptionFeesLowBreakpoint",
  // : "redemptionFeesHighBreakpoint",
  // : "redemptionFeesValue",
};

const initialValues = (asset) => ({});

const schema = Yup.object({});

const clean = (values) => {
  let cleanValues = clone(values);
  return cleanValues;
};

const ManagedFundForm = ({ form, nameSpace, asset }) => <></>;

ManagedFundForm.fields = fields;
ManagedFundForm.initialValues = initialValues;
ManagedFundForm.schema = schema;
ManagedFundForm.clean = clean;

export default ManagedFundForm;
