import { ThemeProvider } from "capitalroadkit";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import Navigation from "./Navigation";
import Routes from "./Routes";
import Loading from "./Loading";
import CSS from "./CSS";
import UserProvider from "./context/UserContext";
import HealthProvider from "./context/HealthContext";
import RoleProvider from "./context/RoleContext";

const App = () => (
  <ThemeProvider>
    <CSS>
      <UserProvider>
        <HealthProvider>
          <RoleProvider>
            <BrowserRouter basename="/mse-ui">
              <Suspense fallback={<Loading />}>
                <Navigation>
                  <Routes />
                </Navigation>
              </Suspense>
            </BrowserRouter>
          </RoleProvider>
        </HealthProvider>
      </UserProvider>
    </CSS>
  </ThemeProvider>
);

export default App;
