import { TextField } from "capitalroadkit";
import { Field } from "formik";
import React from "react";
import * as Yup from "yup";

const fields = {
  NOTE: "note",
};

const initialValues = {
  [fields.NOTE]: " ",
};

const schema = Yup.object({
  [fields.NOTE]: Yup.string().required("Note is required"),
});

const NoteForm = () => <Field component={TextField} multiline fullWidth label="Add note" name={fields.NOTE} />;

NoteForm.fields = fields;
NoteForm.initialValues = initialValues;
NoteForm.schema = schema;
export default NoteForm;
