import {
  InfoBox,
  Card,
  CardBody,
  CardTitle,
  CardActions,
  CardHeader,
  Col,
  Grid,
  Page,
  PageHeader,
  Button,
  useSessionStorage,
} from "capitalroadkit";
import clone from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { listCorporateActionsAxios } from "../../api/CorporateActionsResource";
import useAPI from "../../../../hooks/useAPI";
import { toAPIDateString, toDateString } from "../../../../resources/formatters/Formatter";
import CorporateActionsSearchForm from "../../forms/CorporateActionForm/CorporateActionsSearchForm";
import CorporateActionsTable from "../../components/CorporateActionsTable";
import Loading from "../../../../Loading";

const CorporateActionsIndex = () => {
  const navigate = useNavigate();

  // todo: this doesn't need to be in state
  const [corporateActions, setCorporateActions] = useState(null);

  const [lastParams, setLastParams] = useSessionStorage("LastParams", {});

  const listCorporateActionsAPI = useAPI(listCorporateActionsAxios(), { initialParams: { ...lastParams, limit: 10 } });

  const debouncedListCorporateActionsAPI = useRef(debounce(listCorporateActionsAPI.fetch, 500));

  useEffect(() => {
    const { response, loading, error } = listCorporateActionsAPI;

    if (response && !loading && !error) setCorporateActions(listCorporateActionsAPI.response.data.list);
  }, [listCorporateActionsAPI.response, listCorporateActionsAPI.loading]);

  useEffect(() => {
    if (listCorporateActionsAPI.lastParams) {
      setLastParams(listCorporateActionsAPI.lastParams);
    }
  }, [listCorporateActionsAPI.lastParams]);

  const corporateActionsSearchForm = useFormik({
    initialValues: CorporateActionsSearchForm.initialValues,
    onSubmit: (values) => {
      const clean = clone(values);
      const tag = values[CorporateActionsSearchForm.fields.TAG];
      const tags = values[CorporateActionsSearchForm.fields.TAGS];

      if (tag !== "" && tags.filter((value) => value === tag).length === 0) {
        corporateActionsSearchForm.setFieldValue(CorporateActionsSearchForm.fields.TAGS, [...tags, tag]);
        clean[CorporateActionsSearchForm.fields.TAGS] = [...tags, tag];
      }

      corporateActionsSearchForm.setFieldValue(CorporateActionsSearchForm.fields.TAG, "");

      if (clean.date !== "") clean[values.dateType] = toAPIDateString(values.date);
      if (clean.type) clean.type = values.type + "_CORPORATE_ACTION";
      delete clean.dateType;
      delete clean.date;
      delete clean.tag;

      for (const propName in clean) {
        if (clean[propName] === "") {
          delete clean[propName];
        }
      }

      debouncedListCorporateActionsAPI.current({ ...clean, limit: 10, offset: 0 });
    },
  });

  useEffect(() => {
    Object.entries(lastParams).forEach((param) => {
      if (corporateActionsSearchForm.values[param[0]] !== param[1]) {
        switch (param[0]) {
          case "limit":
          case "offset":
            break;
          case "type":
            corporateActionsSearchForm.setFieldValue(param[0], param[1].replace("_CORPORATE_ACTION", ""));
            break;
          case "ex-date":
          case "record-date":
          case "payment-date":
          case "date-of-announcement":
          case "processing-date":
          case "application-closing-date":
          case "date-of-revision":
            corporateActionsSearchForm.setFieldValue("dateType", param[0]);
            corporateActionsSearchForm.setFieldValue("date", toDateString(param[1]));
            break;
          default:
            corporateActionsSearchForm.setFieldValue(param[0], param[1]);
        }
      }
    });
  }, []);

  // todo: this is disgusting and probably creating a memory leak
  const updateCorporateAction = (updatedCorporateAction) => {
    for (let corporateAction of corporateActions) {
      if (corporateAction.uuid === updatedCorporateAction.uuid) corporateAction = updatedCorporateAction;
    }
  };

  const error = listCorporateActionsAPI.error;
  const loading = listCorporateActionsAPI.loading;

  if (corporateActions) {
    return (
      <Page id="CorporateActions">
        <Grid>
          <PageHeader heading="Corporate actions" />
          <Col sm={12}>
            <Card>
              <CardHeader>
                <CardTitle>Corporate actions search</CardTitle>
                <CardActions>
                  <Button color="primary" variant="text" onClick={() => navigate("/mse-corp-action/announcements")}>
                    View announcements
                  </Button>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() => navigate("/mse-corp-action/corporate-actions/new")}
                    style={{ marginLeft: "8px" }}
                  >
                    New corporate action
                  </Button>
                </CardActions>
              </CardHeader>
              <CardBody>
                <FormikProvider value={corporateActionsSearchForm}>
                  <Form>
                    <Grid nested>
                      <Col lg={11} md={11} sm={12}>
                        <Grid nested>
                          <CorporateActionsSearchForm form={corporateActionsSearchForm} />
                        </Grid>
                      </Col>
                      <Col lg={1} md={1} sm={12} style={{ marginTop: "1.5rem", textAlign: "right" }}>
                        <Button color="primary" loading={loading} type="submit">
                          Search
                        </Button>
                      </Col>
                    </Grid>
                  </Form>
                </FormikProvider>
              </CardBody>
              <CorporateActionsTable
                api={listCorporateActionsAPI}
                data={corporateActions}
                updateCorporateAction={updateCorporateAction}
              />
            </Card>
          </Col>
        </Grid>
      </Page>
    );
  }
  if (loading) return <Loading />;
  if (error)
    return (
      <Page id="CorporateActions">
        <Grid>
          <Col sm={12}>
            <InfoBox variant={InfoBox?.Variant.ERROR} value={error.response?.data} />
          </Col>
        </Grid>
      </Page>
    );
  return null;
};

export default CorporateActionsIndex;
