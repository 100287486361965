import { createContext, useContext } from "react";

import { assetServiceHealthCheckAxios } from "../Routes/AssetService/api/HealthCheckResource";
import useAPI from "../hooks/useAPI";
import { cashServiceHealthCheckAxios } from "../Routes/CashService/api/HealthCheckResource";
import { corporateActionsServiceHealthCheckAxios } from "../Routes/CorporateActionsService/api/HealthCheckResource";
import Loading from "../Loading";

const HealthContext = createContext(null);

const HealthProvider = ({ children }) => {
  const assetServiceHealthCheckAPI = useAPI(assetServiceHealthCheckAxios());
  const cashServiceHealthCheckAPI = useAPI(cashServiceHealthCheckAxios());
  const corporateActionsServiceHealthCheckAPI = useAPI(corporateActionsServiceHealthCheckAxios());

  const loading =
    assetServiceHealthCheckAPI.loading ||
    cashServiceHealthCheckAPI.loading ||
    corporateActionsServiceHealthCheckAPI.loading;

  if (loading) return <Loading />;
  return (
    <HealthContext.Provider
      value={{ assetServiceHealthCheckAPI, cashServiceHealthCheckAPI, corporateActionsServiceHealthCheckAPI }}
    >
      {children}
    </HealthContext.Provider>
  );
};

const useHealth = () => useContext(HealthContext);

export default HealthProvider;
export { useHealth };
